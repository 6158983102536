import   React           from 'react';
import { getFunctions,
         httpsCallable } from 'firebase/functions';

import                       'widgets/forms/index.scss';
import                       'widgets/button/button-v2.scss';
import                       'widgets/spinner/spinner.scss';
import { PgTextField }  from 'widgets/text-field/text-field';
import { InputPhone,
         formatPhone }  from 'widgets/text-field/input-phone';
import { LoginContext } from 'walls/login-wall/login-wall';
import { dbDeletePhone,
         useDbPhone }   from 'backend-layer/db/db-users';

export function FormNotifications({ onExit }) {
  const login = React.useContext(LoginContext);
  const phoneNumber = useDbPhone(login.uid);

  const [readOnly, setReadOnly] = React.useState(true);

  return (
    <section className="widget-form widget-form-notifications">
      {readOnly ?
        <ManagePhone setReadOnly={setReadOnly} phoneNumber={phoneNumber} onExit={onExit} />
      :
        <FormPhone setReadOnly={setReadOnly} isUpdating={Boolean(phoneNumber)} />
      }
    </section>
  );
}

function ManagePhone({ setReadOnly, phoneNumber, onExit }) {
  const [isPrimedForRemoval, setPrimedForRemoval] = React.useState(false);

  const handleRemovePhone = React.useCallback(() => {
    dbDeletePhone();
    setPrimedForRemoval(false);
  }, []);

  return (
    <>
      <p>
        {phoneNumber ?
          <>Your mobile number is <strong>{formatPhone(phoneNumber).display}</strong>. </> :
          <>Set up SMS notifications. </>
        }
        We’ll only send you texts for new maintenance requests, appointments,
        and messages. Remove your number anytime.
      </p>

      <div className="flex-row top-spacer-md">
        {isPrimedForRemoval ?
          <>
            <div className="flex-1" />
            <button className="btn-remove"
                    type="button"
                    onClick={handleRemovePhone}>Confirm Remove</button>
            <button className="btn-primary left-spacer-sm"
                    type="button"
                    onClick={() => setPrimedForRemoval(false)}>Cancel</button>
          </>
        :
          <>
            {phoneNumber &&
              <button className="btn-alt right-spacer-sm"
                      type="button"
                      onClick={() => setPrimedForRemoval(true)}>Remove</button>
            }
            <div className="flex-1" />
            <button className="btn-primary"
                    type="button"
                    onClick={() => setReadOnly(false)}>
              {phoneNumber ? 'Change' : 'Add Phone'}
            </button>
            <button className="btn-primary left-spacer-sm"
                    type="button"
                    onClick={onExit}>Dismiss</button>
          </>
        }
      </div>
    </>
  );
}

function FormPhone({ setReadOnly, isUpdating }) {
  const [mode, setMode] = React.useState('input-phone-number');

  const [phoneNumber,      setPhoneNumber]     = React.useState(null);
  const [verificationCode, setVerficationCode] = React.useState('');

  const submit = React.useCallback(e => {
    e.preventDefault();
    if (mode === 'input-phone-number') {
      httpsCallable(getFunctions(), 'phone-sendCode')({ phoneNumber });
      setMode('input-verfication-code');
    } else {
      setMode(); // Sets to loading
      httpsCallable(getFunctions(), 'phone-addNumber')({ phoneNumber,
                                                         verificationCode }).then(resp => {
        if (resp.data.isCodeAccepted) {
          setReadOnly(true);
        } else {
          setVerficationCode('');
          setMode('verfication-code-failed');
        }
      });
    }
  }, [phoneNumber, verificationCode, mode, setReadOnly]);

  const reset = React.useCallback(e => {
    e.preventDefault();
    setReadOnly(true);
  }, [setReadOnly]);

  switch(mode) {
    case 'input-phone-number': return (
      <form onSubmit={submit} onReset={reset}>
        <InputPhone label="Mobile Number"
                    autoFocus
                    required
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber} />
        <p className="text-field-caption">
          We'll text you a code to verify your number.
        </p>
        <div className="flex-row" style={{ marginTop: '7.692px' }}>
          <div className="flex-1" />
          <button className="btn-primary"
                  type="submit">Verify Number</button>
          <button className="btn-primary left-spacer-sm"
                  type="reset">Cancel</button>
        </div>
      </form>
    );

    case 'input-verfication-code' :
    case 'verfication-code-failed': return (
      <form onSubmit={submit} onReset={reset}>
        <PgTextField label="Verification Code"
                     autoFocus
                     autoComplete="one-time-code"
                     inputMode="numeric"
                     required
                     minLength="6"
                     maxLength="6"
                     value={verificationCode}
                     onChange={e => setVerficationCode(e.target.value)} />
        <p className="text-field-caption">
          {mode === 'verfication-code-failed' ?
            'Incorrect verfication code. Try again.'
          :
            'Enter the verfication code we just texted you.'
          }
        </p>
        <div className="flex-row" style={{ marginTop: '7.692px' }}>
          <div className="flex-1" />
          <button className="btn-change"
                  type="submit">Confirm Code</button>
          <button className="btn-primary left-spacer-sm"
                  type="reset">Cancel</button>
        </div>
      </form>
    );

    default: return (
      <div className="spinner" style={{ marginTop: '32.601px', marginBottom: '32.601px'}} />
    );
  }
}
