import   React     from 'react';
import { getDatabase,
         ref,
         push,
         set,
         update,
         remove,
         onValue } from 'firebase/database';
import   currency  from 'currency.js'

/******************************************************************************
 * Constants
 ******************************************************************************/

 export const dbTransactionTypes = ['Rent', 'Payment', 'Reimbursement', 'Late Fee', 'Surcharge', 'Credit', 'Security Deposit', 'Security Deposit Return'];
 export const dbNegTransactionTypes = ['Payment', 'Credit', 'Security Deposit Return'];
 export const dbPayMethods = ['Check', 'Cashier\'s Check', 'Money Order', 'Zelle', 'ACH', 'Bank Transfer', 'Wire Transfer', 'Cash'];

/******************************************************************************
 * Create, read once, update & delete
 ******************************************************************************/

export function dbCreateTransaction(buildingId,
                                    unitId,
                                    tenancyId,
                                    type,
                                    date,
                                    amount,
                                    { payer, payee, payMethod, checkNumber, memo }) {
  if (!buildingId)                                                   { throw new Error('Create transaction failed: missing building ID!');                           }
  if (!unitId)                                                       { throw new Error('Create transaction failed: missing unit ID!');                               }
  if (!tenancyId)                                                    { throw new Error('Create transaction failed: missing tenancy ID!');                            }
  if (!type)                                                         { throw new Error('Create transaction failed: missing type!');                                  }
  if (!date)                                                         { throw new Error('Create transaction failed: missing date!');                                  }
  if (!amount)                                                       { throw new Error('Create transaction failed: missing amount!');                                }
  if (isNaN(Number(amount)))                                         { throw new Error('Create transaction failed: amount is not a number!');                        }
  if (!dbTransactionTypes.includes(type))                            { throw new Error(`Create transaction failed: ${type} is not a valid type!`);                   }
  if (payMethod   && !dbPayMethods.includes(payMethod))              { throw new Error(`Create transaction failed: ${payMethod} is not a valid payment method!`);    }
  if (payer       && type !== 'Payment')                             { throw new Error(`Create transaction failed: payer is now allowed with type ${type}!`);        }
  if (payee       && type !== 'Reimbursement')                       { throw new Error(`Create transaction failed: payee is now allowed with type ${type}!`);        }
  if (payMethod   && type !== 'Payment' && type !== 'Reimbursement') { throw new Error(`Create transaction failed: pay method is now allowed with type ${type}!`);   }
  if (checkNumber && type !== 'Payment' && type !== 'Reimbursement') { throw new Error(`Create transaction failed: check number is now allowed with type ${type}!`); }

  return set(ref(getDatabase(), `transactions/${buildingId}/${unitId}/${tenancyId}/${date} ${abbreviateTransactionType(type)} m ${push(ref(getDatabase())).key}`), {
    type,
    date,
    amount:      Number(amount),
    payer:       payer       || null,
    payee:       payee       || null,
    payMethod:   payMethod   || null,
    checkNumber: checkNumber || null,
    memo:        memo        || null
  });
}

export function dbUpdateTransaction(buildingId,
                                    unitId,
                                    tenancyId,
                                    transactionId,
                                    { payer, payee, payMethod, checkNumber, memo }) {
  if (!buildingId)                                                   { throw new Error('Update transaction failed: missing building ID!');                           }
  if (!unitId)                                                       { throw new Error('Update transaction failed: missing unit ID!');                               }
  if (!tenancyId)                                                    { throw new Error('Update transaction failed: missing tenancy ID!');                            }
  if (!transactionId)                                                { throw new Error('Update transaction failed: missing transaction ID!');                        }
  const type = extractTypeFromTransactionId(transactionId);
  if (payMethod   && !dbPayMethods.includes(payMethod))              { throw new Error(`Update transaction failed: ${payMethod} is not a valid payment method!`);    }
  if (payer       && type !== 'Payment')                             { throw new Error(`Update transaction failed: payer is now allowed with type ${type}!`);        }
  if (payee       && type !== 'Reimbursement')                       { throw new Error(`Update transaction failed: payee is now allowed with type ${type}!`);        }
  if (payMethod   && type !== 'Payment' && type !== 'Reimbursement') { throw new Error(`Update transaction failed: pay method is now allowed with type ${type}!`);   }
  if (checkNumber && type !== 'Payment' && type !== 'Reimbursement') { throw new Error(`Update transaction failed: check number is now allowed with type ${type}!`); }

  return update(ref(getDatabase(), `transactions/${buildingId}/${unitId}/${tenancyId}/${transactionId}`), {
    payer:       payer       || null,
    payMethod:   payMethod   || null,
    payee:       payee       || null,
    checkNumber: checkNumber || null,
    memo:        memo        || null
  });
}

export function dbDeleteTransaction(buildingId, unitId, tenancyId, transactionId) {
  if (!buildingId)    { throw new Error('Delete transaction failed: missing building ID!');    }
  if (!unitId)        { throw new Error('Delete transaction failed: missing unit ID!');        }
  if (!tenancyId)     { throw new Error('Delete transaction failed: missing tenancy ID!');     }
  if (!transactionId) { throw new Error('Delete transaction failed: missing transaction ID!'); }

  remove(ref(getDatabase(), `transactions/${buildingId}/${unitId}/${tenancyId}/${transactionId}`));
}

/******************************************************************************
 * React hooks
 ******************************************************************************/

 export function useDbTransactionsWithRunningBalances(buildingId, unitId, tenancyId) {
   const [transactions, setTransactions] = React.useState();
   const [balance,      setBalance]      = React.useState();

   React.useEffect(() => {
     if (buildingId && unitId && tenancyId) {
       return onValue(ref(getDatabase(), `transactions/${buildingId}/${unitId}/${tenancyId}`), snapshot => {
         const transactions = snapshot.val();

         if (transactions && typeof transactions === 'object') {
           let runningBalance = currency();

           const sortedTransactions = Object.entries(snapshot.val()).map(([id, transaction]) => (
             { id, ...transaction }
           )).sort(transactionSorter);

           /* Adds running balance to each transaction. */
           Array.from(sortedTransactions).reverse().forEach((transaction, index) => {
             if (dbNegTransactionTypes.includes(transaction.type)) {
               runningBalance = runningBalance.subtract(transaction.amount);
             } else {
               runningBalance = runningBalance.add(transaction.amount);
             }
             sortedTransactions[sortedTransactions.length - 1 - index].runningBalance = runningBalance.value;
           });

           setTransactions(sortedTransactions);
           setBalance(runningBalance.value);

         } else {
           setTransactions(null);
           setBalance(0);
         }
       });
     } else {
       setTransactions();
       setBalance();
     }
   }, [buildingId, unitId, tenancyId]);

   return [transactions, balance];
 }

export function useDbTransaction(buildingId, unitId, tenancyId, transactionId) {
  const [value, setValue] = React.useState();

  React.useEffect(() => {
    if (buildingId && unitId && tenancyId && transactionId) {
      return onValue(ref(getDatabase(), `transactions/${buildingId}/${unitId}/${tenancyId}/${transactionId}`), snapshot => {
        setValue(snapshot.val());
      });
    } else {
      setValue();
    }
  }, [buildingId, unitId, tenancyId, transactionId]);

  return value;
}

/******************************************************************************
 * Helper functions
 ******************************************************************************/

function transactionSorter(firstTransaction, secondTransaction) {
  const firstTimestamp  = firstTransaction.created  || Date.parse(firstTransaction.date);
  const secondTimestamp = secondTransaction.created || Date.parse(secondTransaction.date);
  const timestampDiff = secondTimestamp - firstTimestamp;

  if (timestampDiff === 0) {
    const firstTypeAbbreviation = extractTypeAbbreviationFromTransactionId(firstTransaction.id);
    const secondTypeAbbreviation = extractTypeAbbreviationFromTransactionId(secondTransaction.id);
    const typeAbbreviationCompare = transactionTypeAbbreviationOrder(firstTypeAbbreviation)
                                  - transactionTypeAbbreviationOrder(secondTypeAbbreviation);

    return typeAbbreviationCompare;
  } else {
    return timestampDiff;
  }
}

function abbreviateTransactionType(type) {
  switch (type) {
    case 'Rent':                    return 'rent';
    case 'Payment':                 return 'pymt';
    case 'Reimbursement':           return 'rbmt';
    case 'Late Fee':                return 'ltfe';
    case 'Surcharge':               return 'srch';
    case 'Credit':                  return 'crdt';
    case 'Security Deposit':        return 'secd';
    case 'Security Deposit Return': return 'sdrt';
    default: throw new Error(`Can not determine type symbol! ${type} is not a valid transaction type.`);
  }
}

function abbreviationToType(abbreviation) {
  switch (abbreviation) {
    case 'rent': return 'Rent';
    case 'pymt': return 'Payment';
    case 'rbmt': return 'Reimbursement';
    case 'ltfe': return 'Late Fee';
    case 'srch': return 'Surcharge';
    case 'crdt': return 'Credit';
    case 'secd': return 'Security Deposit';
    case 'sdrt': return 'Security Deposit Return';
    default: throw new Error(`Abbreviation ${abbreviation} is not a valid transaction type abbreviation.`);
  }
}

function transactionTypeAbbreviationOrder(abbreviation) {
  switch (abbreviation) {
    case 'rent': return 7;
    case 'pymt': return 1;
    case 'rbmt': return 2;
    case 'ltfe': return 5;
    case 'srch': return 6;
    case 'crdt': return 4;
    case 'secd': return 8;
    case 'sdrt': return 3;
    default: throw new Error(`Can not determine type symbol! ${abbreviation} is not a valid transaction type abbreviation.`);
  }
}

function extractTypeAbbreviationFromTransactionId(id) {
  return id.slice(11, 15);
}

function extractTypeFromTransactionId(id) {
  return abbreviationToType(extractTypeAbbreviationFromTransactionId(id));
}
