import   React    from 'react';
import { Link }   from 'react-router-dom';
import   currency from 'currency.js'
import   dayjs    from 'dayjs';

import                                  'widgets/button/button-v2.scss';
import { StreetView }              from 'widgets/street-view/street-view';
import { FileRoomUnit }            from 'pages/file-room/file-room-unit';
import { LoginContext }            from 'walls/login-wall/login-wall'
import { useDbBuildingMemberRole } from 'backend-layer/db/db-memberships';

export function FileRoomBuilding({ buildingId, building }) {
  const login = React.useContext(LoginContext);
  const role  = useDbBuildingMemberRole(buildingId, login.uid);

  const [streetAddress, locality] = React.useMemo(() => {
    if (building.address) {
      const firstComma = building.address.indexOf(',');
      return [building.address.slice(0, firstComma),
              building.address.slice(firstComma + 1)];
    } else {
      return [undefined, undefined];
    }
  }, [building.address]);

  const [totalRents, unpaidCount] = React.useMemo(() => {
    let runningTotalRents = currency();
    let runningUnpaidCount = 0;
    if (building.units) {
      Object.values(building.units).forEach(unit => {
        if (unit.tenancies) {
          Object.values(unit.tenancies).forEach(tenancy => {
            let isActive = tenancy.moveOutDate === undefined || tenancy.moveOutDate === null
                                                             || !dayjs().isAfter(tenancy.moveOutDate, 'day');
            if (isActive) {
              if (typeof tenancy.rent === 'number') { runningTotalRents = runningTotalRents.add(tenancy.rent); }
              if (tenancy.balance >= 0.01)          { runningUnpaidCount += 1; }
            }
          });
        }
      });
    }
    return [runningTotalRents.value, runningUnpaidCount]
  }, [building.units]);

  return (
    <div className="building">
      <StreetView lat={building.lat} lng={building.lng} />

      {(role === 'manager' &&
        <Link className="building-address btn-unstyled" to={`/management/building?buildingId=${buildingId}`}>
          {streetAddress}<br />
          {locality}
        </Link>
      )|| (
        <div className="building-address">
          {streetAddress}<br />
          {locality}
        </div>
      )}

      {role === 'manager' &&
        <div className="building-financials flex-row">
          <i className="material-icons">sell</i>
          {typeof totalRents === 'number' && totalRents.toLocaleString('en-US', { minimumFractionDigits: 2 })}
          <div className="flex-1" />
          {(unpaidCount > 0 &&
            <>
              <i className="material-icons">report</i>
              {unpaidCount} unpaid
            </>
          ) || (unpaidCount === 0 &&
            <>
              <i className="material-icons">verified</i>
              all paid
            </>
          )}
        </div>
      }

      {building.units && Object.entries(building.units).map(([unitId, unit]) =>
        <FileRoomUnit key={unitId}
                      role={role}
                      buildingId={buildingId}
                      unitId={unitId}
                      unit={unit} />
      )}
    </div>
  );
}
