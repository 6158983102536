import   React     from 'react';
import { getAuth } from 'firebase/auth';
import { getDatabase,
         ref,
         update,
         remove,
         onValue } from 'firebase/database';

export const ADMIN_UIDS = [
  'ftIHsCziu4NgToehT5n0T6dfiPW2', // Marc Mouallem
  '9nRbnGt7rlQ1tiFMw6HsWNRuyBH3'  // Alex Marchi
];

/******************************************************************************
 * Create, read once, update & delete
 ******************************************************************************/

 /* To change a member's role add them again to the building with their new role. */
 export function dbAddBuildingMember(buildingId, uid, role) {
   if (!buildingId) { throw new Error('Adding building member failed: missing building ID!'); }
   if (!uid)        { throw new Error('Adding building member failed: missing user ID!');     }
   if (!role)       { throw new Error('Adding building member failed: missing role!');        }

   return update(ref(getDatabase()), {
     [`buildings/${buildingId}/members/${uid}`]: { role },
     [`memberships/${uid}/buildings/${buildingId}`]: true
   });
 }

 export function dbRemoveBuildingMember(buildingId, uid) {
   if (!buildingId) { throw new Error('Removing building member failed: missing building ID!'); }
   if (!uid)        { throw new Error('Removing building member failed: missing user ID!');     }

   return update(ref(getDatabase()), {
     [`buildings/${buildingId}/members/${uid}`]: null,
     [`memberships/${uid}/buildings/${buildingId}`]: null
   });
 }

 export function dbAddTenancyMember(buildingId, unitId, tenancyId, uid, affiliation) {
   if (!buildingId)  { throw new Error('Adding tenancy member failed: missing building ID!'); }
   if (!unitId)      { throw new Error('Adding tenancy member failed: missing unit ID!');     }
   if (!tenancyId)   { throw new Error('Adding tenancy member failed: missing tenancy ID!');  }
   if (!uid)         { throw new Error('Adding tenancy member failed: missing user ID!');     }
   if (!affiliation) { throw new Error('Adding tenancy member failed: missing affiliation!'); }

   return update(ref(getDatabase()), {
     [`buildings/${buildingId}/units/${unitId}/tenancies/${tenancyId}/members/${uid}`]: { affiliation },
     [`memberships/${uid}/tenancies/${tenancyId}`]: { buildingId, unitId }
   });
 }

 export function dbRemoveTenancyMember(buildingId, unitId, tenancyId, uid) {
   if (!buildingId) { throw new Error('Remove tenancy member failed: missing building ID!'); }
   if (!unitId)     { throw new Error('Remove tenancy member failed: missing unit ID!');     }
   if (!tenancyId)  { throw new Error('Remove tenancy member failed: missing tenancy ID!');  }
   if (!uid)        { throw new Error('Remove tenancy member failed: missing user ID!');     }

   return update(ref(getDatabase()), {
     [`buildings/${buildingId}/units/${unitId}/tenancies/${tenancyId}/members/${uid}`]: null,
     [`memberships/${uid}/tenancies/${tenancyId}`]: null
   });
 }

/******************************************************************************
 * React hooks
 ******************************************************************************/

export function useDbBuildingMemperships() {
  const [buildingMemberships, setBuildingMemberships] = React.useState();

  React.useEffect(() => {
    const uid = getAuth().currentUser.uid;
    return onValue(ADMIN_UIDS.includes(uid) ? ref(getDatabase(), `buildings`) // If admin gets all buildings
                                            : ref(getDatabase(), `memberships/${uid}/buildings`), snapshot => {
      setBuildingMemberships(snapshot.val());
    });
  }, []);

  return buildingMemberships;
}

export function useDbTenancyMemperships() {
  const [tenancyMemberships, setTenancyMemberships] = React.useState();

  React.useEffect(() => {
    const uid = getAuth().currentUser.uid;
    return onValue(ref(getDatabase(), `memberships/${uid}/tenancies`), snapshot => {
      setTenancyMemberships(snapshot.val());
    });
  }, []);

  return tenancyMemberships;
}

export function useDbBuildingMempershipCleanup(buildingId) {
  const [isMember, setIsMember] = React.useState();

  React.useEffect(() => {
    if (buildingId) {
      const uid = getAuth().currentUser.uid;
      return onValue(ref(getDatabase(), `buildings/${buildingId}/members/${uid}`), snapshot => {
        if (snapshot.exists()) {
          setIsMember(true);
        } else {
          remove(ref(getDatabase(), `memberships/${uid}/buildings/${buildingId}`));
          setIsMember(ADMIN_UIDS.includes(uid)); // Returns true if admin otherwise false
        }
      }, error => {
        if (error.message.startsWith('permission_denied')) {
          remove(ref(getDatabase(), `memberships/${uid}/buildings/${buildingId}`));
          setIsMember(false);
        }
      });
    }
  }, [buildingId]);

  return isMember;
}

export function useDbTenancyMempershipCleanup(buildingId, unitId, tenancyId) {
  const [isMember, setIsMember] = React.useState();

  React.useEffect(() => {
    if (buildingId && unitId && tenancyId) {
      const uid = getAuth().currentUser.uid;
      return onValue(ref(getDatabase(), `buildings/${buildingId}/units/${unitId}/tenancies/${tenancyId}/members/${uid}`), snapshot => {
        if (snapshot.exists()) {
          setIsMember(true);
        } else {
          remove(ref(getDatabase(), `memberships/${uid}/tenancies/${tenancyId}`))
          setIsMember(ADMIN_UIDS.includes(uid)); // Returns true if admin otherwise false
        }
      }, error => {
        if (error.message.startsWith('permission_denied')) {
          remove(ref(getDatabase(), `memberships/${uid}/tenancies/${tenancyId}`))
          setIsMember(false);
        }
      });
    }
  }, [buildingId, unitId, tenancyId]);

  return isMember;
}

export function useDbBuildingMembers(buildingId) {
  const [buildingMembers, setBuildingMembers] = React.useState();

  React.useEffect(() => {
    if (buildingId) {
      return onValue(ref(getDatabase(), `buildings/${buildingId}/members`), snapshot => {
        setBuildingMembers(snapshot.val());
      });
    }
  }, [buildingId]);

  return buildingMembers;
}

export function useDbBuildingMemberRole(buildingId, uid) {
  const [role, setRole] = React.useState();

  React.useEffect(() => {
    if (buildingId && uid) {
      if (ADMIN_UIDS.includes(uid)) {
        setRole('manager');
      } else {
        return onValue(ref(getDatabase(), `buildings/${buildingId}/members/${uid}/role`), snapshot => {
          setRole(snapshot.val());
        });
      }
    }
  }, [buildingId, uid]);

  return role;
}
