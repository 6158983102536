import React from 'react';

import { PgTextField }     from 'widgets/text-field/text-field';
import { getGooglePlaces } from 'services/google-places/google-places';

export const AddressField = React.memo(({ className,
                                          label,
                                          autoFocus,
                                          readOnly,
                                          address,
                                          usaAddress, // Broken into: { streetAddress, city, state (2 letters), zip }
                                          lat,
                                          lng,
                                          googlePlaceId,
                                          onChangeAddress,
                                          onChangeUsaAddress,
                                          onChangeLat,
                                          onChangeLng,
                                          onChangeGooglePlaceId }) => {

  const [googlePlaces, setGooglePlaces] = React.useState(); // Google Places API
  const [autoComplete, setAutoComplete] = React.useState(); // Goolge address autocomplete service
  const [inputElement, setInputElement] = React.useState();

  const clearLocation= React.useCallback(e => {
    onChangeAddress ? onChangeAddress('')
                    : e.target.closest('button').nextSibling.value = '';

    onChangeUsaAddress    && onChangeUsaAddress();
    onChangeLat           && onChangeLat();
    onChangeLng           && onChangeLng();
    onChangeGooglePlaceId && onChangeGooglePlaceId();

    inputElement?.focus();
  }, [
    onChangeAddress,
    onChangeUsaAddress,
    onChangeLat,
    onChangeLng,
    onChangeGooglePlaceId,
    inputElement
  ]);

  const init= React.useCallback(async input => {
    setInputElement(input);

    /* Build auto complete options */
    const autoCompleteOptions = { types: ['address'] };
    if (onChangeUsaAddress) { autoCompleteOptions.componentRestrictions = { country: ['us'] }; }

    const googlePlaces = await getGooglePlaces();
    const autoComplete = new googlePlaces.maps.places.Autocomplete(input, autoCompleteOptions);
    autoComplete.addListener('place_changed', () => {
      /* Google Place docs found at
       * https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult */
      const place = autoComplete.getPlace();

      onChangeAddress       && onChangeAddress      (place.formatted_address);
      onChangeUsaAddress    && onChangeUsaAddress   (parseUsaAddress(place.address_components));
      onChangeLat           && onChangeLat          (place.geometry && place.geometry.location && place.geometry.location.lat());
      onChangeLng           && onChangeLng          (place.geometry && place.geometry.location && place.geometry.location.lng());
      onChangeGooglePlaceId && onChangeGooglePlaceId(place.place_id);
    });

    /* Persist Google Place and auto complete service so they can be turned off
     * when in read only mode. */
    setGooglePlaces(googlePlaces);
    setAutoComplete(autoComplete);
  }, [
    onChangeAddress,
    onChangeUsaAddress,
    onChangeLat,
    onChangeLng,
    onChangeGooglePlaceId
  ]);

  const uninit= React.useCallback(input => {
    googlePlaces?.maps.event.clearInstanceListeners(input);
    autoComplete?.unbindAll();
  }, [
    googlePlaces,
    autoComplete
  ]);

  /* Custom validation for USA address, if and only if onChangeUsaAddress is defined. */
  React.useEffect(() => {
    if (inputElement) { // Wait for inputElement to get defined by init().
      if (onChangeUsaAddress) {
        if (isValidUsaAddress(usaAddress)) {
          inputElement.setCustomValidity('');
        } else {
          inputElement.setCustomValidity('Must select a valid USA address.');
        }
      } else {
        inputElement.setCustomValidity('');
      }
    }
  }, [
    inputElement,
    usaAddress,
    onChangeUsaAddress
  ]);

  return (
    <PgTextField className={`field-address${className ? ` ${className}` : ''}`}
                 label={label}
                 autoFocus={autoFocus}
                 icon="clear"
                 required
                 readOnly={readOnly}
                 onEditable={init}
                 onReadOnly={uninit}
                 placeholder=""
                 value={address}
                 onChange={e => onChangeAddress && onChangeAddress(e.target.value)}
                 onClickIcon={e => clearLocation(e)} />
  );
});

function parseUsaAddress(addressComponents) {
  var streetNumber,
      street,
      city,
      state,
      zip;

  addressComponents && addressComponents.forEach(component => {
    if (component.types.includes('street_number')) {
      streetNumber = component.short_name;
    } else if (component.types.includes('route')) {
      street = component.short_name;
    } else if (component.types.includes('locality')) {
      city = component.long_name;
    } else if (component.types.includes('administrative_area_level_1')) {
      state = component.short_name;
    } else if (component.types.includes('postal_code')) {
      zip = component.short_name;
    }
  });

  return streetNumber && street && city && state && zip ? {
    streetAddress: `${streetNumber} ${street}`,
    city,
    state,
    zip
  } : null;
}

function isValidUsaAddress(usaAddress) {
  return Boolean (
    usaAddress                    &&
    usaAddress.streetAddress      &&
    usaAddress.city               &&
    usaAddress.state              &&
    usaAddress.state.length === 2 &&
    usaAddress.zip
  );
}
