import   React        from 'react';
import { useHistory } from "react-router-dom";

import                          'widgets/button/button-v2.scss';
import { dbDeleteTenancy } from 'backend-layer/db/db-tenancies';

export const WidgetDeleteTenancy = React.memo(({ buildingId,
                                                 unitId,
                                                 tenancyId,
                                                 onExit }) => {
  const history = useHistory();

  return (
    <div className="widget">
      <p className="top-spacer-none">
        Are you sure you want to delete this tenancy? This action is not
        reversable, and will delete all transaction records pertaining to this
        tenancy.
      </p>
      <div className="flex-row">
        <div className="flex-1"></div>
        <button className="btn-remove"
                type="button"
                onClick={e => { history.replace('/management/file-room'); dbDeleteTenancy(buildingId, unitId, tenancyId); }}>
          Delete
        </button>
        <button className="btn-primary left-spacer-xs"
                type="button"
                onClick={onExit}>
          Cancel
        </button>
      </div>
    </div>
  );
});
