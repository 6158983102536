import   React     from 'react';
import { NavLink } from 'react-router-dom';

import                                      'widgets/forms/index.scss';
import { dbSetLastPortalToTenant,
         dbSetLastPortalToManagement } from 'backend-layer/db/db-users';

export function FormToTenantPortal({ onExit }) {
  return (
    <section className="widget-form widget-form-switch-portal">
      <p>
        You're currently using the management portal. Click on the link below to
        switch to the tenant portal.
      </p>
      <div className="flex-row top-spacer-md">
        <div className="flex-1" />
        <NavLink className="btn-change" to="/tenant" onClick={dbSetLastPortalToTenant}>Tenant Portal</NavLink>
        <button className="btn-primary left-spacer-sm" onClick={onExit}>Cancel</button>
      </div>
    </section>
  );
}

export function FormToManagementPortal({ onExit }) {
  return (
    <section className="widget-form widget-form-switch-portal">
      <p>
        You're currently using the tenant portal. If you're a landlord, or work
        for a landlord, click on the link below to switch to the management portal.
      </p>
      <div className="flex-row top-spacer-md">
        <div className="flex-1" />
        <NavLink className="btn-change" to="/management/file-room" onClick={dbSetLastPortalToManagement}>Management Portal</NavLink>
        <button className="btn-primary left-spacer-sm" onClick={onExit}>Cancel</button>
      </div>
    </section>
  );
}
