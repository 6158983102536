import React from 'react';

import                              'widgets/button/button-v2.scss';
import { PgTextField }         from 'widgets/text-field/text-field';
import { Select }              from 'widgets/select/select';
import { dbPayMethods,
         dbCreateTransaction } from 'backend-layer/db/db-transactions';

export function WidgetRecordPayment({ buildingId,
                                      unitId,
                                      tenancyId,
                                      onExit,
                                      onRecordReimbursement }) {

  const [date,        setDate]        = React.useState('');
  const [amount,      setAmount]      = React.useState('');
  const [payer,       setPayer]       = React.useState('');
  const [payMethod,   setPayMethod]   = React.useState('');
  const [checkNumber, setCheckNumber] = React.useState('');
  const [memo,        setMemo]        = React.useState('');

  const submit = React.useCallback(e => {
    e.preventDefault();
    dbCreateTransaction(buildingId,
                        unitId,
                        tenancyId,
                        'Payment',
                        date,
                        amount,
                        { payer, payMethod, checkNumber, memo });
    onExit();
  }, [buildingId, unitId, tenancyId, onExit, date, amount, payer, payMethod, checkNumber, memo]);

  const reset = React.useCallback(e => {
    e.preventDefault();
    onExit();
  }, [onExit]);

  return (
    <form className="widget" onSubmit={submit} onReset={reset}>
      <div className="flex-row">
        <PgTextField className="flex-1"
                     label="Date"
                     name="date"
                     type="date"
                     value={date}
                     onChange={e => setDate(e.target.value)}
                     required />
        <PgTextField className="flex-1 left-spacer-sm"
                     label="Amount"
                     name="amount"
                     type="number"
                     inputMode="decimal"
                     step="0.01"
                     min="0"
                     value={amount}
                     onChange={e => setAmount(e.target.value)}
                     required />
      </div>

      <div className="flex-row top-spacer-sm">
        <Select className="flex-2"
                label="Method of Payment"
                name="payMethod"
                includeEmpty
                selections={dbPayMethods}
                value={payMethod}
                onChange={e => setPayMethod(e.target.value)} />

        <PgTextField className="flex-3 left-spacer-sm"
                     label="Check / Confirmation #"
                     name="checkNumber"
                     value={checkNumber}
                     onChange={e => setCheckNumber(e.target.value)} />
      </div>

      <PgTextField className="top-spacer-sm"
                   label="Paid by"
                   name="payer"
                   value={payer}
                   onChange={e => setPayer(e.target.value)} />

      <PgTextField className="top-spacer-sm"
                   label="Memo"
                   name="memo"
                   multiline
                   value={memo}
                   onChange={e => setMemo(e.target.value)} />

      <div className="flex-row top-spacer-md">
        <button className="btn-alt"                    type="button" onClick={onRecordReimbursement}>Reimbursement</button>
        <div className="flex-1" />
        <button className="btn-change"                 type="submit">Record Payment</button>
        <button className="btn-primary left-spacer-sm" type="reset" >Cancel</button>
      </div>
    </form>
  );
}
