import React from 'react';

import { PgTextField } from 'widgets/text-field/text-field';

export function CardUnit({ readOnly,
                           updatedNumOfUnits,
                           numOfTenancies,
                           designator,
                           onChangeDesignator,
                           onRemoveUnit }) {
  return (
    <div className="card-unit">
      <PgTextField className="designator"
                   label="Unit #"
                   name="designator"
                   readOnly={readOnly}
                   required={updatedNumOfUnits > 1}
                   value={designator}
                   onChange={e => onChangeDesignator(e.target.value)} />

      {(readOnly &&
        <p>
          {(!numOfTenancies &&
            <em>
              Unit has no tenancies
            </em>
          ) || (numOfTenancies === 1 &&
            <em>
              Unit has 1 tenancy
            </em>
          ) || (
            <em>
              Unit has {numOfTenancies} tenancies
            </em>
          )}
        </p>
      ) || (updatedNumOfUnits === 1 &&
        <p>
          Must have at least one unit
        </p>
      ) || (numOfTenancies &&
        <p>
          Can't remove has tenancies
        </p>
      ) || (
        <div className="flex-row top-spacer-sm">
          <div className="flex-1" />
          <button className="btn-remove" type="button" onClick={onRemoveUnit}>Remove</button>
        </div>
      )}
    </div>
  );
}
