import React from 'react';

import 'widgets/info-box/info-box.css';

export function InfoBox({ className, title, children, isWarning, action, actionTitle }) {
  return (
    <div className={`info-box${isWarning ? ' warning' : ''}${className ? ` ${className}` : ''}`}>
      <i className="material-icons">info</i>
      <div>
        <div className="title">{title}</div>
        {children}
        {action && <button className="btn-primary" type="button" onClick={action}>{actionTitle || 'Fix'}</button>}
      </div>
    </div>
  );
}
