import   React     from 'react';
import { getAuth,
         signOut } from 'firebase/auth';
import   currency  from 'currency.js'
import   dayjs     from 'dayjs';

import                                  'pages/file-room/file-room.scss';
import { FileRoomBuildingCreator } from 'pages/file-room/file-room-building-creator';
import { FileRoomBuilding }        from 'pages/file-room/file-room-building';
import { FormUserProfile }         from 'widgets/forms/user-profile';
import { FormNotifications }       from 'widgets/forms/notifications';
import { FormToTenantPortal }      from 'widgets/forms/switch-portal';
import { ButtonIconOverText }      from 'widgets/button/button-icon-over-text';
import { LoginContext }            from 'walls/login-wall/login-wall';
import { UrlContext }              from 'walls/url-parsing-wall/url-parsing-wall';
import { AppContext }              from 'store/store';
import { useDbUser }               from 'backend-layer/db/db-users';

export function FileRoom({ hidden }) {
  const login = React.useContext(LoginContext);
  const url = React.useContext(UrlContext);
  const user = useDbUser(login.uid);
  const [state] = React.useContext(AppContext);
  const [toolbar, setToolbar] = React.useState();

  React.useEffect(() => {
    if (url.page !== 'file-room') {
      setToolbar();
    }
  }, [url.page]);

  /* Use of a ref instead of a React state variable allows changing
   * isBuildingCreatorOpen without triggering a render. We can then manually
   * trigger a render when opening the building creator or closing the building
   * creator without adding a building. After adding a building, we set the
   * isBuildingCreatorOpen ref’s current value as false, and a render is
   * triggered when the buildings list is updated with the new building.

   * This serves as an alternative to using a state variable for
   * isBuildingCreatorOpen, as changing a state variable would trigger two
   * renders: one to close the building creator and another when the building is
   * added, which would create a flashing effect. */
  const [, forceUpdate] = React.useReducer(x => x + 1, 0);
  const isBuildingCreatorOpen = React.useRef(false);

  const openBuildingCreator = React.useCallback(() => {
    isBuildingCreatorOpen.current = true;
    forceUpdate();
  }, []);

  const closeBuildingCreator = React.useCallback(() => {
    isBuildingCreatorOpen.current = false;
    forceUpdate();
  }, []);

  const closeBuildingCreatorOnNextUpdate = React.useCallback(() => {
    isBuildingCreatorOpen.current = false;
  }, []);

  const { numOfBuildings, numOfUnits, numOfUnpaid, totalRents, totalUnpaid } = React.useMemo(() => {
    let runningNumOfBuildings = 0;
    let runningNumOfUnits     = 0;
    let runningtNumOfUnpaid   = null;
    let runningTotalRents     = null;
    let runningTotalUnpaid    = null;

    if (state.buildings) {
      const listOfBuildings = Object.values(state.buildings);
      runningNumOfBuildings += listOfBuildings.length;

      listOfBuildings.forEach(building => {
        if (building.units) {
          const listOfUnits = Object.values(building.units);
          runningNumOfUnits += listOfUnits.length;

          if (building.members[login.uid]?.role === 'manager') {
            if (runningtNumOfUnpaid === null) { runningtNumOfUnpaid = 0          };
            if (runningTotalRents   === null) { runningTotalRents   = currency() };
            if (runningTotalUnpaid  === null) { runningTotalUnpaid  = currency() };

            listOfUnits.forEach(unit => {
              if (unit.tenancies) {
                Object.values(unit.tenancies).forEach(tenancy => {
                  const isActive = !tenancy.moveOutDate ||
                                   !dayjs().isAfter(tenancy.moveOutDate, 'day'); // Passing granularity of day, since we would consider a tenancy not moved out if the move out date were today.
                  if (isActive) {
                    if (typeof tenancy.rent === 'number') {
                      runningTotalRents = runningTotalRents.add(tenancy.rent);
                    }
                    if (typeof tenancy.balance === 'number' && tenancy.balance >= 0.01) {
                      runningtNumOfUnpaid += 1;
                      runningTotalUnpaid = runningTotalUnpaid.add(tenancy.balance);
                    }
                  }
                });
              }
            });
          }
        }
      });
    }

    return {
      numOfBuildings: runningNumOfBuildings,
      numOfUnits:     runningNumOfUnits,
      numOfUnpaid:    runningtNumOfUnpaid,
      totalRents:     runningTotalRents?.value  ?? null,
      totalUnpaid:    runningTotalUnpaid?.value ?? null
    };
  }, [state.buildings, login]);

  return (
    <main className="page-file-room" hidden={hidden}>
      <div className="toolbar">
        <ButtonIconOverText icon="handyman"      label={`Issues (${state.openIssues?.length})`}  pathname="/management/maintenance" />
        <ButtonIconOverText icon="badge"         label="Profile"                                 onClick={() => { toolbar !== 'profile' ? setToolbar('profile') : setToolbar(); }} backgroundImage={user?.avatar} />
        <ButtonIconOverText icon="night_shelter" label="Portal"                                  onClick={() => { toolbar !== 'portal'  ? setToolbar('portal')  : setToolbar(); }} />
        <ButtonIconOverText icon="logout"        label="Logout"                                  onClick={() => { signOut(getAuth()); }} />
      </div>
      {
        (toolbar === 'profile'        && <FormUserProfile onPhone={() => { setToolbar('notifications'); }} onExit={() => { setToolbar(); }} />)          ||
        (toolbar === 'notifications'  && <FormNotifications                                                onExit={() => { setToolbar('profile'); }} />) ||
        (toolbar === 'portal'         && <FormToTenantPortal                                               onExit={() => { setToolbar(); }} />)
      }

      <div className="buildings">
        <header>
          <h1>Buildings & Rent
            <small><i className="icon-building-count material-icons">apartment< /i>Buildings: {numOfBuildings}</small>
            <small><i className="icon-unit-count material-icons"    >door_front</i>Units: {numOfUnits}</small>

            {totalRents !== null &&
              <small><i className="icon-total-rent material-icons">sell</i>Rent: {typeof totalRents === 'number' && totalRents.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</small>
            }
            {(numOfUnpaid > 0 &&
              <>
                <small><i className="icon-unpaid material-icons">report</i>{numOfUnpaid} unpaid</small>
                <small>Owed {typeof totalUnpaid === 'number' && totalUnpaid.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</small>
              </>
            ) || (numOfUnpaid === 0 &&
              <small><i className="icon-everyone-paid material-icons">verified</i>everyone paid</small>
            )}
          </h1>
          <p>New landlords, add buildings to begin. Staff, share your account email <em>{login.email}</em> with landlords for access.</p>
          <p>Landlords, click a building's address to edit details or manage access.</p>
          <p>Don't hesitate to reach out to us at <a href="mailto:contact@apartmentify.com" target="_blank" rel="noreferrer">contact@apartmentify.com</a></p>
          <button className="btn-primary" onClick={openBuildingCreator}>Add Building</button>
        </header>

        {(isBuildingCreatorOpen.current &&
          <FileRoomBuildingCreator onAddBuilding={closeBuildingCreatorOnNextUpdate}
                                   onCancel={closeBuildingCreator} />
        )}

        {state.buildings && Object.entries(state.buildings).sort(geoSort).map(([buildingId, building]) =>
          <FileRoomBuilding key={buildingId}
                            buildingId={buildingId}
                            building={building} />
        )}
      </div>
    </main>
  );
}

export function geoSort([firstBuildingId, firstBuilding], [secondBuildingId, secondBuilding]) {
  return firstBuilding.geoOrder - secondBuilding.geoOrder ||
         secondBuildingId.localeCompare(firstBuildingId); // Fallback to last added appears first
}
