import React from 'react';

export const FormattedDate = React.memo(({ date }) => {
  return date !== undefined ? (
    <>
      {new Date(date).toLocaleDateString('en-us', { year    : 'numeric',
                                                    month   : 'short',
                                                    day     : 'numeric',
                                                    timeZone: 'UTC' })}
    </>
  ) : null;
});

export const FormattedMonth = React.memo(({ date }) => {
  return date !== undefined ? (
    <>
      {new Date(date).toLocaleDateString('en-us', { year    : 'numeric',
                                                    month   : 'short',
                                                    timeZone: 'UTC' })}
    </>
  ) : null;
});

export const FormattedMoney = React.memo(({ amount }) => {
  return amount !== undefined ? (
    <>
      {Number(amount).toLocaleString('en-US', { style: 'currency',
                                                currency: 'USD' })}
    </>
  ) : null;
});
