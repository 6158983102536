import   React        from 'react';
import { useLocation,
         useHistory } from 'react-router-dom';

import { PortalSelector }  from 'walls/url-parsing-wall/portal-selector';
import { dbGetLastPortal } from 'backend-layer/db/db-users';

export const UrlContext   = React.createContext();
export const validPortals = ['management', 'tenant'];

export function UrlParsingWall({ children }) {
  const location = useLocation();
  const history  = useHistory();

  const [url,                setUrl]                = React.useState();
  const [showPortalSelector, setShowPortalSelector] = React.useState();

  React.useEffect(() => {
    const [, portal, page] = location.pathname.split('/');
    const params = new URLSearchParams(location.search);

    if(portal && validPortals.includes(portal)) {
      setUrl({
        portal, page,
        buildingId: params.get('buildingId'),
        unitId:     params.get('unitId'),
        tenancyId:  params.get('tenancyId')
      });

    } else {
      dbGetLastPortal().then(portal => {
        if (portal && validPortals.includes(portal)) {
          history.replace(`/${portal}`);
        } else {
          setShowPortalSelector(true);
        }
      });
    }
  }, [location, history]);

  if (url) {
    return <UrlContext.Provider value={url}>{children}</UrlContext.Provider>;
  } else if (showPortalSelector) {
    return <PortalSelector />;
  } else {
    return <div id="app-loading-indicator"></div>;
  }
}
