import React from 'react';

import                              'widgets/forms/index.scss';
import                              'widgets/button/button-v2.scss';
import { InfoBox }             from 'widgets/info-box/info-box';
import { PgTextField }         from 'widgets/text-field/text-field';
import { AptfyCheckbox }       from 'widgets/checkbox/checkbox';
import { Files }               from 'widgets/file-uploader/file-uploader';
import { LoginContext }        from 'walls/login-wall/login-wall';
import { titleMaxChars,
         commentMaxChars }     from 'widgets/issue/forms';
import { useDbPhone }          from 'backend-layer/db/db-users';
import { dbCreateIssue }       from 'backend-layer/db/db-issues';

export function FormNewIssue({ buildingId, unitId, tenancyId, onExit }) {
  const login = React.useContext(LoginContext);
  const phoneNumber = useDbPhone(login.uid);

  const [title,       setTitle]       = React.useState('');
  const [description, setDescription] = React.useState('');
  const [pte,         setPte]         = React.useState(false);
  const [attachments, setAttachments] = React.useState();

  const submit = React.useCallback(e => {
    e.preventDefault();
    dbCreateIssue(buildingId,
                  unitId,
                  tenancyId,
                  title,
                  description,
                  pte,
                  attachments);
    onExit();
  }, [buildingId, unitId, tenancyId, title, description, pte, attachments, onExit]);

  const reset = React.useCallback(e => {
    e.preventDefault();
    onExit();
  }, [onExit]);

  return (
    <form className="widget-form widget-form-new-issue" role="main" onSubmit={submit} onReset={reset}>
      {phoneNumber === null &&
        <InfoBox className="bottom-spacer-md" title="Enable text notifications" isWarning>
          Don't miss important updates on your mainteance request such as
          scheduled appointments.
        </InfoBox>
      }

      <PgTextField label="Title"
                   placeholder="e.g. Bath sink clogged"
                   required
                   maxLength={titleMaxChars}
                   value={title}
                   onChange={e => setTitle(e.target.value)} />

      <PgTextField className="top-spacer-sm"
                   label="Description"
                   multiline
                   placeholder="Add further description if necessary. Must attach at least one image."
                   maxLength={commentMaxChars}
                   value={description}
                   onChange={e => setDescription(e.target.value)} />

      <Files className="top-spacer-sm" required onFiles={files => setAttachments(files)} />

      <AptfyCheckbox className="top-spacer-md"
                     title="Permission to Enter"
                     isChecked={pte}
                     onChange={e => setPte(e.target.checked)}>

        Grant permission to enter your unit during normal buisness hours to
        perform repairs. Without permission to enter you'll have to schedule a
        time directly with your management team. This may cause significant
        delays, please consider granting said permission.
      </AptfyCheckbox>

      <div className="flex-row top-spacer-lg">
        <div className="flex-1" />
        <button className="btn-change"                 type="submit">Submit Issue</button>
        <button className="btn-primary left-spacer-sm" type="reset" >Cancel</button>
      </div>
    </form>
  );
}
