import React from 'react';

import                                  'walls/url-parsing-wall/portal-selector.css';
import { ButtonIconOverText }      from 'widgets/button/button-icon-over-text';
import { dbSetRegisteredAsManagement,
         dbSetRegisteredAsTenant } from 'backend-layer/db/db-users';

export function PortalSelector({ children }) {
  return (
    <main className="portal-selector">

      <div className="portal-option_tenant">
        <h2>
          I'm a tenant
          <ButtonIconOverText className="top-spacer-md"
                              icon="bed"
                              label="Tenant Portal"
                              onClick={dbSetRegisteredAsTenant}
                              pathname="/tenant" />
        </h2>
      </div>

      <div className="portal-option_management">
        <h2>
          I'm a landlord, manager, or worker
          <ButtonIconOverText className="top-spacer-md"
                              icon="real_estate_agent"
                              label="Management Portal"
                              onClick={dbSetRegisteredAsManagement}
                              pathname="/management/file-room" />
        </h2>
      </div>

    </main>
  );
}
