import                         'react-toastify/dist/ReactToastify.css';
import   React            from 'react';
import { ToastContainer } from 'react-toastify';
import { useHistory }     from 'react-router-dom';

import { TenantDashboardPage }           from 'pages/tenant-dashboard/';
import { TenantStartGuidePage }          from 'pages/tenant-start-guide/';
import { LoginContext }                  from 'walls/login-wall/login-wall';
import { UrlContext }                    from 'walls/url-parsing-wall/url-parsing-wall';
import { useDbTenancyMemperships,
         useDbTenancyMempershipCleanup } from 'backend-layer/db/db-memberships';

export function AppTenant() {
  const history = useHistory();
  const url = React.useContext(UrlContext);
  const login = React.useContext(LoginContext);
  const tenancyMemberships = useDbTenancyMemperships(login.uid);
  const isMember = useDbTenancyMempershipCleanup(url.buildingId, url.unitId, url.tenancyId);

  /* Auto selects user's last added tenancy if they don't already have a tenancy
   * specified in the URL or if they are not a member of the specified tenancy,
   * and they are a member of at least one tenancy. */
  React.useEffect(() => {
    if ((!url.buildingId || !url.unitId || !url.tenancyId || isMember === false) && tenancyMemberships) {
      const [tenancyId, { buildingId, unitId }] = Object.entries(tenancyMemberships).pop();
      history.replace(`/tenant?buildingId=${buildingId}&unitId=${unitId}&tenancyId=${tenancyId}`);
    }
  }, [url, isMember, tenancyMemberships, history]);

  return (
    <>
      <ToastContainer hideProgressBar />

      {(isMember &&
        <TenantDashboardPage buildingId={url.buildingId} unitId={url.unitId} tenancyId={url.tenancyId} />
      ) || (tenancyMemberships === null &&
        <TenantStartGuidePage />
      )}
    </>
  );
}
