import   React           from 'react';
import { toast }         from 'react-toastify';
import { getAuth }       from 'firebase/auth';
import { getDatabase,
         ref,
         serverTimestamp,
         query,
         orderByChild,
         equalTo,
         startAt,
         limitToLast,
         push,
         set,
         update,
         remove,
         onValue }       from 'firebase/database';
import { getStorage,
         ref as bktRef,
         uploadBytes }   from 'firebase/storage';

import { useDbBuildingMemberRole } from 'backend-layer/db/db-memberships'

/******************************************************************************
 * Create, read once, update & delete
 ******************************************************************************/

export function dbCreateIssue(buildingId,
                              unitId,
                              tenancyId,
                              title,
                              description,
                              pte,
                              attachments) {
  const currentUser = getAuth().currentUser;
  if (!currentUser) { throw new Error('Issue creation failed: not logged in!');       }
  if (!buildingId)  { throw new Error('Issue creation failed: missing building Id!'); }
  if (!title)       { throw new Error('Issue creation failed: missing title!');       }

  const issueId = push(ref(getDatabase(), `issues/${buildingId}/issues`)).key
  const actionId = issueId; // Creation action shares ID with issue

  return update(ref(getDatabase(), `issues/${buildingId}`), {
    [`issues/${issueId}`]: {
      unitId       : unitId    || null,
      tenancyId    : tenancyId || null,
      submitted    : serverTimestamp(),
      updated      : serverTimestamp(),
      author       : currentUser.uid,
      title,
      pte          : Boolean(pte),
      lastComment  : description || null,
      lastCommenter: description ? currentUser.uid : null,
    },
    [`actions/${issueId}/${actionId}`]: {
      taken        : serverTimestamp(),
      actor        : currentUser.uid,
      originalTitle: title,
      comment      : description || null,
      attachments  : (attachments?.length > 0 && Array(attachments.length).fill({ exists: true })) || null
    }
  }).then(() => dbAttachAllToAction(buildingId, issueId, actionId, attachments))
    .then(() => issueId);
}

export function dbRetitleIssue(buildingId, issueId, title) {
  if (!buildingId) { throw new Error('Issue retitle failed: missing building ID!'); }
  if (!issueId)    { throw new Error('Issue retitle failed: missing issue ID!');    }
  if (!title)      { throw new Error('Issue retitle failed: missing new title!');   }

  return set(ref(getDatabase(), `issues/${buildingId}/issues/${issueId}/title`), title);
}

export function dbAssignIssue(buildingId, issueId, assignee) {
  const currentUser = getAuth().currentUser;
  if (!currentUser) { throw new Error('Issue assignment failed: not logged in!');    }
  if (!issueId)     { throw new Error('Issue assignment failed: missing issue ID!'); }

  if (assignee) {
    const actionId = push(ref(getDatabase(), `issues/${buildingId}/actions/${issueId}`)).key
    return update(ref(getDatabase(), `issues/${buildingId}`), {
      [`issues/${issueId}/updated`]: serverTimestamp(),
      [`issues/${issueId}/assignee`]: assignee,
      [`actions/${issueId}/${actionId}`]: {
        taken: serverTimestamp(),
        actor: currentUser.uid,
        assignee
      }
    });
  } else {
    return remove(ref(getDatabase(), `issues/${buildingId}/issues/${issueId}/assignee`));
  }
}

export function dbScheduleIssue(buildingId, issueId, appointment) {
  const currentUser = getAuth().currentUser;
  if (!currentUser) { throw new Error('Issue scheduling failed: not logged in!');       }
  if (!buildingId)  { throw new Error('Issue scheduling failed: missing building ID!'); }
  if (!issueId)     { throw new Error('Issue scheduling failed: missing issue ID!');    }

  const actionId = push(ref(getDatabase(), `issues/${buildingId}/actions/${issueId}`)).key
  return update(ref(getDatabase(), `issues/${buildingId}`), {
    [`issues/${issueId}/updated`]: serverTimestamp(),
    [`issues/${issueId}/appointment`]: appointment || null,
    [`actions/${issueId}/${actionId}`]: {
      taken: serverTimestamp(),
      actor: currentUser.uid,
      appointment: appointment || 'unscheduled'
    }
  });
}

export function dbCommentOnIssue(buildingId, issueId, comment, attachments) {
  const currentUser = getAuth().currentUser;
  if (!currentUser) { throw new Error('Issue comment post failed: not logged in!');       }
  if (!buildingId)  { throw new Error('Issue comment post failed: missing building ID!'); }
  if (!issueId)     { throw new Error('Issue comment post failed: missing issue ID!');    }
  if (!comment &&
      !attachments) { throw new Error('Issue comment post failed: missing comment and no attachments!'); }

  const actionId = push(ref(getDatabase(), `issues/${buildingId}/actions/${issueId}`)).key
  return update(ref(getDatabase(), `issues/${buildingId}`), {
    [`issues/${issueId}/updated`]: serverTimestamp(),
    [`issues/${issueId}/lastComment`]: comment || null,
    [`issues/${issueId}/lastCommenter`]: currentUser.uid,
    [`actions/${issueId}/${actionId}`]: {
      taken: serverTimestamp(),
      actor: currentUser.uid,
      comment: comment || null,
      attachments: attachments?.length > 0 ? Array(attachments.length).fill({ exists: true }) : null
    }
  }).then(() => dbAttachAllToAction(buildingId, issueId, actionId, attachments))
    .then(() => actionId);
}

export function dbCloseIssue(buildingId, issueId, fixed) {
  const currentUser = getAuth().currentUser;
  if (!currentUser)               { throw new Error('Issue closure failed: not logged in!');           }
  if (!buildingId)                { throw new Error('Issue closure failed: missing building ID!');     }
  if (!issueId)                   { throw new Error('Issue closure failed: missing issue ID!');        }
  if (typeof fixed !== 'boolean') { throw new Error('Issue closure failed: fixed must be a boolean!'); }

  const actionId = push(ref(getDatabase(), `issues/${buildingId}/actions/${issueId}`)).key
  return update(ref(getDatabase(), `issues/${buildingId}`), {
    [`issues/${issueId}/updated`]: serverTimestamp(),
    [`issues/${issueId}/closed`]: serverTimestamp(),
    [`actions/${issueId}/${actionId}`]: {
      taken: serverTimestamp(),
      actor: currentUser.uid,
      fixed
    }
  });
}

export function dbReopenIssue(buildingId, issueId) {
  const currentUser = getAuth().currentUser;
  if (!currentUser) { throw new Error('Issue reopen failed: not logged in!');       }
  if (!buildingId)  { throw new Error('Issue reopen failed: missing building ID!'); }
  if (!issueId)     { throw new Error('Issue reopen failed: missing issue ID!');    }

  const actionId = push(ref(getDatabase(), `issues/${buildingId}/actions/${issueId}`)).key;
  return update(ref(getDatabase(), `issues/${buildingId}`), {
    [`issues/${issueId}/updated`]: serverTimestamp(),
    [`issues/${issueId}/closed`]: null, // Removes value for closed to reopen
    [`actions/${issueId}/${actionId}`]: {
      taken: serverTimestamp(),
      actor: currentUser.uid,
      reopened: true
    }
  });
}

export function dbDeleteIssue(buildingId, issueId) {
  if (!buildingId)  { throw new Error('Issue deletion failed: missing building ID!'); }
  if (!issueId)     { throw new Error('Issue deletion failed: missing issue ID!');    }

  return update(ref(getDatabase(), `issues/${buildingId}`), {
    [`issues/${issueId}`] : null,
    [`actions/${issueId}`]: null
  });
}

/******************************************************************************
 * React read hooks
 ******************************************************************************/

export function useDbBuildingIssues(buildingId, isOpen, uid) {
  const role = useDbBuildingMemberRole(buildingId, uid);
  const [issuesList, setIssuesList] = React.useState();

  React.useEffect(() => {
    if (buildingId && uid && role) {
      return onValue(query(ref(getDatabase(), `issues/${buildingId}/issues`),
                     orderByChild('closed'),
                     isOpen ? equalTo(null) : startAt(false)), snapshot => {
        if (snapshot.exists()) {
          setIssuesList(
            Object.entries(snapshot.val()).map(([issueId, issue]) => ({
              buildingId,
              issueId,
              role,
              ...issue
            })).filter(issue => role === 'manager' || issue.assignee === uid)
          )
        } else {
          setIssuesList([]);
        }
      });
    }
  }, [buildingId, isOpen, uid, role]);

  return issuesList;
}

export function useDbTenancyIssues(buildingId, tenancyId) {
  const [openIssuesList,   setOpenIssuesList]   = React.useState();
  const [closedIssuesList, setClosedIssuesList] = React.useState();

  React.useEffect(() => {
    if (buildingId && tenancyId) {
      return onValue(query(ref(getDatabase(), `issues/${buildingId}/issues`),
                           orderByChild('tenancyId'),
                           equalTo(tenancyId)), snapshot => {
        if (snapshot.exists()) {
          const openIssues   = [];
          const closedIssues = [];
          Object.entries(snapshot.val()).forEach(([issueId, issue]) => {
            if (issue.closed) {
              closedIssues.push({ buildingId, issueId, ...issue });
            } else {
              openIssues.push({ buildingId, issueId, ...issue });
            }
          });
          setOpenIssuesList(openIssues.sort(openIssuesSorter));
          setClosedIssuesList(closedIssues.sort(closedIssuesSorter));
        } else {
          setOpenIssuesList([]);
          setClosedIssuesList([]);
        }
      });
    }
  }, [buildingId, tenancyId]);

  return [openIssuesList, closedIssuesList];
}

export function useDbActions(buildingId, issueId, lastOnly) {
  const [actions, setActions] = React.useState();

  React.useEffect(() => {
    if (buildingId && issueId) {
      if (lastOnly) {
        return onValue(query(ref(getDatabase(), `issues/${buildingId}/actions/${issueId}`), limitToLast(1)),snapshot => {
          setActions(snapshot.val())
        });
      } else {
        return onValue(ref(getDatabase(), `issues/${buildingId}/actions/${issueId}`), snapshot => {
          setActions(snapshot.val())
        });
      }
    }
  }, [buildingId, issueId, lastOnly]);

  return actions;
}

/******************************************************************************
 * Helper functions
 ******************************************************************************/

function dbAttachAllToAction(buildingId, issueId, actionId, attachments) {
  if (attachments?.length) {
    return toast.promise(() => Promise.all(
      Array.from(attachments).map((attachment, index) => (
        uploadBytes(
          bktRef(getStorage(), `buildings/${buildingId}/issues/${issueId}/actions/${actionId}/attachments/${index}`),
          attachment
        )
      ))
    ), {
      pending: 'Uploading images',
      success: 'Images uploaded successfully',
      error: 'Error uploading images'
    });
  }
}

export function openIssuesSorter(firstIssue, secondIssue) {
  if (firstIssue.submitted > secondIssue.submitted) {
    return -1;
  } else if (firstIssue.submitted < secondIssue.submitted) {
    return 1;
  } else {
    return 0;
  }
}

export function closedIssuesSorter(firstIssue, secondIssue) {
  if (firstIssue.closed > secondIssue.closed) {
    return -1;
  } else if (firstIssue.closed < secondIssue.closed) {
    return 1;
  } else {
    return 0;
  }
}
