import React from 'react';

import                        'widgets/issue/timeline.scss';
import { useDbActions }  from 'backend-layer/db/db-issues';
import { useDbUser,
         useDbUserName } from 'backend-layer/db/db-users';

export const IssueTimeline = React.memo(({ buildingId, issueId, isExpanded }) => {
  const actions = useDbActions(buildingId, issueId, !isExpanded);

  return (
    <div className="widget-issue-timeline">
      {actions && Object.entries(actions).map(([actionId, action]) =>
        <TimelineEntry key={actionId}
                       buildingId={buildingId}
                       issueId={issueId}
                       actionId={actionId}
                       action={action} />
      )}

      {actions && Object.keys(actions).length === 1 && isExpanded &&
        <p className="force-expansion">no actions yet</p>
      }
    </div>
  );
});

const TimelineEntry = React.memo(({ buildingId, issueId, actionId, action }) => {
  return (
    <div className="flex-col">

      <div className="flex-row">
        <ActorAvatar uid={action.actor} />

        <div className="body flex-1 flex-col">
          <Event originalTitle={action.originalTitle}
                 reopened={action.reopened}
                 assignee={action.assignee}
                 appointment={action.appointment}
                 fixed={action.fixed} />
          <div>
            {action.comment}
            <Attachments attachments={action.attachments} />
          </div>
        </div>
      </div>

      <div className="actor-name-and-timestamp flex-row">
        <ActorName uid={action.actor} />
        <div className="flex-1" />
        <Timestamp timestamp={action.taken} />
      </div>

    </div>
  );
});

const ActorAvatar = React.memo(({ uid }) => {
  const user = useDbUser(uid);

  return (
    <img className="profile-pic"
         width="40"
         height="40"
         alt={user?.name}
         src={user?.avatar}
         referrerPolicy="no-referrer" />
  );
});

const ActorName = React.memo(({ uid }) => {
  const nameOfActor = useDbUserName(uid);
  return <div className="actor-name">{nameOfActor}</div>;
});

const Event = React.memo(({ originalTitle, reopened, assignee, appointment, fixed }) => {
  const assigneeName = useDbUserName(assignee);

  if (originalTitle) {
    return <div className="event">Submitted "{originalTitle}"</div>;

  } else if (reopened) {
    return <div className="event">Reopened</div>;

  } else if (assignee && assignee !== 'unassigned') {
    return <div className="event">Assigned to {assigneeName}</div>;

  } else if (appointment === 'unscheduled') {
    return <div className="event">Appointment cancelled</div>;

  } else if (appointment) {
    return (
      <div className="event">
        Scheduled for {new Date(appointment).toLocaleDateString('en-US', { weekday: 'short',
                                                                           month  : 'short',
                                                                           day    : 'numeric',
                                                                           year   : 'numeric' })}
      </div>
    );

  } else if (fixed !== undefined) {
    return fixed ? <div className="event">Fixed and closed</div>
                 : <div className="event">Closed no fix</div>;
  }
});

const Attachments = React.memo(({ attachments }) => {
  return Boolean(attachments) && (
    <div className="attachments">
      {attachments && Object.entries(attachments).map(([index, attachment]) =>
        <a key={index}
           className="img-link"
           href={attachment.fullSize && attachment.fullSize.url}
           target="_blank"
           rel="noreferrer">

          <img src={(attachment.thumbnail && attachment.thumbnail.url) || `data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='68' height='68' viewBox='0 0 68 68'%3E%3Crect fill='rgba(0,0,0,.12)' width='68' height='68' /%3E%3C/svg%3E`}
               alt="Comment attachment"
               width="68"
               height="68" />
        </a>
      )}
    </div>
  );
});

const Timestamp = React.memo(({ timestamp }) => {
  return (
    <div className="timestamp">
      {new Date(timestamp).toLocaleDateString('en-US', { weekday: 'short',
                                                         month  : 'short',
                                                         day    : 'numeric',
                                                         year   : 'numeric',
                                                         hour   : 'numeric',
                                                         minute : 'numeric' })}
    </div>
  );
});
