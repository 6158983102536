import React from 'react';

import                       'widgets/forms/index.scss';
import                       'widgets/forms/user-profile/index.scss';
import                       'widgets/button/button-v2.scss';
import { LoginContext } from 'walls/login-wall/login-wall';
import { PgTextField }  from 'widgets/text-field/text-field';
import { dbUpdateUserName,
         useDbUser }    from 'backend-layer/db/db-users';

export function FormUserProfile({ onPhone, onExit }) {
  const login = React.useContext(LoginContext);
  const user = useDbUser(login.uid);

  const [legalFirstName, setLegalFirstName] = React.useState('');
  const [preferredName,  setPreferredName]  = React.useState('');
  const [lastName,       setLastName]       = React.useState('');

  const unpackData = React.useCallback(() => {
    if (user) {
      setLegalFirstName(user.legalFirstName || '');
      setPreferredName (user.preferredName  || '');
      setLastName      (user.lastName       || '');
    } else {
      setLegalFirstName('');
      setPreferredName ('');
      setLastName      ('');
    }
  }, [user]);

  React.useEffect(() => { unpackData(); }, [unpackData]);

  const submit = React.useCallback(e => {
    e.preventDefault();
    dbUpdateUserName(legalFirstName, preferredName, lastName);
    onExit();
  }, [legalFirstName, preferredName, lastName, onExit]);

  const reset = React.useCallback(() => {
    onExit();
  }, [onExit]);

  return (
    <form className="widget-form widget-form-user-profile" onSubmit={submit} onReset={reset}>
      <img className="profile-pic"
           width="100"
           height="100"
           alt="Your profile pic"
           referrerPolicy="no-referrer"
           src={user?.avatar || '/default-avatar.jpg'} />
      <p className="login-email">{login?.email}</p>

      <div className="flex-row">
        <PgTextField className="flex-1"
                     label="Legal First Name"
                     required
                     value={legalFirstName}
                     onChange={e => setLegalFirstName(e.target.value)} />
        <PgTextField className="flex-1 left-spacer-sm"
                     label="Preferred Name"
                     value={preferredName}
                     onChange={e => setPreferredName(e.target.value)} />
      </div>

      <PgTextField className="top-spacer-sm"
                   label="Last Name"
                   required
                   value={lastName}
                   onChange={e => setLastName(e.target.value)} />

      <div className="flex-row top-spacer-md">
        {onPhone && <button className="btn-alt" type="button" onClick={onPhone}>Phone</button>}
        <div className="flex-1" />
        <button className="btn-change"                 type="submit">Update</button>
        <button className="btn-primary left-spacer-xs" type="reset" >Cancel</button>
      </div>
    </form>
  );
}
