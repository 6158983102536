import React from 'react';

import                          'widgets/forms/index.scss';
import                          'widgets/forms/new-issue-management/index.scss';
import                          'widgets/button/button-v2.scss';
import { InfoBox }         from 'widgets/info-box/info-box';
import { Select }          from 'widgets/select/select';
import { PgTextField }     from 'widgets/text-field/text-field';
import { AptfyCheckbox }   from 'widgets/checkbox/checkbox';
import { Files }           from 'widgets/file-uploader/file-uploader';
import { LoginContext }    from 'walls/login-wall/login-wall';
import { AppContext }      from 'store/store';
import { geoSort }         from 'pages/file-room/file-room';
import { titleMaxChars,
         commentMaxChars } from 'widgets/issue/forms';
import { useDbPhone }      from 'backend-layer/db/db-users';
import { dbCreateIssue }   from 'backend-layer/db/db-issues';

export function FormNewIssue({ hidden, onNotifications, onExit }) {
  const login = React.useContext(LoginContext);
  const [state] = React.useContext(AppContext);
  const phoneNumber = useDbPhone(login.uid);

  const [buildingId,  setBuildingId]  = React.useState(null);
  const [unitId,      setUnitId]      = React.useState(null);
  const [title,       setTitle]       = React.useState('');
  const [description, setDescription] = React.useState('');
  const [pte,         setPte]         = React.useState(false);
  const [attachments, setAttachments] = React.useState();

  const isMultipleUnitsOrAutoselect = React.useMemo(() => {
    if (buildingId && state.buildings?.[buildingId]?.units) {
      const unitIds = Object.keys(state.buildings[buildingId].units);
      if (unitIds.length > 1) {
        setUnitId(null);
        return true;
      } else {
        setUnitId(unitIds.pop());
        return false;
      }
    } else {
      setUnitId(null);
      return false;
    }
  }, [buildingId, state.buildings]);

  const submit = React.useCallback(e => {
    e.preventDefault();
    const tenancyId = state.buildings?.[buildingId]?.units?.[unitId]?.tenancies
                    ? Object.keys(state.buildings[buildingId].units[unitId].tenancies).pop()
                    : null;
    dbCreateIssue(buildingId,
                  unitId,
                  tenancyId,
                  title,
                  description,
                  pte,
                  attachments);
    onExit();
  }, [
    state.buildings,
    buildingId,
    unitId,
    title,
    description,
    pte,
    attachments,
    onExit
  ]);

  const reset = React.useCallback(e => {
    e.preventDefault();
    onExit();
  }, [onExit]);

  return (
    <form className="widget-form widget-form-new-issue" hidden={hidden} onSubmit={submit} onReset={reset}>
      {(state.buildings === null &&
        <InfoBox className="bottom-spacer-md" title="Add a building" isWarning>
          You won't be able to submit an issue until you add a building, or
          are granted access to a building. Head back to the buildings page to
          add a building.
        </InfoBox>
      ) || (phoneNumber === null &&
        <InfoBox className="bottom-spacer-md" title="Enable text notifications" isWarning action={onNotifications} actionTitle="Add Phone">
          Don't miss important updates on your mainteance request such as
          scheduled appointments.
        </InfoBox>
      )}

      <Select className="building"
              label="Building"
              required
              onChange={e => setBuildingId(e.target.value)}>
        {state.buildings && Object.entries(state.buildings).sort(geoSort).map(([buildingId, building]) =>
          <option key={buildingId} value={buildingId}>{building.address}</option>
        )}
      </Select>

      {isMultipleUnitsOrAutoselect &&
        <Select className="select-unit top-spacer-sm"
                label="Unit"
                includeEmpty
                onChange={e => setUnitId(e.target.value)}>
          {state.buildings?.[buildingId]?.units && Object.entries(state.buildings[buildingId].units).map(([unitId, unit]) =>
             <option key={unitId} value={unitId}>{unit.designator}</option>
          )}
        </Select>
      }

      <PgTextField className="top-spacer-md"
                   label="Title"
                   placeholder="e.g. Bath sink clogged"
                   required
                   maxLength={titleMaxChars}
                   value={title}
                   onChange={e => setTitle(e.target.value)} />

      <PgTextField className="top-spacer-sm"
                   label="Description"
                   multiline
                   placeholder="Add further description if necessary. Must attach at least one image."
                   maxLength={commentMaxChars}
                   value={description}
                   onChange={e => setDescription(e.target.value)} />

      <Files className="top-spacer-sm" onFiles={files => setAttachments(files)} />

      {unitId &&
        <AptfyCheckbox className="top-spacer-md"
                       title="Permission to Enter"
                       isChecked={pte}
                       onChange={e => setPte(e.target.checked)}>
          Your tenant has explitity granted you permission to enter during normal
          buisness hours to perform repairs. For your protection it's best to have
          this permssion from your tenant in writting such as a text message or
          email.
        </AptfyCheckbox>
      }

      <div className="flex-row top-spacer-lg">
        <div className="flex-1" />
        <button className="btn-change"                 type="submit">Submit Issue</button>
        <button className="btn-primary left-spacer-sm" type="reset" >Cancel</button>
      </div>
    </form>
  );
}
