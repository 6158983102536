import React from 'react';

import { StatementItemDetails }              from 'widgets/statement/item-details';
import { StatementItemDetailsOnlinePayment } from 'widgets/statement/item-details-online-payment';
import { FormattedDate }                     from 'widgets/formatter/formatter';
import { dbNegTransactionTypes }             from 'backend-layer/db/db-transactions';

export function StatementItem({ buildingId, unitId, tenancyId, transaction }) {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <>
      <tr className="row-item"
          role="button"
          onClick={() => setIsExpanded(!isExpanded)}>
        <td>
          <span className="transaction-date">
            {(transaction.payMethod === 'Apartmentify' &&
              <FormattedDate date={transaction.created} />
            ) || (
              <FormattedDate date={transaction.date} />
            )}
          </span>
          <br />
          <span className="transaction-type">
            {(transaction.type === 'Rent' &&
              'Rent Charge'
            ) || (transaction.type === 'Payment' &&
              'Payment'
            ) || (transaction.type === 'Reimbursement' &&
              'Reimbursement'
            ) || (transaction.type === 'Credit' &&
              'Credit'
            ) || (transaction.type === 'Late Fee' &&
              'Late Fee'
            ) || (transaction.type === 'Surcharge' &&
              'Surcharge'
            ) || (transaction.type === 'Security Deposit' &&
              'Security Deposit'
            ) || (transaction.type === 'Security Deposit Return' &&
              'Deposit Return'
            )}
          </span>
        </td>
        <td className="text-align-right">
          {(transaction.type === 'Payment' &&
            <i className="payment-indicator material-icons">payments</i>
          ) || (transaction.type === 'Reimbursement' &&
            <i className="reimbursement-indicator material-icons">swap_horiz</i>
          )}
          {dbNegTransactionTypes.includes(transaction.type) &&
            '-'
          }
          {transaction.amount?.toLocaleString('en-US', { minimumFractionDigits: 2 })}
        </td>
        <td className="text-align-right">
          {transaction.runningBalance?.toLocaleString('en-US', { minimumFractionDigits: 2 })}
        </td>
      </tr>

      {isExpanded &&
        <tr>
          <td className="cell-item-details" colSpan="4">
            {transaction.payMethod === 'Apartmentify' ?
              <StatementItemDetailsOnlinePayment transaction={transaction} />
            :
              <StatementItemDetails buildingId={buildingId}
                                    unitId={unitId}
                                    tenancyId={tenancyId}
                                    transactionId={transaction.id}
                                    transaction={transaction} />
            }
          </td>
        </tr>
      }
    </>
  );
}
