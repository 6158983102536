import React from 'react';

import 'widgets/text-field/input-phone.scss';

export const InputPhone = React.memo(({ className,
                                        label,
                                        readOnly,
                                        autoFocus,
                                        required,
                                        phoneNumber,
                                        setPhoneNumber }) => {
  const inputRef = React.useRef();
  const [display, setDisplay] = React.useState(''); // Internal use only

  const parsePhoneNumber = React.useCallback(input => {
    const { display, phoneNumber, isBlank } = formatPhone(input);
    setDisplay(display);

    if (isBlank) {
      setPhoneNumber(null);
      inputRef.current.setCustomValidity('');
    } else if (phoneNumber) {
      setPhoneNumber(phoneNumber);
      inputRef.current.setCustomValidity('');
    } else {
      inputRef.current.setCustomValidity('Not a valid USA phone number.');
    }
  }, [inputRef, setPhoneNumber]);

  const handleOnChange = React.useCallback(e => {
    parsePhoneNumber(e.target.value);
  }, [parsePhoneNumber]);

  const digestValue = React.useCallback(() => {
    parsePhoneNumber(phoneNumber);
  }, [parsePhoneNumber, phoneNumber]);
  React.useEffect(digestValue, [digestValue]);

  return (
    <label className={`input-phone${className ? ` ${className}` : ''}`}>
      <span className="input-label">
        {label}
        {!readOnly &&             <span> (USA numbers only)</span>}
        {!readOnly && required && <span className="required-indicator" />}
      </span>
      <input ref={inputRef}
             type="tel"
             inputMode="numeric"
             readOnly={readOnly}
             autoFocus={autoFocus}
             required={required}
             value={display}
             onChange={handleOnChange} />
    </label>
  );
});

/* Takes any input and outputs an internationaly formated USA phone number if
 * one can be parsed from the input. Also, outputs a formatted version for
 * display regardless of whether or not a valid phone number can be parsed from
 * the input. */
export function formatPhone(input) {
  let display = '',
      phoneNumber = null,
      isBlank = true;

  if (typeof input === 'string') {
    const usaTenDigitPhoneNumber = input.replace(/^\+1/, '') // Removes +1
                                        .replace(/[^\d]/g, '') // Strips non-digits
                                        .slice(0, 10); // Truncates to length 10
    if (usaTenDigitPhoneNumber.length === 10) {
      phoneNumber = `+1${usaTenDigitPhoneNumber}`;
    }

    if (usaTenDigitPhoneNumber.length) {
      isBlank = false;

      if (usaTenDigitPhoneNumber.length < 4) {
        display = `(${usaTenDigitPhoneNumber}`;
      } else if (usaTenDigitPhoneNumber.length < 7) {
        display = `(${usaTenDigitPhoneNumber.slice(0, 3)}) ${usaTenDigitPhoneNumber.slice(3)}`;
      } else {
        display = `(${usaTenDigitPhoneNumber.slice(0, 3)}) ${usaTenDigitPhoneNumber.slice(3,6)}-${usaTenDigitPhoneNumber.slice(6)}`;
      }
    }
  }

  return { display, phoneNumber, isBlank };
}
