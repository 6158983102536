import React from 'react';

import 'widgets/button/button-v2.scss';

export function WidgetEditUnits({ addUnit, onExit }) {
  return (
    <div className="widget">
      <p className="top-spacer-none">
        Edit the units below and click save changes when you're done. You can
        change the unit designators, add more units, or remove units that don't have any
        tenancies.
      </p>
      <div className="flex-row top-spacer-md">
        <button className="btn-alt"
                type="button"
                onClick={addUnit}>
          Add Unit
        </button>
        <div className="flex-1" />
        <button className="btn-remove"
                form="building-page_form-units"
                type="submit">
          Save
        </button>
        <button className="btn-primary left-spacer-xs"
                form="building-page_form-units"
                type="reset">
          Cancel
        </button>
      </div>
    </div>
  );
}
