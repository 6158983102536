import { initializeApp }       from 'firebase/app';
import { initializeAppCheck,
         ReCaptchaV3Provider } from 'firebase/app-check';

const RECAPTCHA_SITE_KEY_DEV  = '6LdqCiMhAAAAAFAbBNZYpR9cOYnRyoUeJuSVa_x7';
const RECAPTCHA_SITE_KEY_PROD = '6LfNnZsgAAAAAPOZcnQQ2aSuO-JZ7nUqABIaMjn2';

export async function initFirebase() {
  try {
    const config = await (await fetch('/__/firebase/init.json')).json();
    let recaptchaSiteKey;

    if (config.projectId === 'apartmentify') { // production
      config.authDomain = 'www.apartmentify.com';
      recaptchaSiteKey = RECAPTCHA_SITE_KEY_PROD;
    } else {
      recaptchaSiteKey = RECAPTCHA_SITE_KEY_DEV;
    }

    initializeAppCheck(initializeApp(config), {
      provider: new ReCaptchaV3Provider(recaptchaSiteKey),
      isTokenAutoRefreshEnabled: true
    });

  } catch (error) {
    console.log('Failed to initiate Firebase reload page', error);
  }
}
