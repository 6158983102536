import React from 'react';

import 'widgets/file-uploader/file-uploader.scss';

export const Files = React.memo(({ className, required, onFiles }) => {
  const [files,  setFiles]  = React.useState();

  const addFiles = React.useCallback(newFiles => {
    if (files) {
      setFiles(files.concat(Array.from(newFiles)));
    } else {
      setFiles(Array.from(newFiles));
    }
  }, [files]);

  React.useEffect(() => {
    onFiles(files);
  }, [onFiles, files]);

  return (
    <div className={`files${className ? ` ${className}` : ''}`}>
      {files && files.map(file =>
        <img key={file.name}
             src={URL.createObjectURL(file)}
             alt={file.name} />
      )}
      <UploadBtn required={required} onFiles={addFiles} />
    </div>
  );
});

export const UploadBtn = React.memo(({ className, required, onFiles }) => {
  return (
    <label className={`btn btn-flat upload-btn${required ? ' required' : ''}${className ? ` ${className}` : ''}`}>
      <input type="file"
             aria-label="Upload Files"
             required={required}
             multiple
             accept="image/jpeg, image/png, image/tiff, image/webp, image/gif"
             onChange={e => onFiles(e.target.files)} />
      <i className="material-icons">photo_camera</i>
    </label>
  );
});
