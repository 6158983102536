import   React      from 'react';
import { getDatabase,
         ref,
         push,
         set,
         update,
         remove,
         onValue }  from 'firebase/database';
import { getAnalytics,
         logEvent } from 'firebase/analytics';

/******************************************************************************
 * Create, read once, update & delete
 ******************************************************************************/

export function dbCreateTenancy(buildingId, unitId, start, rent, rentMemo) {
  if (!buildingId)                                  { throw new Error('Tenancy creation failed: missing building ID!');                                     }
  if (!unitId)                                      { throw new Error('Tenancy creation failed: missing unit ID!');                                         }
  if (isNaN(rent))                                  { throw new Error(`Tenancy creation failed: expected rent as number, received ${rent}!`);               }
  if (start && !start.match(/^\d{4}-\d{2}-\d{2}$/)) { throw new Error(`Tenancy creation failed: expected start as yyyy-mm-dd or falsy, received ${start}!`);}

  logEvent(getAnalytics(), 'tenancy_creation', { buildingId, unitId });

  return push(ref(getDatabase(), `buildings/${buildingId}/units/${unitId}/tenancies`), {
    commencementDate: start || null,
    rent: Number(rent),
    moveOutDate: '9999-01-01'
  }).key;
}

export function dbAdjustRent(buildingId, unitId, tenancyId, rent, rentMemo, start) {
  if (!buildingId)                            { throw new Error('Rent adjustment failed: missing building ID!');                                   }
  if (!unitId)                                { throw new Error('Rent adjustment failed: missing unit ID!');                                       }
  if (!tenancyId)                             { throw new Error('Rent adjustment failed: missing tenancy ID!');                                    }
  if (isNaN(rent))                            { throw new Error(`Rent adjustment failed: expected rent as number, received ${rent}!`);             }
  if (start && !start.match(/^\d{4}-\d{2}$/)) { throw new Error(`Rent adjustment failed: expected start as yyyy-mm or falsy, received ${start}!`); }

  /* Backend fuction will apply new rent on start month. */
  if (start && start > currentMonth()) {
    return set(ref(getDatabase(), `buildings/${buildingId}/units/${unitId}/tenancies/${tenancyId}/rentAdjustment`), {
      rent: Number(rent),
      rentMemo: rentMemo || null,
      start
    });

  /* New rent applied immediately. */
  } else {
    return update(ref(getDatabase(), `buildings/${buildingId}/units/${unitId}/tenancies/${tenancyId}`), {
      rent: Number(rent),
      rentMemo: rentMemo || null,
      rentAdjustment: null
    });
  }
}

export function dbSetRentMemo(buildingId, unitId, tenancyId, rentMemo) {
  if (!buildingId) { throw new Error('Rent memo failed: missing building ID!'); }
  if (!unitId)     { throw new Error('Rent memo failed: missing unit ID!');     }
  if (!tenancyId)  { throw new Error('Rent memo failed: missing tenancy ID!');  }

  return set(ref(getDatabase(), `buildings/${buildingId}/units/${unitId}/tenancies/${tenancyId}/rentMemo`), rentMemo || null);
}

export function dbRevertRent(buildingId, unitId, tenancyId) {
  if (!buildingId) { throw new Error('Revert rent failed: missing building ID!'); }
  if (!unitId)     { throw new Error('Revert rent failed: missing unit ID!');     }
  if (!tenancyId)  { throw new Error('Revert rent failed: missing tenancy ID!');  }

  return remove(ref(getDatabase(), `buildings/${buildingId}/units/${unitId}/tenancies/${tenancyId}/rentAdjustment`));
}

export function dbSetTenancyStart(buildingId, unitId, tenancyId, start) {
  if (!buildingId)                                  { throw new Error('Update tenancy start failed: missing building ID!');                                      }
  if (!unitId)                                      { throw new Error('Update tenancy start failed: missing unit ID!');                                          }
  if (!tenancyId)                                   { throw new Error('Update tenancy start failed: missing tenancy ID!');                                       }
  if (start && !start.match(/^\d{4}-\d{2}-\d{2}$/)) { throw new Error(`Update tenancy start failed: expected start as yyyy-mm-dd or falsy, received ${start}!`); }

  return set(ref(getDatabase(), `buildings/${buildingId}/units/${unitId}/tenancies/${tenancyId}/commencementDate`), start || null);
}

export function dbSetTenancyEnd(buildingId, unitId, tenancyId, end) {
  if (!buildingId)                              { throw new Error('Update tenancy end failed: missing building ID!');                                  }
  if (!unitId)                                  { throw new Error('Update tenancy end failed: missing unit ID!');                                      }
  if (!tenancyId)                               { throw new Error('Update tenancy end failed: missing tenancy ID!');                                   }
  if (end && !end.match(/^\d{4}-\d{2}-\d{2}$/)) { throw new Error(`Update tenancy end failed: expected end as yyyy-mm-dd or falsy, received ${end}!`); }

  return set(ref(getDatabase(), `buildings/${buildingId}/units/${unitId}/tenancies/${tenancyId}/moveOutDate`), end || null);
}

export function dbDeleteTenancy(buildingId, unitId, tenancyId) {
  if (!buildingId) { throw new Error('Tenancy deletion failed: missing building ID!'); }
  if (!unitId)     { throw new Error('Tenancy deletion failed: missing unit ID!');     }
  if (!tenancyId)  { throw new Error('Tenancy deletion failed: missing tenancy ID!');  }

  logEvent(getAnalytics(), 'tenancy_deletion', { buildingId, unitId, tenancyId });

  return update(ref(getDatabase()), {
    [`buildings/${buildingId}/units/${unitId}/tenancies/${tenancyId}`]: null,
    [`transactions/${buildingId}/${unitId}/${tenancyId}`]: null,
  });
}

/******************************************************************************
 * React hooks
 ******************************************************************************/

 export function useDbTenancy(buildingId, unitId, tenancyId) {
   const [value, setValue] = React.useState();

   React.useEffect(() => {
     if (buildingId && unitId && tenancyId) {
       return onValue(ref(getDatabase(), `buildings/${buildingId}/units/${unitId}/tenancies/${tenancyId}`), snapshot => {
         if (snapshot.exists()) {
           setValue({
             ...snapshot.val(),
             moveOutDate: snapshot.val().moveOutDate === '9999-01-01' ? null
                                                                      : snapshot.val().moveOutDate
           });
         } else {
           setValue(null);
         }
       });
     } else {
       setValue();
     }
   }, [buildingId, unitId, tenancyId]);

   return value;
 }

 /******************************************************************************
  * Helper functions
  ******************************************************************************/

 export function currentMonth() {
   return new Date().toLocaleDateString(
     'sv-SE', { year: 'numeric', month: 'numeric', timeZone: 'UTC'} // yyyy-mm e.g. 2022-04
   );
 }
