import   React  from 'react';
import { Link } from 'react-router-dom';
import   dayjs  from 'dayjs';

import                          'widgets/button/button-v2.scss';
import { FileRoomTenancy } from 'pages/file-room/file-room-tenancy';

export function FileRoomUnit({ role, buildingId, unitId, unit }) {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <div className={`unit${isExpanded ? ' expanded' : ''}`}>
      <button className="btn-unit-designator btn-unstyled" onClick={e => setIsExpanded(!isExpanded)}>
        {unit.designator || <i className="material-icons">{isExpanded ? "expand_less" : "expand_more"}</i>}
      </button>

      <div className="tenancies">
        {unit.tenancies && Object.entries(unit.tenancies).reverse().filter(([tenacyId, tenancy]) => {
          return isExpanded || tenancy.moveOutDate === undefined
                            || tenancy.moveOutDate === null
                            || !dayjs().isAfter(tenancy.moveOutDate, 'day'); // Passing granularity of day, since we would consider a tenancy not moved out if the move out date were today.
        }).map(([tenancyId, tenancy]) =>
          <FileRoomTenancy key={tenancyId}
                           role={role}
                           buildingId={buildingId}
                           unitId={unitId}
                           tenancyId={tenancyId}
                           tenancy={tenancy} />
        )}
        {(isExpanded || !unit.tenancies) && role === 'manager' &&
          <Link className="btn-add-tenancy btn-primary"
                to={`/management/tenancy?buildingId=${buildingId}&unitId=${unitId}`}>
            Add Tenancy
          </Link>
        }
      </div>
    </div>
  );
}
