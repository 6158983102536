import                        'material-icons/iconfont/material-icons.css';
import { StrictMode }    from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot }    from 'react-dom/client';

import                         'index.scss';
import { LoginWall }      from 'walls/login-wall/login-wall';
import { UrlParsingWall } from 'walls/url-parsing-wall/url-parsing-wall';
import { Store }          from 'store/store';
import { App }            from 'app/app';
import { initFirebase }   from 'backend-layer/firebase-init';

initFirebase().then(() => {
  createRoot(document.getElementById('root')).render(
    <StrictMode>
      <BrowserRouter>
        <LoginWall>
          <UrlParsingWall>
            <Store>
              <App />
            </Store>
          </UrlParsingWall>
        </LoginWall>
      </BrowserRouter>
    </StrictMode>
  );
});
