import React from 'react';

import { PgTextField }         from 'widgets/text-field/text-field';
import { LoginContext }        from 'walls/login-wall/login-wall';
import { dbAddBuildingMember } from 'backend-layer/db/db-memberships';
import { dbGetUserByEmail }     from 'backend-layer/db/db-users';

const fallbackProfilePic = `data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='128' height='128' viewBox='0 0 128 128'%3E%3Crect fill='rgb(224,224,224)' width='128' height='128' /%3E%3C/svg%3E`;

export function WidgetMembershipAdd({ buildingId, onExit }) {
  const login = React.useContext(LoginContext);

  const [email,        setEmail]        = React.useState('');
  const [searchResult, setSearchResult] = React.useState();

  const submit = React.useCallback(e => {
    e.preventDefault();
    dbGetUserByEmail(email).then(resp => {
      setSearchResult(resp);
    });
  }, [email]);

  const reset = React.useCallback(e => {
    e.preventDefault();
    onExit();
  }, [onExit]);

  const addManager = React.useCallback(e => {
    dbAddBuildingMember(buildingId, searchResult.uid, 'manager');
    onExit();
  }, [buildingId, searchResult, onExit]);

  const addTechnician = React.useCallback(e => {
    dbAddBuildingMember(buildingId, searchResult.uid, 'technician');
    onExit();
  }, [buildingId, searchResult, onExit]);

  const searchAgain = React.useCallback(e => {
    setSearchResult();
  }, []);

  return (
    <div className="widget">
      {!searchResult &&
        <p className="top-spacer-none">
          Ask your staff member to create an account
          at <strong>www.apartmentify.com</strong> if they don't already have
          one. Once they've created an account, you can search for them by the
          email they registered with.
        </p>
      }

      {(searchResult === undefined &&
        <form onSubmit={submit} onReset={reset}>
          <PgTextField type="email"
                       label="Search by email"
                       required
                       validateSilently
                       value={email}
                       onChange={e => setEmail(e.target.value)} />
          <div className="flex-row top-spacer-md">
            <div className="flex-1" />
            <button className="btn-primary" type="submit">Search User</button>
            <button className="btn-primary left-spacer-xs" type="reset" >Cancel</button>
          </div>
        </form>
      ) || (searchResult &&
        <>
          <img src={searchResult.avatar || fallbackProfilePic}
               alt={searchResult.name}
               width="128"
               height="128"
               style={{ borderRadius: '50%', display: 'block', margin: 'auto' }}/>
          <div className="top-spacer-sm"  style={{ fontWeight: 'bold', textAlign: 'center' }}>{searchResult.name}</div>
          <div className="text-secondary" style={{                     textAlign: 'center' }}>{searchResult.email}</div>

          <div className="flex-row top-spacer-md">
            <div className="flex-1" />
            {searchResult.uid !== login.uid &&
              <button className="btn-change left-spacer-xs" onClick={addTechnician}>Technician</button>
            }
            <button className="btn-change"                  onClick={addManager}   >Manager</button>
            <button className="btn-primary left-spacer-xs"  onClick={onExit}       >Cancel</button>
          </div>
        </>
      ) || (
        <>
          <p className="top-spacer-md" style={{
            alignItems: 'center',
            backgroundColor: '#ffe0b2',
            borderRadius: 'var(--border-radius)',
            display: 'flex',
            padding: '12px',
            marginBottom: '0px'
          }}>
             <i className="material-icons" style={{ marginRight: '12px' }}>announcement</i>
             <span className="text-overline">No account found</span>
          </p>
          <div className="flex-row top-spacer-md">
            <div className="flex-1" />
            <button className="btn-primary"                type="button" onClick={searchAgain}>Search Again</button>
            <button className="btn-primary left-spacer-xs" type="button" onClick={onExit}     >Cancel</button>
          </div>
        </>
      )}
    </div>
  );
}
