import React from 'react';

const apiKey = 'AIzaSyDp1bLCIUpqu1K3JBojQ1omXqqX6JfGa8M';
const origin = 'https://maps.googleapis.com/maps/api/streetview';
const horizontalResolution = 640;
const verticalResolution = 320;
const placeHolder = `data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='${horizontalResolution}' height='${verticalResolution}' viewBox='0 0 ${horizontalResolution} ${verticalResolution}'%3E%3Crect fill='%23f1f1f1' width='${horizontalResolution}' height='${verticalResolution}' /%3E%3C/svg%3E`;

export function StreetView(props) {
  const [uri, setUri] = React.useState(placeHolder);

  React.useEffect( () => {
    if (props.lat && props.lng) {
      setUri(`${origin}?size=${horizontalResolution}x${verticalResolution}&location=${props.lat},${props.lng}&source=outdoor&key=${apiKey}`);
    } else {
      setUri(placeHolder);
    }
  }, [
    props.lat,
    props.lng
  ]);

  return (
    <img className="street-view" src={uri} alt="street view" />
  );
}
