import React from 'react';

import                       'widgets/button/button-v2.scss';
import { PgTextField }  from 'widgets/text-field/text-field';
import { dbAdjustRent,
         dbRevertRent,
         currentMonth } from 'backend-layer/db/db-tenancies';

export function WidgetAdjustRent({ buildingId,
                                   unitId,
                                   tenancyId,
                                   currentRentAdjustment,
                                   onExit,
                                   onEditMemo }) {
  const [rent,     setRent]     = React.useState(currentRentAdjustment?.rent     || '');
  const [rentMemo, setRentMemo] = React.useState(currentRentAdjustment?.rentMemo || '');
  const [start,    setStart]    = React.useState(currentRentAdjustment?.start    || '');

  const submit = React.useCallback(e => {
    e.preventDefault();
    dbAdjustRent(buildingId, unitId, tenancyId, rent, rentMemo, start);
    onExit();
  }, [buildingId, unitId, tenancyId, rent, rentMemo, start, onExit]);

  const reset = React.useCallback(e => {
    e.preventDefault();
    onExit();
  }, [onExit]);

  const revertRent = React.useCallback(() => {
    dbRevertRent(buildingId, unitId, tenancyId);
    onExit();
  }, [buildingId, unitId, tenancyId, onExit]);

  return (
    <form className="widget flex-col" onSubmit={submit} onReset={reset}>
      <div className="flex-row">
        <PgTextField className="flex-2"
                     label="New Rent"
                     type="number"
                     inputMode="decimal"
                     required
                     step="0.01"
                     min="0"
                     value={rent}
                     onChange={e => setRent(e.target.value)} />
        <PgTextField className="flex-3 left-spacer-sm"
                     label="Effective on (immediate if blank)"
                     type="month"
                     min={currentMonth()}
                     value={start}
                     onChange={e => setStart(e.target.value)} />
      </div>

      <PgTextField className="top-spacer-sm"
                   label="New Rent Memo"
                   multiline
                   value={rentMemo}
                   onChange={e => setRentMemo(e.target.value)} />

      <div className="flex-row top-spacer-md">
        {currentRentAdjustment ?
          <button className="btn-remove" type="button" onClick={revertRent}>Revert Rent</button>
        :
          <button className="btn-alt" type="button" onClick={onEditMemo}>Current Memo</button>
        }
        <div className="flex-1" />
        <button className="btn-change"                 type="submit">Set</button>
        <button className="btn-primary left-spacer-xs" type="reset" >Cancel</button>
      </div>
    </form>
  );
}
