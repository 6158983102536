import React from 'react';

import                              'widgets/issue/index.scss';
import { IssueTimeline }       from 'widgets/issue/timeline';
import { IssueToolbar }        from 'widgets/issue/toolbar';
import { useAddressParts }     from 'widgets/address/address';
import { useDbUser }           from 'backend-layer/db/db-users';
import { useDbBuildingAddress,
         useDbUnitDesignator } from 'backend-layer/db/db-buildings';

const issueCoverPhotoPlaceholder = `data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='320' height='180' viewBox='0 0 320 180'%3E%3Crect fill='rgba(0,0,0,.12)' width='320' height='180' /%3E%3C/svg%3E`;

export function Issue({ issueId, issue, isManagement }) {
  const assignee = useDbUser(issue.assignee);
  const [isExpanded, setExpanded] = React.useState(false);

  const toggleExpanded = React.useCallback(e => {
    if (!e.target.matches('button, button *, a, a *, form, form *, .body, .body *')) {
      setExpanded(!isExpanded);
    }
  }, [isExpanded]);

  return (
    <div className={`widget-issue ${isExpanded ? 'expanded' : 'collapsed'}`}
         onClick={toggleExpanded}>

      <img className="cover-photo"
           src={issue.image?.cover?.url || issueCoverPhotoPlaceholder}
           alt={issue.title}
           width="320"
           height="180" />

      {assignee &&
        <img className="assignee-pic"
             src={assignee.avatar}
             alt={`Assigned to ${assignee.name}`}
             width="52"
             height="52"
             referrerPolicy="no-referrer" />
      }

      <div className="heading" style={{ backgroundColor: issue.closed ? 'rgba(157, 92, 68, 0.82)' : 'rgb(82, 120, 83, 0.82)' }}>
        <div className="title">{issue.title}</div>
        <IssueCardTags pte={issue.pte} appointment={issue.appointment} />
      </div>

      {isManagement && <IssueCardLocation buildingId={issue.buildingId} unitId={issue.unitId} />}


      <IssueTimeline buildingId={issue.buildingId}
                     issueId={issueId}
                     isExpanded={isExpanded} />

      <IssueToolbar buildingId={issue.buildingId}
                    issueId={issueId}
                    issue={issue}
                    isManagement={isManagement}
                    isExpanded={isExpanded} />
    </div>
  );
}

const IssueCardLocation = React.memo(({ buildingId, unitId }) => {
  const address = useDbBuildingAddress(buildingId);
  const unitDesignator = useDbUnitDesignator(buildingId, unitId);
  const [streetAddress] = useAddressParts(address);

  return (
    <address className="location">
      {streetAddress}
      {unitDesignator && <span className="unit-designator">{unitDesignator}</span>}
    </address>
  );
});

const IssueCardTags = React.memo(({ pte, appointment }) => {
  const formattedAppointment = React.useMemo(() => (
    appointment && new Date(appointment).toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric'
    })
  ), [appointment]);

  return (
    <div className="tags">
      {pte ? <>permission to enter</> : <>by appointment only</>}
      {formattedAppointment && <> • scheduled {formattedAppointment}</> }
    </div>
  );
});
