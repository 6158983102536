import React from 'react';

import                           'widgets/button/button-v2.scss';
import { StreetView }       from 'widgets/street-view/street-view';
import { AddressField }     from 'widgets/text-field/address-field';
import { PgTextField }      from 'widgets/text-field/text-field';
import { dbCreateBuilding } from 'backend-layer/db/db-buildings';

const maxNumOfUnits = 100;

export function FileRoomBuildingCreator({ onAddBuilding, onCancel }) {
  const [numOfUnits, setNumOfUnits] = React.useState('');

  const [address,         setAddress]         = React.useState('');
  const [lat,             setLat]             = React.useState();
  const [lng,             setLng]             = React.useState();
  const [googlePlaceId,   setGooglePlaceId]   = React.useState();
  const [unitDesignators, setUnitDesignators] = React.useState(Array(maxNumOfUnits).fill().map((_, index) => ''));

  const submit= React.useCallback(e => {
    e.preventDefault();
    dbCreateBuilding(address, lat, lng, googlePlaceId, unitDesignators.slice(0, numOfUnits));
    onAddBuilding();
  }, [address, lat, lng, googlePlaceId, unitDesignators, numOfUnits, onAddBuilding]);

  const reset = React.useCallback(e => {
    e.preventDefault();
    onCancel();
  }, [onCancel]);

  const onChangeNumOfUnits = React.useCallback(e => {
    if (e.target.validity.valid) {
      setNumOfUnits(e.target.value);
    } else if (!e.target.value) {
      setNumOfUnits('');
    }
  }, []);

  const setUnitDesignator = React.useCallback((index, designator) => {
    setUnitDesignators([
      ...unitDesignators.slice(0, index),
      designator,
      ...unitDesignators.slice(index + 1)
    ]);
  }, [unitDesignators]);

  /* Focus on input numOfUnits upon selecting an address from Google address
   * autocomplete. */
  React.useEffect(() => {
    if (googlePlaceId) {
      document.querySelector('input[name="numOfUnits"]')?.focus();
    }
  }, [googlePlaceId]);

  return (
    <div className="building-creator">
      <StreetView lat={lat} lng={lng} />
      <form onSubmit={submit} onReset={reset}>
        <AddressField label="Address"
                      autoFocus
                      address={address}
                      lat={lat}
                      lng={lng}
                      onChangeAddress={setAddress}
                      onChangeLat={setLat}
                      onChangeLng={setLng}
                      onChangeGooglePlaceId={setGooglePlaceId} />

        <PgTextField className="input-num-of-units top-spacer-sm"
                     label="# of units"
                     name="numOfUnits"
                     type="number"
                     inputMode="numeric"
                     showControls
                     step="1"
                     min="1"
                     max={maxNumOfUnits}
                     required
                     value={numOfUnits}
                     onChange={onChangeNumOfUnits} />

        <div className="actions flex-row">
          <div className="flex-1" />
          <button className="btn-filled-change"                 type="submit">Add Building</button>
          <button className="btn-filled-primary left-spacer-sm" type="reset" >Cancel</button>
        </div>

        <div className="units">
          {unitDesignators.slice(0, numOfUnits).map((designator, index) =>
            <div key={index}>
              <div>
                <PgTextField label="Unit #"
                             name="designator"
                             required={numOfUnits > 1}
                             value={designator}
                             onChange={e => setUnitDesignator(index, e.target.value)} />
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
