import   React      from 'react';
import { toast }    from 'react-toastify';
import { getAuth }  from 'firebase/auth';
import { getDatabase,
         ref,
         push,
         update,
         onValue }  from 'firebase/database';
import { getAnalytics,
         logEvent } from 'firebase/analytics';

/******************************************************************************
 * Create, read once, update & delete
 ******************************************************************************/

 export function makeUnitId(buildingId) {
   if (!buildingId) { throw new Error('Unit ID generation failed: missing building ID!'); }

   return push(ref(getDatabase(), `buildings/${buildingId}/units`)).key;
 }

export function dbCreateBuilding(address, lat, lng, googlePlaceId, unitDesignators) {
  if (!address)         { throw new Error('Building creation failed: missing address!');          }
  if (!unitDesignators) { throw new Error('Building creation failed: missing unit designators!'); }

  const buildingId = push(ref(getDatabase(), 'buildings')).key;

  logEvent(getAnalytics(), 'building_creation', { buildingId });

  return toast.promise(() => update(ref(getDatabase()), {
    [`buildings/${buildingId}`]: {
      address,
      lat: lat                     || null,
      lng: lng                     || null,
      googlePlaceId: googlePlaceId || null,
      units: Object.fromEntries(unitDesignators.map(designator => [makeUnitId(buildingId), { designator }])),
      members: {
        [getAuth().currentUser.uid]: {
          role: 'manager'
        }
      }
    },
    [`memberships/${getAuth().currentUser.uid}/buildings/${buildingId}`]: true
  }), { success: `Building added and sorted` });
}

export function dbUpdateBuildingAddress(buildingId, address, lat, lng, googlePlaceId) {
  if (!buildingId) { throw new Error('Building address update failed: missing building ID!'); }
  if (!address)    { throw new Error('Building address update failed: missing address!');     }

  return update(ref(getDatabase(), `buildings/${buildingId}`), {
    address,
    lat: lat                     || null,
    lng: lng                     || null,
    googlePlaceId: googlePlaceId || null
  });
}

export function dbUpdateUnits(buildingId, unitDesignators) {
  return update(ref(getDatabase(), `buildings/${buildingId}/units`), Object.entries(unitDesignators).reduce((accumulator, [unitId, designator]) => ({
    ...accumulator,
    [`${unitId || makeUnitId(buildingId)}/designator`]: designator
  }), {}));
}

export function dbDeleteBuilding(buildingId) {
  if (!buildingId) { throw new Error('Building deletion failed: missing building ID!'); }

  logEvent(getAnalytics(), 'building_deletion', { buildingId });

  return update(ref(getDatabase()), {
    [`buildings/${buildingId}`]   : null,
    [`issues/${buildingId}`]      : null,
    [`transactions/${buildingId}`]: null
  });
}

/******************************************************************************
 * React hooks
 ******************************************************************************/

export function useDbBuilding(buildingId) {
  const [value, setValue] = React.useState();

  React.useEffect(() => {
    if (buildingId) {
      return onValue(ref(getDatabase(), `buildings/${buildingId}`), snapshot => setValue(snapshot.val()));
    } else {
      setValue();
    }
  }, [buildingId]);

  return value;
}

export function useDbBuildingAddress(buildingId) {
  const [value, setValue] = React.useState();

  React.useEffect(() => {
    if (buildingId) {
      return onValue(ref(getDatabase(), `buildings/${buildingId}/address`), snapshot => setValue(snapshot.val()));
    } else {
      setValue();
    }
  }, [buildingId]);

  return value;
}

export function useDbBuildingLocation(buildingId) {
  const [address, setAddress] = React.useState();
  const [lat,     setLat]     = React.useState();
  const [lng,     setLng]     = React.useState();

  React.useEffect(() => {
    if (buildingId) {
      return onValue(ref(getDatabase(), `buildings/${buildingId}/address`), snapshot => setAddress(snapshot.val()));
    } else {
      setAddress();
    }
  }, [buildingId]);

  React.useEffect(() => {
    if (buildingId) {
      return onValue(ref(getDatabase(), `buildings/${buildingId}/lat`), snapshot => setLat(snapshot.val()));
    } else {
      setLat();
    }
  }, [buildingId]);

  React.useEffect(() => {
    if (buildingId) {
      return onValue(ref(getDatabase(), `buildings/${buildingId}/lng`), snapshot => setLng(snapshot.val()));
    } else {
      setLng();
    }
  }, [buildingId]);

  return { address, lat, lng };
}



export function useDbUnitDesignator(buildingId, unitId) {
  const [value, setValue] = React.useState();

  React.useEffect(() => {
    if (buildingId && unitId) {
      return onValue(ref(getDatabase(), `buildings/${buildingId}/units/${unitId}/designator`), snapshot => setValue(snapshot.val()));
    } else {
      setValue();
    }
  }, [buildingId, unitId]);

  return value;
}
