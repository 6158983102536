import React from 'react';

export function useStreetAddress(address) {
  return address && address.slice(0, address.indexOf(','));
}

export function useLocality(address) {
  return address && address.slice(address.indexOf(',') + 1);
}

export function useAddressParts(address) {
  const [streetAddress, locality] = React.useMemo(() => {
    if (address) {
      const firstComma = address.indexOf(',');
      return [address.slice(0, firstComma),
              address.slice(firstComma + 1)];
    } else {
      return [undefined, undefined];
    }
  }, [address]);

  return [streetAddress, locality];
}
