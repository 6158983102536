import React from 'react';

import                                  'widgets/button/button-v2.scss';
import { StreetView }              from 'widgets/street-view/street-view';
import { AddressField }            from 'widgets/text-field/address-field';
import { dbUpdateBuildingAddress } from 'backend-layer/db/db-buildings';

export function WidgetEditAddress({ buildingId, building, onExit }) {
  const [address,       setAddress]       = React.useState('');
  const [lat,           setLat]           = React.useState();
  const [lng,           setLng]           = React.useState();
  const [googlePlaceId, setGooglePlaceId] = React.useState();

  const unpackData = React.useCallback(() => {
    setAddress      (building?.address || '');
    setLat          (building?.lat          );
    setLng          (building?.lng          );
    setGooglePlaceId(building?.googlePlaceId);
  }, [building]);

  React.useEffect(() => { unpackData(); }, [unpackData]);

  const submit= React.useCallback(e => {
    e.preventDefault();
    dbUpdateBuildingAddress(buildingId, address, lat, lng, googlePlaceId);
    onExit();
  }, [buildingId, address, lat, lng, googlePlaceId, onExit]);

  const reset= React.useCallback(e => {
    e.preventDefault();
    onExit();
  }, [onExit]);

  return (
    <form className="widget" onSubmit={submit} onReset={reset}>
      <StreetView lat={lat} lng={lng} />

      <AddressField className="top-spacer-sm"
                    label="Address"
                    address={address}
                    lat={lat}
                    lng={lng}
                    onChangeAddress={setAddress}
                    onChangeLat={setLat}
                    onChangeLng={setLng}
                    onChangeGooglePlaceId={setGooglePlaceId} />

      <div className="flex-row top-spacer-md">
        <div className="flex-1" />
        <button className="btn-remove"                 type="submit">Save</button>
        <button className="btn-primary left-spacer-xs" type="reset" >Cancel</button>
      </div>
    </form>
  );
}
