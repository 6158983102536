import                         'react-toastify/dist/ReactToastify.css';
import   React            from 'react';
import { ToastContainer } from 'react-toastify';
import { useHistory }     from 'react-router-dom';

import { FileRoom }     from 'pages/file-room/file-room';
import { BuildingPage } from 'pages/building/building';
import { TenancyPage }  from 'pages/tenancy/tenancy';
import { IssuesPage }   from 'pages/issues';
import { UrlContext }   from 'walls/url-parsing-wall/url-parsing-wall';
import { AppContext }   from 'store/store';

export function AppManagement() {
  const url = React.useContext(UrlContext);
  const [state] = React.useContext(AppContext);

  const [isBuildingsPageVisitied, setIsBuildingsPageVisited] = React.useState(false);
  const [isIssuesPageVisited,     setIsIssuesPageVisited]    = React.useState(false);

  React.useEffect(() => {
    switch (url.page) {
      case 'file-room':   setIsBuildingsPageVisited(true); break;
      case 'maintenance': setIsIssuesPageVisited(true);    break;
      default:
    }
  }, [url.page]);

  return state.buildings !== undefined && (
    <>
      <ToastContainer position="top-center" hideProgressBar />

      {isBuildingsPageVisitied && <FileRoom   hidden={url.page !== 'file-room'}   />}
      {isIssuesPageVisited     && <IssuesPage hidden={url.page !== 'maintenance'} />}

      {
        (url.page === 'building'    && <BuildingPage buildingId={url.buildingId}                                               />) ||
        (url.page === 'tenancy'     && <TenancyPage  buildingId={url.buildingId} unitId={url.unitId} tenancyId={url.tenancyId} />) ||
        (url.page === 'file-room'   && true                                                                                      ) ||
        (url.page === 'maintenance' && true                                                                                      ) ||

        <LandingPage buildings={state.buildings} />
      }
    </>
  );
}

function LandingPage({ buildings }) {
  const history = useHistory();

  React.useEffect(() => {
    history.replace(buildings ? '/management/maintenance' : '/management/file-room');
  }, [buildings, history]);

  return null;
}
