import React from 'react';

import 'widgets/checkbox/checkbox.css';

export function AptfyCheckbox({ className,
                                style,
                                children,
                                title,
                                disabled,
                                required,
                                isChecked,
                                onChange }) {
  return (
    <div className={`aptfy-checkbox ${className}`} style={style}>
      <input className="aptfy-checkbox_input"
             type="checkbox"
             aria-label={title}
             disabled={disabled}
             required={required}
             checked={isChecked}
             onChange={onChange} />
      <div className="aptfy-checkbox_body">
        {title && <div className="aptfy-checkbox_title">{title}</div>}
        <div className="aptfy-checkbox_description">
          {children}
        </div>
      </div>
    </div>
  );
}
