import React from 'react';

import 'widgets/select/select.scss';

export function Select({ children,
                         className,
                         autoFocus,
                         label,
                         name,
                         required,
                         disabled,
                         includeEmpty,
                         selections,
                         formatter,
                         value,
                         onChange }) {

  const selectRef = React.createRef();

  /* Sets intial value */
  React.useEffect(() => {
    if (onChange) {
      onChange({ target: selectRef.current });
    }
 }, [selectRef, onChange]);

  return (
    <label className={`select${className ? ` ${className}` : ''}`}>
      <span className="label">
        {label}
        {required && !disabled && <span className="asterisk">✱</span>}
      </span>
      <select ref={selectRef}
              autoFocus={autoFocus}
              name={name}
              required={required}
              disabled={disabled}
              value={value}
              onChange={onChange}>

        {includeEmpty &&
          <option value={''}></option>
        }
        {selections && selections.map(selection =>
          <Option key={selection}
                  formatter={formatter}
                  value={selection} />
        )}
        {children}
      </select>
    </label>
  );
}

export function Option({ formatter, value }) {
  const display = formatter ? formatter(value) : value;

  return (
    <option value={value}>{display}</option>
  );
}
