import React from 'react';

export function WidgetMembershipRemove({ onExit }) {
  return (
    <div className="widget">
      <p className="top-spacer-none">
        Click "Remove" under any staff member's name to revoke their access.
        Note: you cannot remove yourself. Dismiss this box to hide the "Remove"
        option.
      </p>
      <div className="flex-row">
        <div className="flex-1" />
        <button className="btn-primary" onClick={onExit}>Dismiss</button>
      </div>
    </div>
  );
}
