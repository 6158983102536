import React from 'react';

import                                 'pages/building/building.scss';
import                                 'widgets/button/button-v2.scss';
import { WidgetMembershipAdd }    from 'pages/building/widget-membership-add';
import { WidgetMembershipRemove } from 'pages/building/widget-membership-remove';
import { WidgetEditAddress }      from 'pages/building/widget-edit-address';
import { WidgetEditUnits }        from 'pages/building/widget-edit-units';
import { WidgetDeleteBuilding }   from 'pages/building/widget-delete-building';
import { CardMember }             from 'pages/building/card-member';
import { CardUnit }               from 'pages/building/card-unit';
import { ButtonIconOverText }     from 'widgets/button/button-icon-over-text';
import { useDbBuilding,
         makeUnitId,
         dbUpdateUnits }          from 'backend-layer/db/db-buildings';

export function BuildingPage({ buildingId }) {
  const building = useDbBuilding(buildingId);

  const [showWidget, setShowWidget] = React.useState();
  const [units,      setUnits]      = React.useState();

  const unpackData = React.useCallback(() => {
    setUnits(building?.units && Object.fromEntries(Object.entries(building.units).map(([unitId, unit]) => [unitId, unit.designator || ''])));
  }, [building]);

  React.useEffect(() => {
    unpackData();
  }, [unpackData]);

  const updatedNumOfUnits = React.useMemo(() => (
    units && Object.entries(units).filter(([unitId, designator]) => designator !== null).length
  ), [units]);

  const submitUnits = React.useCallback(e => {
    e.preventDefault();
    dbUpdateUnits(buildingId, units);
    setShowWidget();
  }, [buildingId, units]);

  const resetUnits = React.useCallback(e => {
    e.preventDefault();
    unpackData();
    setShowWidget();
  }, [unpackData]);

  const addUnit = React.useCallback(() => {
    setUnits({ ...units, [makeUnitId(buildingId)]: '' });
  }, [buildingId, units]);

  const removeUnit = React.useCallback(unitId => {
    setUnits({ ...units, [unitId]: null });
  }, [units]);

  const changeUnitDesignator = React.useCallback((unitId, designator) => {
    setUnits({ ...units, [unitId]: designator });
  }, [units]);

  return (
    <main className="page-building">
      <header className="page-header">
        <h1 className="top-spacer-none bottom-spacer-none">
          <small>Building Settings</small><br />
          {buildingId ? building?.address?.slice(0, building.address.indexOf(','))
                      : 'Add New Building'}
        </h1>
      </header>

      <div className="action-bar">
        <ButtonIconOverText icon="arrow_back"       label="To Buildings"    pathname="/management/file-room"                                                                           />
        <ButtonIconOverText icon="person_add"       label="Add Staff"       onClick={() => { showWidget !== 'add-member'      ? setShowWidget('add-member')       : setShowWidget() }} />
        <ButtonIconOverText icon="person_off"       label="Remove Staff"    onClick={() => { showWidget !== 'remove-member'   ? setShowWidget('remove-member')    : setShowWidget() }} />
        <ButtonIconOverText icon="apartment"        label="Edit Address"    onClick={() => { showWidget !== 'edit-address'    ? setShowWidget('edit-address')    : setShowWidget() }}  />
        <ButtonIconOverText icon="room_preferences" label="Edit Units"      onClick={() => { showWidget !== 'edit-units'      ? setShowWidget('edit-units')      : setShowWidget() }}  />
        <ButtonIconOverText icon="delete"           label="Delete Building" onClick={() => { showWidget !== 'delete-building' ? setShowWidget('delete-building') : setShowWidget() }}  />
      </div>

      {showWidget &&
        <div className="container-widgets">
          {
            (showWidget === 'add-member'      && <WidgetMembershipAdd  buildingId={buildingId}                     onExit={() => setShowWidget()} />) ||
            (showWidget === 'remove-member'   && <WidgetMembershipRemove                                           onExit={() => setShowWidget()} />) ||
            (showWidget === 'edit-address'    && <WidgetEditAddress    buildingId={buildingId} building={building} onExit={() => setShowWidget()} />) ||
            (showWidget === 'edit-units'      && <WidgetEditUnits                              addUnit={addUnit}   onExit={() => setShowWidget()} />) ||
            (showWidget === 'delete-building' && <WidgetDeleteBuilding buildingId={buildingId}                     onExit={() => setShowWidget()} />)
          }
        </div>
      }

      <h2>Staff Members</h2>
      <div className="container-member-cards">
        {building?.members && Object.entries(building.members).map(([uid, permissions]) => (
          <CardMember key={uid}
                      uid={uid}
                      role={permissions.role}
                      buildingId={buildingId}
                      isPrimedToRemove={showWidget === 'remove-member'} />
        ))}
      </div>

      <h2>Building Units</h2>
      <form id="building-page_form-units" className="container-card-units" onSubmit={submitUnits} onReset={resetUnits}>
        {units && Object.entries(units).filter(([unitId, designator]) => designator !== null).map(([unitId, designator]) => (
          <CardUnit key={unitId}
                    readOnly={showWidget !== 'edit-units'}
                    updatedNumOfUnits={updatedNumOfUnits}
                    numOfTenancies={building?.units?.[unitId]?.tenancies ? Object.keys(building.units[unitId].tenancies).length : 0}
                    designator={designator}
                    onChangeDesignator={designator => changeUnitDesignator(unitId, designator)}
                    onRemoveUnit={() => removeUnit(unitId)} />
        ))}
      </form>
      <p className="msg-managing-tenancies">
        Manage the tenancies of each unit on the Buildings & Rent page
      </p>
    </main>
  );
}
