import   React     from 'react';
import { getAuth,
         signOut } from 'firebase/auth';

import                                               'pages/tenant-dashboard/index.scss';
import { FormUserProfile }                      from 'widgets/forms/user-profile';
import { FormSwitchTenancy }                    from 'widgets/forms/switch-tenancy';
import { FormToManagementPortal }               from 'widgets/forms/switch-portal';
import { FormNewIssue }                         from 'widgets/forms/new-issue';
import { FormNotifications }                    from 'widgets/forms/notifications';
import { Statement,
         Rent,
         Balance}                               from 'widgets/statement';
import { Issue }                                from 'widgets/issue';
import { ButtonIconOverText }                   from 'widgets/button/button-icon-over-text';
import { LoginContext }                         from 'walls/login-wall/login-wall';
import { useDbTenancy }                         from 'backend-layer/db/db-tenancies';
import { useDbTransactionsWithRunningBalances } from 'backend-layer/db/db-transactions';
import { useDbBuildingAddress,
         useDbUnitDesignator }                  from 'backend-layer/db/db-buildings';
import { useStreetAddress }                     from 'widgets/address/address';
import { useDbTenancyIssues }                   from 'backend-layer/db/db-issues';
import { useDbUser }                            from 'backend-layer/db/db-users';

export function TenantDashboardPage({ buildingId, unitId, tenancyId }) {
  const login = React.useContext(LoginContext);
  const user = useDbUser(login.uid);

  const address                 = useDbBuildingAddress(buildingId);
  const streetAddress           = useStreetAddress(address);
  const unitDesignator          = useDbUnitDesignator (buildingId, unitId);
  const tenancy                 = useDbTenancy(buildingId, unitId, tenancyId);
  const [transactions, balance] = useDbTransactionsWithRunningBalances(buildingId, unitId, tenancyId);

  const [openIssues, closedIssues] = useDbTenancyIssues(buildingId, tenancyId);

  const [topToolbar,    setTopToolbar]    = React.useState();
  const [issuesToolbar, setIssuesToolbar] = React.useState();

  /* Close open widget when switching tenancies. */
  React.useEffect(() => { setTopToolbar(); setIssuesToolbar(); }, [tenancyId]);

  return (
    <main className="page-tenant-dashboard">

      <div className="top-toolbar">
        <ButtonIconOverText icon="badge"             label="Profile" onClick={() => { topToolbar !== 'profile'        ? setTopToolbar('profile')        : setTopToolbar(); }} backgroundImage={user?.avatar} />
        <ButtonIconOverText icon="other_houses"      label="Tenancy" onClick={() => { topToolbar !== 'switch-tenancy' ? setTopToolbar('switch-tenancy') : setTopToolbar(); }} />
        <ButtonIconOverText icon="real_estate_agent" label="Portal"  onClick={() => { topToolbar !== 'switch-portal'  ? setTopToolbar('switch-portal')  : setTopToolbar(); }} />
        <ButtonIconOverText icon="logout"            label="Logout"  onClick={() => { signOut(getAuth()); }} />
      </div>
      {
        (topToolbar === 'profile'        && <FormUserProfile onPhone={() => { setTopToolbar('notifications'); }} onExit={() => { setTopToolbar(); }} />)          ||
        (topToolbar === 'notifications'  && <FormNotifications                                                   onExit={() => { setTopToolbar('profile'); }} />) ||
        (topToolbar === 'switch-tenancy' && <FormSwitchTenancy                                                   onExit={() => { setTopToolbar(); }} /> )         ||
        (topToolbar === 'switch-portal'  && <FormToManagementPortal                                              onExit={() => { setTopToolbar(); }} />)
      }

      <h1>
        {streetAddress}{unitDesignator && <span className="unit-designator">{unitDesignator}</span>}
      </h1>

      <div className="rent-and-balance">
        <Rent rent={tenancy?.rent} rentAdjustment={tenancy?.rentAdjustment} />
        <Balance balance={balance} />
      </div>

      <Statement buildingId={buildingId}
                 unitId={unitId}
                 tenancyId={tenancyId}
                 transactions={transactions}
                 isCollapsible />

      <div className="issues-toolbar">
        <ButtonIconOverText icon="mark_unread_chat_alt" label="Notifications" onClick={() => { issuesToolbar !== 'notifications' ? setIssuesToolbar('notifications') : setIssuesToolbar(); }} />
        <ButtonIconOverText icon="construction"         label="New Issue"     onClick={() => { issuesToolbar !== 'new-issue'     ? setIssuesToolbar('new-issue')     : setIssuesToolbar(); }} />
      </div>
      {
        (issuesToolbar === 'notifications'  && <FormNotifications                                                          onExit={() => { setIssuesToolbar(); }} />) ||
        (issuesToolbar === 'new-issue'      && <FormNewIssue buildingId={buildingId} unitId={unitId} tenancyId={tenancyId} onExit={() => { setIssuesToolbar(); }} />)
      }

      {openIssues?.length > 0 && <h2>Open Issues</h2>}
      {openIssues?.map(issue => <Issue key={issue.issueId}
                                       issueId={issue.issueId}
                                       issue={issue} />)}

      {closedIssues?.length > 0 && <h2>Closed Issues</h2>}
      {closedIssues?.map(issue => <Issue key={issue.issueId}
                                         issueId={issue.issueId}
                                         issue={issue} />)}

      <h2 className="contact-us">Need help?</h2>
      <p className="contact-us">
        For any questions or issues with our software please don't hesitate to
        reach out to us at <a href="mailto:contact@apartmentify.com" target="_blank" rel="noreferrer">contact@apartmentify.com</a>
      </p>
    </main>
  );
}
