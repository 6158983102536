import   React     from 'react';
import { getAuth,
         signOut } from 'firebase/auth';

import                                 'pages/tenant-start-guide/index.scss';
import { FormUserProfile }        from 'widgets/forms/user-profile';
import { FormNotifications }      from 'widgets/forms/notifications';
import { FormToManagementPortal } from 'widgets/forms/switch-portal';
import { ButtonIconOverText }     from 'widgets/button/button-icon-over-text';
import { LoginContext }           from 'walls/login-wall/login-wall';
import { useDbUser }              from 'backend-layer/db/db-users';

export function TenantStartGuidePage() {
  const login = React.useContext(LoginContext);
  const user = useDbUser(login.uid);

  const [toolbar, setToolbar] = React.useState();

  return user && (
    <main className="page-tenant-start-guide">
      <h1>
        <img className="profile-pic"
             width="100"
             height="100"
             alt="Your profile pic"
             referrerPolicy="no-referrer"
             src={user?.avatar || '/default-avatar.jpg'} />
        {user.name}
        <small>{user.email}</small>
      </h1>

      <div className="toolbar">
        <ButtonIconOverText icon="badge"             label="Profile" onClick={() => { toolbar !== 'profile'       ? setToolbar('profile')       : setToolbar(); }} backgroundImage={user?.avatar} />
        <ButtonIconOverText icon="real_estate_agent" label="Portal"  onClick={() => { toolbar !== 'switch-portal' ? setToolbar('switch-portal') : setToolbar(); }} />
        <ButtonIconOverText icon="logout"            label="Logout"  onClick={() => { signOut(getAuth()); }} />
      </div>
      {
        (toolbar === 'profile'        && <FormUserProfile onPhone={() => { setToolbar('notifications'); }} onExit={() => { setToolbar(); }} />)          ||
        (toolbar === 'notifications'  && <FormNotifications                                                onExit={() => { setToolbar('profile'); }} />) ||
        (toolbar === 'switch-portal'  && <FormToManagementPortal                                           onExit={() => { setToolbar(); }} />)
      }

      <h2>No tenancies found</h2>
      <p>
        Welcome to Apartmentify! You’ll need to let your building manager know
        you’ve registered with {login.email}. They can now search for your
        account and connect you using their management portal.
      </p>

      <h2>Need help?</h2>
      <p>
        For any questions or issues with our software please don't hesitate to
        reach out to us at <a href="mailto:contact@apartmentify.com" target="_blank" rel="noreferrer">contact@apartmentify.com</a>
      </p>
    </main>
  );
}
