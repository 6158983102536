import   React        from 'react';
import { useHistory } from "react-router-dom";

import                          'pages/tenancy/tenancy.scss';
import                          'widgets/button/button-v2.scss';
import { PgTextField }     from 'widgets/text-field/text-field';
import { dbCreateTenancy } from 'backend-layer/db/db-tenancies';

export function TenancyCreator({ buildingId, unitId }) {
  const history = useHistory();

  const [commencement, setCommencement] = React.useState('');
  const [rent,         setRent]         = React.useState('');
  const [rentMemo,     setRentMemo]     = React.useState('');

  const submit = React.useCallback(e => {
    e.preventDefault();
    const tenancyId = dbCreateTenancy(buildingId,
                                      unitId,
                                      commencement,
                                      rent);
    history.replace(`/management/tenancy?buildingId=${buildingId}&unitId=${unitId}&tenancyId=${tenancyId}`);
  }, [
    history,
    buildingId,
    unitId,
    commencement,
    rent
  ]);

  const reset = React.useCallback(e => {
    e.preventDefault();
    history.replace(`/management/file-room`);
  }, [history]);

  return (
    <form className="tenancy-creator" onSubmit={submit} onReset={reset}>
      <div className="term flex-row">
        <PgTextField className="flex-3"
                     minWidth="150px"
                     type="date"
                     label="Lease started on"
                     value={commencement}
                     onChange={e => setCommencement(e.target.value)} />
        <PgTextField className="flex-2 left-spacer-sm"
                     minWidth="100px"
                     type="number"
                     inputMode="decimal"
                     label="Rent"
                     required
                     value={rent}
                     onChange={e => setRent(e.target.value)} />
      </div>

      <PgTextField className="top-spacer-sm"
                   label="Rent Memo"
                   multiline
                   value={rentMemo}
                   onChange={e => setRentMemo(e.target.value)} />

      <p>Tenant names will be added to the tenancy when you connect their account</p>

      <div className="flex-row top-spacer-lg">
        <div className="flex-1" />
        <button className="btn-filled-change"                 type="submit">Create Tenancy</button>
        <button className="btn-filled-primary left-spacer-xs" type="reset" >Cancel</button>
      </div>
    </form>
  );
}
