import   React           from 'react';
import { getAuth,
         onAuthStateChanged,
         signInWithPopup,
         GoogleAuthProvider,
         OAuthProvider } from 'firebase/auth';
import { getAnalytics,
         logEvent }      from 'firebase/analytics';

import                                'walls/login-wall/login-wall.scss';
import                                'walls/login-wall/login-btns.scss';
import { dbUpdateUserFromLogin } from 'backend-layer/db/db-users';

export const LoginContext = React.createContext();

export function LoginWall({ children }) {
  const [firebaseUser, setFirebaseUser] = React.useState();

  React.useEffect(() => {
    return onAuthStateChanged(getAuth(), firebaseUser => {
      if (firebaseUser?.uid) {
        logEvent(getAnalytics(), 'login');
        setFirebaseUser({ ...firebaseUser });
        dbUpdateUserFromLogin();
      } else {
        logEvent(getAnalytics(), 'logout');
        setFirebaseUser(null);
      }
    });
  }, []);

  switch(firebaseUser) {
    case undefined: return <div id="app-loading-indicator"></div>;

    case null: return (
      <main className="login-wall">
        <section className="section-info">
          <h1>
            Property Management Software<br />
            <small>Work smart with a software built for productivity</small>
          </h1>
          <table>
            <tbody>
              <tr><td>Maintenance Tracking</    td><td>Free</td></tr>
              <tr><td>Assign Work Request</     td><td>Free</td></tr>
              <tr><td>Schedule Repairs</        td><td>Free</td></tr>
              <tr><td>Text Notifications</      td><td>Free</td></tr>
              <tr><td>Rent Roll Overview</      td><td>Free</td></tr>
              <tr><td>Tenancy Statements</      td><td>Free</td></tr>
              <tr><td>Scheduled Rent Increases</td><td>Free</td></tr>
              <tr><td>Record Offline Payments</ td><td>Free</td></tr>
              <tr><td>Online Rent Payments</    td><td>In the works</td></tr>
            </tbody>
          </table>
        </section>

        <section className="section-action">
          <h1>Property<br />Management<br />Software</h1>

          <div className="callout-login">
            <h2>Get started</h2>
            <nav className="btn-group-login">
              <button className="btn-login btn-login-google"
                      onClick={() => signInWithPopup(getAuth(), new GoogleAuthProvider())}>
                <img src="/icon-google.svg" alt="" width="38" height="38" />Sign in with Google
              </button>
              <button className="btn-login btn-login-apple"
                      onClick={() => signInWithPopup(getAuth(), new OAuthProvider('apple.com'))}>
                <img src="/icon-apple.svg" alt="" width="18" height="18" />Sign in with Apple
              </button>
            </nav>
          </div>

          <footer>
            <img src="/logo-with-name.svg"
                 alt="Apartmentify"
                 width="407.12"
                 height="56.85" />
            <a href="mailto:contact@apartmentify.com">contact@apartmentify.com</a>
          </footer>
        </section>
      </main>
    );

    default: return (
      <LoginContext.Provider value={firebaseUser}>
        {children}
      </LoginContext.Provider>
    );
  }
}
