import   React     from 'react';
import { NavLink } from 'react-router-dom';

import                                       'widgets/forms/index.scss';
import                                       'widgets/forms/switch-tenancy/index.scss';
import { LoginContext }                 from 'walls/login-wall/login-wall';
import { UrlContext }                   from 'walls/url-parsing-wall/url-parsing-wall';
import { useStreetAddress }             from 'widgets/address/address';
import { FormattedMonth }               from 'widgets/formatter/formatter';
import { useDbBuildingAddress,
         useDbUnitDesignator }          from 'backend-layer/db/db-buildings';
import { useDbTenancyMemperships,
         useDbTenancyMempershipCleanup} from 'backend-layer/db/db-memberships';
import { useDbTenancy }                 from 'backend-layer/db/db-tenancies';
import { DbUserName }                   from 'backend-layer/db/db-users';

export function FormSwitchTenancy({ onExit }) {
  const login = React.useContext(LoginContext);
  const url = React.useContext(UrlContext);
  const tenancyMemberships = useDbTenancyMemperships(login.uid);

  return tenancyMemberships !== undefined ? (
    <section className="widget-form widget-form-switch-tenancy">
      {tenancyMemberships && Object.entries(tenancyMemberships).map(([tenancyId, { buildingId, unitId }]) => (
        <LinkTenancyMembership key={tenancyId}
                               buildingId={buildingId}
                               unitId={unitId}
                               tenancyId={tenancyId}
                               activeTenancyId={url.tenancyId} />
      ))}
      <div className="flex-row">
        <div className="flex-1" />
        <button className="btn-primary" onClick={onExit}>Cancel</button>
      </div>
    </section>
  ) : null;
}

const LinkTenancyMembership = React.memo(({ buildingId, unitId, tenancyId, activeTenancyId }) => {
  const address = useDbBuildingAddress(buildingId);
  const streetAddress = useStreetAddress(address);
  const unitDesignator = useDbUnitDesignator(buildingId, unitId);
  const isMember = useDbTenancyMempershipCleanup(buildingId, unitId, tenancyId);
  const tenancy = useDbTenancy(buildingId, unitId, tenancyId);

  return isMember && tenancy ? (
    <NavLink className="btn-filled-alt flex-row align-center"
             to={`/tenant?buildingId=${buildingId}&unitId=${unitId}&tenancyId=${tenancyId}`}>
       <i className="material-icons" aria-hidden="true">
         {tenancyId === activeTenancyId ? 'check_circle' : 'circle'}
       </i>
       <div className="flex-col flex-1">
         <div className="flex-row">
           <div>{streetAddress} #{unitDesignator}</div>
           <div className="flex-1" />
           {tenancy.commencementDate &&
             <strong className="text-align-right"><FormattedMonth date={tenancy.commencementDate} /></strong>
           }
         </div>
         <div className="tenants">
           {tenancy.members && Object.keys(tenancy.members).map(uid => <DbUserName key={uid} uid={uid} />)}
         </div>
       </div>
    </NavLink>
  ) : null;
});
