import { getDistance } from 'geolib';

import { openIssuesSorter,
         closedIssuesSorter } from 'backend-layer/db/db-issues';

export const initialState = {
  buildings:    undefined,
  openIssues:   undefined, // Array
  closedIssues: undefined  // Array
};

export function reducer(state, { action, payload }) {
  switch (action) {
    case 'SET_NO_BUILDINGS':           return setNoBuildings();
    case 'SET_BUILDING':               return setBuilding(state, payload);
    case 'SET_BUILDING_OPEN_ISSUES':   return setBuildingOpenIssues(state, payload);
    case 'SET_BUILDING_CLOSED_ISSUES': return setBuildingClosedIssues(state, payload);
    case 'REMOVE_BUILDING':            return removeBuilding(state, payload);

    default: return state;
  }
};

function setNoBuildings() {
  return {
    buildings:    null,
    openIssues:   [],
    closedIssues: []
  };
}

function setBuilding(state, payload) {
  return {
    ...state,
    buildings: {
      ...state.buildings,
      [payload.buildingId]: {
        ...payload.building,
          geoOrder: (state.buidlings?.[payload.buildingId]?.lat === payload.building.lat &&
                     state.buidlings?.[payload.buildingId]?.lng === payload.building.lng &&
                     state.buidlings?.[payload.buildingId]?.geoOrder) ||
                    (payload.building.lat &&
                     payload.building.lng &&
                     getDistance({ latitude: 0, longitude: 0 },
                                 { latitude: payload.building.lat, longitude: payload.building.lng }))
      }
    }
  };
}

function setBuildingOpenIssues(state, { buildingId, buildingOpenIssues }) {
  return {
    ...state,
    openIssues: [
      ...state.openIssues?.filter(issue => issue.buildingId !== buildingId) || [],
      ...buildingOpenIssues
    ].sort(openIssuesSorter)
  }
}

function setBuildingClosedIssues(state, { buildingId, buildingClosedIssues }) {
  return {
    ...state,
    closedIssues: [
      ...state.closedIssues?.filter(issue => issue.buildingId !== buildingId) || [],
      ...buildingClosedIssues
    ].sort(closedIssuesSorter)
  }
}

function removeBuilding(state, { buildingId }) {
  const { [buildingId]: _, ...remainingBuildings } = state.buildings || {};
  return {
    ...state,
    buildings   : Object.keys(remainingBuildings).length > 0 ? remainingBuildings : null,
    openIssues  : state.openIssues?.filter(issue => issue.buildingId !== buildingId).sort(openIssuesSorter),
    closedIssues: state.closedIssues?.filter(issue => issue.buildingId !== buildingId).sort(closedIssuesSorter)
   };
}
