import React from 'react';

import                         'widgets/statement/index.scss';
import { StatementItem }  from 'widgets/statement/item';
import { FormattedMonth } from 'widgets/formatter/formatter';
import { FormattedMoney } from 'widgets/formatter/formatter';

const collapsedCount = 4;

export function Statement({ buildingId, unitId, tenancyId, transactions, isCollapsible }) {
  const [isCollapsed, setIsCollapsed] = React.useState(isCollapsible);

  return (
    <table className="widget-statement">
      <thead>
        <tr>
          <th className="text-align-left" >Transaction</th>
          <th className="text-align-right">Amount</th>
          <th className="text-align-right">Balance</th>
        </tr>
      </thead>
      <tbody>
        {transactions?.length && transactions.slice(0, isCollapsed ? collapsedCount : undefined).map(transaction =>
          <StatementItem key={transaction.id}
                         buildingId={buildingId}
                         unitId={unitId}
                         tenancyId={tenancyId}
                         transaction={transaction} />
        )}
        {transactions === null &&
          <tr>
            <td className="cell-no-transactions" colSpan="4">No transactions</td>
          </tr>
        }
        {isCollapsible && transactions?.length > collapsedCount &&
          <tr className="row-toggle">
            <td colSpan="4" role="button" onClick={() => setIsCollapsed(!isCollapsed)}>
              <i className="material-icons">
                {isCollapsed ? 'expand_more' : 'expand_less'}
              </i>
            </td>
          </tr>
        }
      </tbody>
    </table>
  );
}

export const Rent = React.memo(({ rent, rentAdjustment }) => {
  return (
    <div className="widget-rent flex-7">
      <h3>Rent</h3>
      {rentAdjustment ?
        <>
          <div className="value">
            <FormattedMoney amount={rentAdjustment.rent} />
            <small style={{ marginLeft: '6px',
                            textDecoration: 'line-through' }}>
              <FormattedMoney amount={rent} />
            </small>
          </div>
          <p>change effective <FormattedMonth date={rentAdjustment.start} /></p>
        </>
      :
        <div className="value">
          <FormattedMoney amount={rent} />
        </div>
      }
    </div>
  );
});

export const Balance = React.memo(({ balance }) => {
  return (
    <div className="widget-balance flex-5">
      <h3>Balance</h3>
      <div className="value">
        <FormattedMoney amount={balance ?? 0} />
      </div>
    </div>
  );
});
