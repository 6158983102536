import React from 'react';

import { PgTextField } from 'widgets/text-field/text-field';

export function StatementItemDetailsOnlinePayment({ transaction }) {
  return (
    <>
      <div className="flex-row">
        <PgTextField className="flex-3"
                     label="Type"
                     readOnly
                     value={transaction.type} />
       <PgTextField className="flex-4 left-spacer-sm"
                    label="Submitted"
                    readOnly
                    value={new Date(transaction.created).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' })} />
       <PgTextField className="flex-3 left-spacer-sm text-capitalize"
                    label="Status"
                    readOnly
                    value={transaction.status} />
      </div>

      <div className="flex-row top-spacer-sm">
        <PgTextField className="flex-5"
                     label="Payer"
                     readOnly
                     value={transaction.payer} />
        {transaction.status === 'cancelled' || transaction.status === 'failed' ?
          <PgTextField className="flex-3 left-spacer-sm text-capitalize"
                       label={`${transaction.status} Amount`}
                       readOnly
                       value={transaction.attemptedAmount.toLocaleString('en-US', { minimumFractionDigits: 2 })} />
        :
          <PgTextField className="flex-3 left-spacer-sm"
                       label="Amount"
                       readOnly
                       value={transaction.amount.toLocaleString('en-US', { minimumFractionDigits: 2 })} />
        }
      </div>

      <div className="flex-row top-spacer-sm">
        <PgTextField className="flex-2"
                     label="Debit ACH ID"
                     readOnly
                     value={transaction.debitAchId} />
        <PgTextField className="flex-3 left-spacer-sm"
                     label="Paid from"
                     readOnly
                     value={transaction.payerBankAcct} />
      </div>

      <div className="flex-row top-spacer-sm">
        <PgTextField className="flex-3"
                     label="Deposited to"
                     readOnly
                     value={transaction.payeeBankAcct} />
        <PgTextField className="flex-2 left-spacer-sm"
                     label="Deposit ACH ID"
                     readOnly
                     value={transaction.depositAchId} />
      </div>

      {transaction.status === 'failed' && transaction.returnCode &&
        <PgTextField className="top-spacer-sm"
                     label="Failure Reason"
                     multiline
                     readOnly
                     value={getReturnReason(transaction.returnCode)} />
      }
    </>
  );
}

function getReturnReason(code) {
  switch(code) {
    case 'R01': return 'Insufficient funds. Available balance is not sufficient to cover the dollar value of the debit entry.';
    case 'R02': return 'Bank account closed. Previously active account has been closed.';
    case 'R03': return 'No account/unable to locate account. Account number structure is valid, but doesn’t match individual identified in entry or is not an open account.';
    case 'R04': return 'Invalid bank account number structure. Account number structure is not valid.';
    case 'R05': return 'Unauthorized debit to consumer account. A debit entry was transmitted to a consumer account that was not authorized by the Receiver. Written Statement is required.';
    case 'R07': return 'Authorization revoked by customer. Consumer who previously authorized entries has revoked authorization with the Originator. Written Statement is required.';
    case 'R08': return 'Payment stopped. The Receiver has requested the stop payment of a specific ACH debit entry.';
    case 'R09': return 'Uncollected funds. Sufficient balance exists, but value of uncollected items brings available balance below amount of debit entry.';
    case 'R10': return 'Customer advises originator is not known to receiver and/or originator is not authorized by receiver to debit receiver’s account. Receiver has no relationship with the originator or has not authorized the originator to debit the account.';
    case 'R11': return 'Customer advises entry not in accordance with the terms of the authorization. The debit entry was inaccurate or improperly initiated. Other reasons include source document was ineligible, notice was not provided to the receive or amount was inaccurately obtained.';
    case 'R16': return 'Bank account frozen. Funds unavailable due to action by the RDFI or legal action.';
    case 'R20': return 'Non-transaction account. RDFI policies/regulations restrict activity to account.';
    case 'R29': return 'Corporate customer advises not authorized. Receiver has notified RDFI that corporate debit entry transmitted to a corporate account is not authorized.';

    default: return '';
  }
}
