import React from 'react';

import { UrlContext }    from 'walls/url-parsing-wall/url-parsing-wall';
import { AppManagement } from 'app/app-management';
import { AppTenant }     from 'app/app-tenant';

export function App() {
  const url = React.useContext(UrlContext);

  switch (url.portal) {
    case 'management': return <AppManagement />;
    case 'tenant'    : return <AppTenant />;
    default          : return null;
  }
}
