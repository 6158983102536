import React from 'react';

import                        'widgets/button/button-v2.scss';
import { PgTextField }   from 'widgets/text-field/text-field';
import { dbSetRentMemo } from 'backend-layer/db/db-tenancies';

export function WidgetEditRentMemo({ buildingId,
                                     unitId,
                                     tenancyId,
                                     currentRentMemo,
                                     onExit }) {
  const [rentMemo, setRentMemo] = React.useState(currentRentMemo || '');

  const submit = React.useCallback(e => {
    e.preventDefault();
    dbSetRentMemo(buildingId, unitId, tenancyId, rentMemo);
    onExit();
  }, [buildingId, unitId, tenancyId, rentMemo, onExit]);

  const reset = React.useCallback(e => {
    e.preventDefault();
    onExit();
  }, [onExit]);

  return (
    <form className="widget" onSubmit={submit} onReset={reset}>
      <PgTextField label="Current Rent Memo"
                   multiline
                   value={rentMemo}
                   onChange={e => setRentMemo(e.target.value)} />

      <div className="flex-row top-spacer-md">
        <div className="flex-1" />
        <button className="btn-change"                 type="submit">Update</button>
        <button className="btn-primary left-spacer-xs" type="reset" >Cancel</button>
      </div>
    </form>
  );
}
