import React from 'react';

import                            'widgets/button/button-v2.scss';
import { PgTextField }       from 'widgets/text-field/text-field';
import { dbSetTenancyStart } from 'backend-layer/db/db-tenancies';

export function WidgetEditStart({ buildingId,
                                  unitId,
                                  tenancyId,
                                  currentStart,
                                  onExit }) {
  const [start, setStart] = React.useState(currentStart || '');

  const submit = React.useCallback(e => {
    e.preventDefault();
    dbSetTenancyStart(buildingId, unitId, tenancyId, start);
    onExit();
  }, [buildingId, unitId, tenancyId, start, onExit]);

  const reset = React.useCallback(e => {
    e.preventDefault();
    onExit();
  }, [onExit]);

  return (
    <form className="widget" onSubmit={submit} onReset={reset}>
      <PgTextField type="date"
                   label="Lease started on"
                   value={start}
                   onChange={e => setStart(e.target.value)} />

      <div className="flex-row top-spacer-md">
        <div className="flex-1" />
        <button className="btn-change"                 type="submit">Set</button>
        <button className="btn-primary left-spacer-xs" type="reset" >Cancel</button>
      </div>
    </form>
  );
}
