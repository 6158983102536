import React from 'react';

import { LoginContext }                   from 'walls/login-wall/login-wall';
import { reducer,
         initialState }                   from 'store/reducer';
import { useDbBuildingMemperships,
         useDbBuildingMempershipCleanup } from 'backend-layer/db/db-memberships';
import { useDbBuilding }                  from 'backend-layer/db/db-buildings';
import { useDbBuildingIssues }            from 'backend-layer/db/db-issues';

export const AppContext = React.createContext();

export function Store({ children }) {
  const login = React.useContext(LoginContext);
  const buildingMemberships = useDbBuildingMemperships();
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    if(buildingMemberships === null) {
      dispatch({ action: 'SET_NO_BUILDINGS' });
    }
  }, [buildingMemberships]);

  return buildingMemberships !== undefined && (
    <AppContext.Provider value={[state, dispatch]}>
      {buildingMemberships && Object.keys(buildingMemberships).map(buildingId =>
        <StoreSyncroBuilding key={buildingId}
                             buildingId={buildingId}
                             uid={login.uid} />
      )}
      {children}
    </AppContext.Provider>
  );
}

export function StoreSyncroBuilding({ buildingId, uid }) {
  const [, dispatch] = React.useContext(AppContext);

  const isMember             = useDbBuildingMempershipCleanup(buildingId);
  const building             = useDbBuilding(buildingId);
  const buildingOpenIssues   = useDbBuildingIssues(buildingId, true, uid);
  const buildingClosedIssues = useDbBuildingIssues(buildingId, false, uid);

  React.useEffect(() => {
    if(isMember && building) {
      dispatch({
        action: 'SET_BUILDING',
        payload: { buildingId, building }
      });
    }
  }, [buildingId, dispatch, isMember, building ]);

  React.useEffect(() => {
    if (isMember && buildingOpenIssues) {
      dispatch({
        action: 'SET_BUILDING_OPEN_ISSUES',
        payload: { buildingId, buildingOpenIssues }
      });
    }
  }, [buildingId, dispatch, isMember, buildingOpenIssues]);

  React.useEffect(() => {
    if (isMember && buildingClosedIssues) {
      dispatch({
        action: 'SET_BUILDING_CLOSED_ISSUES',
        payload: { buildingId, buildingClosedIssues }
      });
    }
  }, [buildingId, dispatch, isMember, buildingClosedIssues]);

  React.useEffect(() => {
    return () => {
      dispatch({
        action: 'REMOVE_BUILDING',
        payload: { buildingId }
      });
    }
  }, [buildingId, dispatch]);

  return null;
}
