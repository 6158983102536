import React from 'react';

import                                'widgets/button/button-v2.scss';
import { formatPhone }           from 'widgets/text-field/input-phone';
import { useDbUser }             from 'backend-layer/db/db-users';
import { useDbPhone }            from 'backend-layer/db/db-users';
import { dbRemoveTenancyMember } from 'backend-layer/db/db-memberships';

const fallbackProfilePic = `data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='172' height='172' viewBox='0 0 172 172'%3E%3Crect fill='rgb(224,224,224)' width='172' height='172' /%3E%3C/svg%3E`;

export function CardTenant({ buildingId, unitId, tenancyId, uid, isPrimedToRemove }) {
  const user        = useDbUser(uid);
  const phoneNumber = useDbPhone(uid);

  const removeTenant = React.useCallback(() => {
    dbRemoveTenancyMember(buildingId, unitId, tenancyId, uid);
  }, [buildingId, unitId, tenancyId, uid]);

  return user ? (
    <div className="card-tenant flex-col">
      <img src={user.avatar || fallbackProfilePic}
           alt={user.name}
           width="172"
           height="172"
           referrerPolicy="no-referrer" />

      <div className="body flex-1 flex-col">
        <div className="flex-1">
          <div className="info card-tenant_name">{user.name}</div>
          <div className="info text-secondary">{user.email}</div>
          <div className="info text-secondary">{formatPhone(phoneNumber).display}</div>
        </div>
        {isPrimedToRemove &&
          <div className="flex-row top-spacer-sm">
            <div className="flex-1" />
            <button className="btn-remove" onClick={removeTenant}>Remove</button>
          </div>
        }
      </div>
    </div>
  ) : null;
}
