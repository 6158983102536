import React from 'react';

import                             'pages/issues/index.scss';
import { FormNewIssue }       from 'widgets/forms/new-issue-management';
import { FormNotifications }  from 'widgets/forms/notifications';
import { Issue }              from 'widgets/issue';
import { ButtonIconOverText } from 'widgets/button/button-icon-over-text';
import { UrlContext }         from 'walls/url-parsing-wall/url-parsing-wall';
import { AppContext }         from 'store/store';

export function IssuesPage({ hidden }) {
  const url = React.useContext(UrlContext);
  const [state] = React.useContext(AppContext);

  const [isShowClosedIssues,   setIsShowClosedIssues]   = React.useState(false);
  const [isRenderClosedIssues, setIsRenderClosedIssues] = React.useState(false);
  const [toolbar,              setToolbar]              = React.useState();

  React.useEffect(() => {
    if (isShowClosedIssues) {
      setIsRenderClosedIssues(true);
    }
  }, [isShowClosedIssues]);

  React.useEffect(() => {
    if (url.page !== 'maintenance') {
      setIsShowClosedIssues(false);
      setToolbar();
    }
  }, [url.page]);

  return (
    <main className="page-issues" hidden={hidden}>
      <div className="toolbar">
        <ButtonIconOverText icon="arrow_back"                                       label="To Buildings"                                           pathname="/management/file-room" />
        <ButtonIconOverText icon={isShowClosedIssues ? 'task_alt' : 'build_circle'} label={isShowClosedIssues ? 'Showing Closed' : 'Showing Open'} onClick={() => { setIsShowClosedIssues(!isShowClosedIssues); }} />
        <ButtonIconOverText icon="add_task"                                         label="New Issue"                                              onClick={() => { toolbar !== 'new-issue' ? setToolbar('new-issue') : setToolbar(); }} />
      </div>

      {(toolbar === 'new-issue' || toolbar === 'notifications') &&
        <FormNewIssue hidden={toolbar !== 'new-issue'}
                      onNotifications={() => { setToolbar('notifications'); }}
                      onExit={() => { setToolbar(); }} />
      }
      {toolbar === 'notifications' &&
        <FormNotifications onExit={() => { setToolbar('new-issue'); }} />
      }

      <div className="issues" hidden={isShowClosedIssues}>
        {state.openIssues?.map(issue =>
          <Issue key={issue.issueId}
                 issueId={issue.issueId}
                 issue={issue}
                 isManagement={true} />
        )}
        {state.openIssues?.length === 0 &&
          <p>Horray, no open issues!</p>
        }
      </div>

      {isRenderClosedIssues &&
        <div className="issues" hidden={!isShowClosedIssues}>
          {state.closedIssues?.map(issue =>
            <Issue key={issue.issueId}
                   issueId={issue.issueId}
                   issue={issue}
                   isManagement={true} />
          )}
          {state.closedIssues?.length === 0 &&
            <p>No closed issues. Once closed, your open issues will be moved here.</p>
          }
        </div>
      }
    </main>
  );
}
