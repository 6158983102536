import React from 'react';

import                               'widgets/issue/forms.scss';
import                               'widgets/button/button-v2.scss';
import { Files }                from 'widgets/file-uploader/file-uploader';
import { PgTextField }          from 'widgets/text-field/text-field';
import { Select }               from 'widgets/select/select';
import { useDbUser }            from 'backend-layer/db/db-users';
import { useDbBuildingMembers } from 'backend-layer/db/db-memberships';
import { dbRetitleIssue,
         dbAssignIssue,
         dbScheduleIssue,
         dbCommentOnIssue,
         dbCloseIssue,
         dbReopenIssue,
         dbDeleteIssue }        from 'backend-layer/db/db-issues';

export const titleMaxChars   = 30;
export const commentMaxChars = 1000;

export function IssueFormTitle({ buildingId, issueId, currentTitle, onExit }) {
  const [title, setTitle]  = React.useState(currentTitle || '');

  const unpackData = React.useCallback(() => {
    setTitle(currentTitle || '');
  }, [currentTitle]);

  const submit = React.useCallback(e => {
    e.preventDefault();
    dbRetitleIssue(buildingId, issueId, title); // Since retitle does not create an action, no need to check if title has changed.
    onExit();
  }, [buildingId, issueId, title, onExit]);

  const reset = React.useCallback(e => {
    e.preventDefault();
    onExit();
  }, [onExit]);

  React.useEffect(() => {
    unpackData();
  }, [unpackData]);

  return (
    <form className="widget-issue-form" onSubmit={submit} onReset={reset}>
      <PgTextField autoFocus
                   label="Issue Title"
                   icon="clear"
                   required
                   maxLength={titleMaxChars}
                   value={title}
                   onChange={e => setTitle(e.target.value)}
                   onClickIcon={e => setTitle('')} />

      <div className="flex-row">
        <div className="flex-1" />
        <button className="btn-change"                 type="submit">Retitle</button>
        <button className="btn-primary left-spacer-xs" type="reset" >Cancel</button>
      </div>
    </form>
  );
}

export function IssueFormAssignee({ buildingId, issueId, currentAssignee, onExit }) {
  const buildingMembers = useDbBuildingMembers(buildingId);
  const [assignee, setAssignee] = React.useState(currentAssignee || '');

  const unpackData = React.useCallback(() => {
    setAssignee(currentAssignee || '');
  }, [currentAssignee]);

  const submit = React.useCallback(e => {
    e.preventDefault();
    if (assignee !== currentAssignee) {
      dbAssignIssue(buildingId, issueId, assignee);
    }
    onExit();
  }, [buildingId, issueId, currentAssignee, assignee, onExit]);

  const reset = React.useCallback(e => {
    e.preventDefault();
    onExit();
  }, [onExit]);

  const unassign = React.useCallback(() => {
    dbAssignIssue(buildingId, issueId, null);
    onExit();
  }, [buildingId, issueId, onExit]);

  React.useEffect(() => {
    unpackData();
  }, [unpackData]);

  return (
    <form className="widget-issue-form" onSubmit={submit} onReset={reset}>
      <Select label="Assignee"
              required
              value={assignee}
              onChange={e => setAssignee(e.target.value)}>
        {buildingMembers && Object.keys(buildingMembers).map(uid =>
          <IssueFormAssigneeOption key={uid} uid={uid} />
        )}
      </Select>

      <div className="flex-row">
        {currentAssignee &&
          <button className="btn-alt"
                  type="button"
                  onClick={unassign}>Unassign</button>
        }
        <div className="flex-1" />
        <button className="btn-change"                 type="submit">Assign</button>
        <button className="btn-primary left-spacer-xs" type="reset" >Cancel</button>
      </div>
    </form>
  );
}

function IssueFormAssigneeOption({ uid }) {
  const user = useDbUser(uid);
  return (
    <option value={uid}>{user?.name}</option>
  );
}

export function IssueFormAppointment({ buildingId, issueId, currentAppointment, onExit }) {
  const [appointment, setAppointment] = React.useState(currentAppointment?.substring(0, 10) || ''); // Removes time portion

  const unpackData = React.useCallback(() => {
    setAppointment(currentAppointment?.substring(0, 10) || '');
  }, [currentAppointment]);

  const submit = React.useCallback(e => {
    e.preventDefault();
    if (`${appointment}T00:00` !== currentAppointment) {
      dbScheduleIssue(buildingId, issueId, `${appointment}T00:00`); // Adds time portion
    }
    onExit();
  }, [buildingId, issueId, currentAppointment, appointment, onExit]);

  const reset = React.useCallback(e => {
    e.preventDefault();
    onExit();
  }, [onExit]);

  const unschedule = React.useCallback(() => {
    dbScheduleIssue(buildingId, issueId, null);
    onExit();
  }, [buildingId, issueId, onExit]);

  React.useEffect(() => {
    unpackData();
  }, [unpackData]);

  return (
    <form className="widget-issue-form" onSubmit={submit} onReset={reset}>
      <PgTextField autoFocus
                   label="Appointment"
                   type="date"
                   required
                   value={appointment}
                   onChange={e => setAppointment(e.target.value)} />

      <div className="flex-row">
        {currentAppointment &&
          <button className="btn-alt"
                  type="button"
                  onClick={unschedule}>Unschedule</button>
        }
        <div className="flex-1" />
        <button className="btn-change"                 type="submit">Set</button>
        <button className="btn-primary left-spacer-xs" type="reset" >Cancel</button>
      </div>
    </form>
  );
}

export function IssueFormDelete({ buildingId, issueId, onExit }) {
  const submit = React.useCallback(e => {
    e.preventDefault();
    dbDeleteIssue(buildingId, issueId);
    onExit();
  }, [buildingId, issueId, onExit]);

  const reset = React.useCallback(e => {
    e.preventDefault();
    onExit();
  }, [onExit]);

  return (
    <form className="widget-issue-form" onSubmit={submit} onReset={reset}>
      <p>
        Are you sure you want to delete this issue and all its comments,
        actions,and images? This is irreversible.
      </p>

      <div className="flex-row">
        <div className="flex-1" />
        <button className="btn-remove"                 type="submit">Delete</button>
        <button className="btn-primary left-spacer-xs" type="reset" >Cancel</button>
      </div>
    </form>
  );
}

export function IssueFormComment({ buildingId, issueId, onExit }) {
  const [comment, setComment]  = React.useState('');
  const [attachments, setAttachments] = React.useState();

  const submit = React.useCallback(e => {
    e.preventDefault();
    dbCommentOnIssue(buildingId, issueId, comment, attachments);
    onExit();
  }, [buildingId, issueId, comment, attachments, onExit]);

  const reset = React.useCallback(e => {
    e.preventDefault();
    onExit();
  }, [onExit]);

  return (
    <form className="widget-issue-form" onSubmit={submit} onReset={reset}>
      <PgTextField autoFocus
                   multiline
                   required
                   maxLength={commentMaxChars}
                   placeholder="Write note ..."
                   value={comment}
                   onChange={e => setComment(e.target.value)} />
      <Files onFiles={files => setAttachments(files)} />

      <div className="flex-row">
        <div className="flex-1" />
        <button className="btn-change"                 type="submit">Post</button>
        <button className="btn-primary left-spacer-xs" type="reset" >Cancel</button>
      </div>
    </form>
  );
}

export function IssueFormClose({ buildingId, issueId, onExit }) {
  const [fixed, setFixed] = React.useState();

  const submit = React.useCallback(e => {
    e.preventDefault();
    dbCloseIssue(buildingId, issueId, fixed);
    onExit();
  }, [buildingId, issueId, fixed, onExit]);

  const reset = React.useCallback(e => {
    e.preventDefault();
    onExit();
  }, [onExit]);

  return (
    <form className="widget-issue-form" onSubmit={submit} onReset={reset}>
      <p>
        Close this issue marking it as fixed or no fix.
      </p>

      <div className="flex-row">
        <button className="btn-alt"                    type="submit" onClick={e => setFixed(false)}>Nofix< /button>
        <div className="flex-1" />
        <button className="btn-change"                 type="submit" onClick={e => setFixed(true)} >Fixed< /button>
        <button className="btn-primary left-spacer-xs" type="reset"                                >Cancel</button>
      </div>
    </form>
  );
}

export function IssueFormReopen({ buildingId, issueId, onExit }) {
  const submit = React.useCallback(e => {
    e.preventDefault();
    dbReopenIssue(buildingId, issueId);
    onExit();
  }, [buildingId, issueId, onExit]);

  const reset = React.useCallback(e => {
    e.preventDefault();
    onExit();
  }, [onExit]);

  return (
    <form className="widget-issue-form" onSubmit={submit} onReset={reset}>
      <p>
        Confirm you want to reopen this issue.
      </p>

      <div className="flex-row">
        <div className="flex-1" />
        <button className="btn-change"                 type="submit">Reopen</button>
        <button className="btn-primary left-spacer-xs" type="reset" >Cancel</button>
      </div>
    </form>
  );
}
