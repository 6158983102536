import React from 'react';

import                                    'pages/tenancy/tenancy.scss';
import { TenancyCreator }            from 'pages/tenancy/tenancy-creator';
import { WidgetAddTenant }           from 'pages/tenancy/widget-add-tenant';
import { WidgetRemoveTenant }        from 'pages/tenancy/widget-remove-tenant';
import { WidgetRecordPayment }       from 'pages/tenancy/widget-record-payment';
import { WidgetRecordReimbursement } from 'pages/tenancy/widget-record-reimbursement';
import { WidgetAddCharge }           from 'pages/tenancy/widget-add-charge';
import { WidgetAdjustRent }          from 'pages/tenancy/widget-adjust-rent';
import { WidgetEditRentMemo }        from 'pages/tenancy/widget-edit-rent-memo';
import { WidgetEndTenancy }          from 'pages/tenancy/widget-end-tenancy';
import { WidgetEditStart }           from 'pages/tenancy/widget-edit-start';
import { WidgetDeleteTenancy }       from 'pages/tenancy/widget-delete-tenancy';
import { CardTenant }                from 'pages/tenancy/card-tenant';
import { Statement,
         Rent,
         Balance }                   from 'widgets/statement';
import { FormattedDate }             from 'widgets/formatter/formatter';
import { ButtonIconOverText }        from 'widgets/button/button-icon-over-text';
import { LoginContext }              from 'walls/login-wall/login-wall';
import { UrlContext }                from 'walls/url-parsing-wall/url-parsing-wall';

import { useDbTenancy }                         from 'backend-layer/db/db-tenancies';
import { useDbTransactionsWithRunningBalances } from 'backend-layer/db/db-transactions';
import { useDbBuildingMemberRole }              from 'backend-layer/db/db-memberships.js';
import { useDbBuildingAddress,
         useDbUnitDesignator }                  from 'backend-layer/db/db-buildings';
import { useStreetAddress }                     from 'widgets/address/address';

export function TenancyPage({ buildingId, unitId, tenancyId }) {
  const url                     = React.useContext(UrlContext);
  const login                   = React.useContext(LoginContext);
  const role                    = useDbBuildingMemberRole(buildingId, login.uid);
  const address                 = useDbBuildingAddress(buildingId);
  const streetAddress           = useStreetAddress(address);
  const unitDesignator          = useDbUnitDesignator (buildingId, unitId);
  const tenancy                 = useDbTenancy(buildingId, unitId, tenancyId);
  const [transactions, balance] = useDbTransactionsWithRunningBalances(buildingId, unitId, tenancyId);

  const [showWidget, setShowWidget] = React.useState();

  /* Close open widget when switching tenancies. */
  React.useEffect(() => setShowWidget(), [tenancyId]);

  return (
    <main className="page-tenancy">
      <header className="tenancy-header page-header" style={{
        backgroundColor: (!tenancyId            && '#00897b') ||
                         ( tenancy?.moveOutDate && '#9d445c') ||
                         ( tenancy              && '#00897b')
      }}>
        <h1 className="top-spacer-none bottom-spacer-none" style={{
          boxSizing: 'border-box',
          color: 'white',
          margin: 'auto',
          maxWidth: '640px',
          padding: '4px 14px 9px'
        }}>
          <small style={{ color: 'rgba(255, 255, 255, 0.618)' }}>
            {(!tenancyId &&
              <>New Tenancy</>
            ) || (tenancy &&
              <>
                {tenancy.commencementDate ?
                  <FormattedDate date={tenancy.commencementDate} />
                :
                  <em>missing start date</em>
                }
                {(tenancy.moveOutDate &&
                  <> → <FormattedDate date={tenancy.moveOutDate} /></>
                )}
              </>
            )}
          </small>
          <br />
          {streetAddress}
          {unitDesignator &&
            <small style={{ backgroundColor: 'rgba(0, 0, 0, 0.17)',
                            borderRadius: '5px',
                            marginLeft: '11.5px',
                            padding: '0px 7.75px 1.75px 7px' }}>{unitDesignator}</small>
          }
        </h1>
      </header>

      {(!tenancyId &&
        <TenancyCreator buildingId={buildingId} unitId={unitId} />

      ) || (tenancy &&
        <>
          {url.portal === 'management' && (
            role === 'manager' && (
              !tenancy?.moveOutDate ?
                <>
                  <div className="action-bar-management">
                    <ButtonIconOverText icon="arrow_back"        label="To Buildings"   pathname="/management/file-room"                                                                        />
                    <ButtonIconOverText icon="person_add"        label="Add Tenant"     onClick={() => { showWidget !== 'add-tenant'     ? setShowWidget('add-tenant')     : setShowWidget() }} />
                    <ButtonIconOverText icon="person_off"        label="Remove Tenant"  onClick={() => { showWidget !== 'remove-tenant'  ? setShowWidget('remove-tenant')  : setShowWidget() }} />
                    <ButtonIconOverText icon="payments"          label="Record Payment" onClick={() => { showWidget !== 'record-payment' ? setShowWidget('record-payment') : setShowWidget() }} />
                    <ButtonIconOverText icon="receipt_long"      label="Add Charge"     onClick={() => { showWidget !== 'add-charge'     ? setShowWidget('add-charge')     : setShowWidget() }} />
                    <ButtonIconOverText icon="sell"              label="Adjust Rent"    onClick={() => { showWidget !== 'adjust-rent'    ? setShowWidget('adjust-rent')    : setShowWidget() }} />
                    <ButtonIconOverText icon="free_cancellation" label="End Tenancy"    onClick={() => { showWidget !== 'end-tenancy'    ? setShowWidget('end-tenancy')    : setShowWidget() }} />
                    <ButtonIconOverText icon="edit_calendar"     label="Edit Start"     onClick={() => { showWidget !== 'edit-start'     ? setShowWidget('edit-start')     : setShowWidget() }} />
                  </div>
                  {showWidget &&
                    <div className="container-widgets">
                      {
                        (showWidget === 'add-tenant'           && <WidgetAddTenant           buildingId={buildingId} unitId={unitId} tenancyId={tenancyId}                                                 onExit={() => setShowWidget()}                                                                     />) ||
                        (showWidget === 'remove-tenant'        && <WidgetRemoveTenant        buildingId={buildingId} unitId={unitId} tenancyId={tenancyId}                                                 onExit={() => setShowWidget()}                                                                     />) ||
                        (showWidget === 'record-payment'       && <WidgetRecordPayment       buildingId={buildingId} unitId={unitId} tenancyId={tenancyId}                                                 onExit={() => setShowWidget()} onRecordReimbursement={() => setShowWidget('record-reimbursement')} />) ||
                        (showWidget === 'record-reimbursement' && <WidgetRecordReimbursement buildingId={buildingId} unitId={unitId} tenancyId={tenancyId}                                                 onExit={() => setShowWidget()}                                                                     />) ||
                        (showWidget === 'add-charge'           && <WidgetAddCharge           buildingId={buildingId} unitId={unitId} tenancyId={tenancyId}                                                 onExit={() => setShowWidget()}                                                                     />) ||
                        (showWidget === 'adjust-rent'          && <WidgetAdjustRent          buildingId={buildingId} unitId={unitId} tenancyId={tenancyId} currentRentAdjustment={tenancy?.rentAdjustment} onExit={() => setShowWidget()} onEditMemo={() => setShowWidget('edit-rent-memo')}                  />) ||
                        (showWidget === 'edit-rent-memo'       && <WidgetEditRentMemo        buildingId={buildingId} unitId={unitId} tenancyId={tenancyId} currentRentMemo={tenancy?.rentMemo}             onExit={() => setShowWidget()}                                                                     />) ||
                        (showWidget === 'end-tenancy'          && <WidgetEndTenancy          buildingId={buildingId} unitId={unitId} tenancyId={tenancyId} currentEnd={tenancy.moveOutDate}                onExit={() => setShowWidget()}                                                                     />) ||
                        (showWidget === 'edit-start'           && <WidgetEditStart           buildingId={buildingId} unitId={unitId} tenancyId={tenancyId} currentStart={tenancy?.commencementDate}        onExit={() => setShowWidget()}                                                                     />)
                      }
                    </div>
                  }
                </>
              :
              <>
                <div className="action-bar-management">
                  <ButtonIconOverText icon="arrow_back"        label="To Buildings"   pathname="/management/file-room"                                                                        />
                  <ButtonIconOverText icon="payments"          label="Record Payment" onClick={() => { showWidget !== 'record-payment' ? setShowWidget('record-payment') : setShowWidget() }} />
                  <ButtonIconOverText icon="receipt_long"      label="Add Charge"     onClick={() => { showWidget !== 'add-charge'     ? setShowWidget('add-charge')     : setShowWidget() }} />
                  <ButtonIconOverText icon="free_cancellation" label="End Tenancy"    onClick={() => { showWidget !== 'end-tenancy'    ? setShowWidget('end-tenancy')    : setShowWidget() }} />
                  <ButtonIconOverText icon="edit_calendar"     label="Edit Start"     onClick={() => { showWidget !== 'edit-start'     ? setShowWidget('edit-start')     : setShowWidget() }} />
                  <ButtonIconOverText icon="delete"            label="Delete Tenancy" onClick={() => { showWidget !== 'delete-tenancy' ? setShowWidget('delete-tenancy') : setShowWidget() }} />
                </div>
                {showWidget &&
                  <div className="container-widgets">
                    {
                      (showWidget === 'record-payment'       && <WidgetRecordPayment       buildingId={buildingId} unitId={unitId} tenancyId={tenancyId}                                          onExit={() => setShowWidget()} onRecordReimbursement={() => setShowWidget('record-reimbursement')} />) ||
                      (showWidget === 'record-reimbursement' && <WidgetRecordReimbursement buildingId={buildingId} unitId={unitId} tenancyId={tenancyId}                                          onExit={() => setShowWidget()}                                                                     />) ||
                      (showWidget === 'add-charge'           && <WidgetAddCharge           buildingId={buildingId} unitId={unitId} tenancyId={tenancyId}                                          onExit={() => setShowWidget()}                                                                     />) ||
                      (showWidget === 'end-tenancy'          && <WidgetEndTenancy          buildingId={buildingId} unitId={unitId} tenancyId={tenancyId} currentEnd={tenancy.moveOutDate}         onExit={() => setShowWidget()}                                                                     />) ||
                      (showWidget === 'edit-start'           && <WidgetEditStart           buildingId={buildingId} unitId={unitId} tenancyId={tenancyId} currentStart={tenancy?.commencementDate} onExit={() => setShowWidget()}                                                                     />) ||
                      (showWidget === 'delete-tenancy'       && <WidgetDeleteTenancy       buildingId={buildingId} unitId={unitId} tenancyId={tenancyId}                                          onExit={() => setShowWidget()}                                                                     />)
                    }
                  </div>
                }
              </>
            )
          )}

          <div className="rent-and-balance">
            <Rent    rent={tenancy.rent}
                            rentAdjustment={tenancy.rentAdjustment} />
            <Balance balance={balance} />
          </div>

          <h2>Tenants</h2>
          {tenancy.members ?
            <div className="container-tenant-cards">
              {Object.keys(tenancy.members).map(uid =>
                <CardTenant key={uid}
                            buildingId={buildingId}
                            unitId={unitId}
                            tenancyId={tenancyId}
                            uid={uid}
                            isPrimedToRemove={showWidget === 'remove-tenant'} />
              )}
            </div>
          :
            <p className="text-secondary text-align-center disabled"
               style={{ margin: '72px auto' }}>
              No tenants added yet
            </p>
          }

          {(url.portal === 'tenant' || role === 'manager') &&
            <>
              <h2>Statement</h2>
              <Statement buildingId={buildingId}
                         unitId={unitId}
                         tenancyId={tenancyId}
                         transactions={transactions} />
              <p className="tenancy-statement_help-text">
                The statement lists all charges, credits, and payments since the
                beginning of the tenancy. The rent charge is generated on the
                1st, and online payments are recorded automatically.
              </p>
            </>
          }
        </>
      )}
    </main>
  );
}
