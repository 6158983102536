import React from 'react';

import                              'widgets/button/button-v2.scss';
import { PgTextField }         from 'widgets/text-field/text-field';
import { Select }              from 'widgets/select/select';
import { dbCreateTransaction } from 'backend-layer/db/db-transactions';

export function WidgetAddCharge({ buildingId,
                                  unitId,
                                  tenancyId,
                                  onExit }) {
  const [type,   setType]   = React.useState('');
  const [date,   setDate]   = React.useState('');
  const [amount, setAmount] = React.useState('');
  const [memo,   setMemo]   = React.useState('');

  const submit = React.useCallback(e => {
    e.preventDefault();
    dbCreateTransaction(buildingId,
                        unitId,
                        tenancyId,
                        type,
                        date,
                        amount,
                        { memo });
    onExit();
  }, [buildingId, unitId, tenancyId, onExit, type, date, amount, memo]);

  const reset = React.useCallback(e => {
    e.preventDefault();
    onExit();
  }, [onExit]);

  return (
    <form className="widget" onSubmit={submit} onReset={reset}>
      <Select label="Type of Charge"
              name="type"
              required
              value={type}
              onChange={e => setType(e.target.value)}>
        <option value="Rent"                   >Rent (one time e.g. prorated rent)</option>
        <option value="Surcharge"              >Surchage (fees, utlities, etc.)</option>
        <option value="Late Fee"               >Late fee</option>
        <option value="Credit"                 >Credit (reduces balance)</option>
        <option value="Security Deposit"       >Security Deposit</option>
        <option value="Security Deposit Return">Security Deposit Return (reduces balance)</option>
      </Select>

      <div className="flex-row top-spacer-sm">
        <PgTextField className="flex-1"
                     label="Date"
                     name="date"
                     type="date"
                     value={date}
                     onChange={e => setDate(e.target.value)}
                     required />
        <PgTextField className="flex-1 left-spacer-sm"
                     label="Amount"
                     name="amount"
                     type="number"
                     inputMode="decimal"
                     step="0.01"
                     min="0"
                     value={amount}
                     onChange={e => setAmount(e.target.value)}
                     required />
      </div>

      <PgTextField className="top-spacer-sm"
                   label="Memo"
                   name="memo"
                   multiline
                   value={memo}
                   onChange={e => setMemo(e.target.value)} />

      <div className="flex-row top-spacer-md">
        <div className="flex-1" />
        <button className="btn-change"                 type="submit">{((type === 'Credit' || type === 'Security Deposit Return') && 'Add Credit') || 'Add Charge'}</button>
        <button className="btn-primary left-spacer-sm" type="reset" >Cancel</button>
      </div>
    </form>
  );
}
