import React from 'react';

import                                 'pages/building/card-member.scss';
import                                 'widgets/button/button-v2.scss';
import { LoginContext }           from 'walls/login-wall/login-wall';
import { formatPhone }            from 'widgets/text-field/input-phone';
import { useDbUser }              from 'backend-layer/db/db-users';
import { useDbPhone }             from 'backend-layer/db/db-users';
import { dbRemoveBuildingMember } from 'backend-layer/db/db-memberships';

const fallbackProfilePic = `data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='172' height='172' viewBox='0 0 172 172'%3E%3Crect fill='rgb(224,224,224)' width='172' height='172' /%3E%3C/svg%3E`;

export function CardMember({ buildingId, uid, role, isPrimedToRemove }) {
  const login = React.useContext(LoginContext);

  const user        = useDbUser(uid);
  const phoneNumber = useDbPhone(uid);

  const removeStaff = React.useCallback(() => {
    dbRemoveBuildingMember(buildingId, uid);
  }, [buildingId, uid]);

  return user ? (
    <div className="card-member">
      <img src={user.avatar || fallbackProfilePic}
           alt={user.name}
           width="172"
           height="172"
           referrerPolicy="no-referrer" />
      <div className="role">{role}</div>

      <div className="body flex-1 flex-col">
        <div className="flex-1">
          <div className="info card-tenant_name">{user.name}</div>
          <div className="info text-secondary">{user.email}</div>
          <div className="info text-secondary">{formatPhone(phoneNumber).display}</div>
        </div>
        {isPrimedToRemove && uid !== login.uid &&
          <div className="flex-row top-spacer-sm">
            <div className="flex-1" />
            <button className="btn-remove" onClick={removeStaff}>Remove</button>
          </div>
        }
      </div>
    </div>
  ) : null;
}
