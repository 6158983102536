import   React         from 'react';
import { getAuth }     from 'firebase/auth';
import { getDatabase,
         ref,
         get,
         set,
         update,
         remove,
         onValue,
         query,
         orderByChild,
         equalTo,
         limitToFirst} from 'firebase/database';
import { getAnalytics,
         logEvent }    from 'firebase/analytics';

/******************************************************************************
 * Create, read once, update & delete
 ******************************************************************************/

export async function dbGetUserByEmail(email) {
  if (!email) { throw new Error('Get user by email failed: missing email address!'); }

  const snapshot = await get(query(ref(getDatabase(), 'users'),
                                   orderByChild('email'),
                                   equalTo(email.trim().toLowerCase()),
                                   limitToFirst(1)));
  if (snapshot.exists()) {
    const [uid, user] = Object.entries(snapshot.val())[0];
    return { ...user, uid };
  } else {
    return null;
  }
}

export async function dbGetLastPortal() {
  return (await get(ref(getDatabase(), `users/${getAuth().currentUser.uid}/portal`))).val();
}

export async function dbUpdateUserFromLogin() {
  const firebaseUser = getAuth().currentUser;
  const user = (await get(ref(getDatabase(), `users/${firebaseUser.uid}`))).val();

  /* User exists, audit user data against login provider data. */
  if (user) {
    /* Reset email if missing. */
    if (!user.email) { set(ref(getDatabase(), `users/${firebaseUser.uid}/email`), firebaseUser.email); }

    /* Attempt to extract avatar from provider data if avatar missing from user. */
    if (!user.avatar) {
      firebaseUser.providerData.some(providerUserInfo =>
        providerUserInfo.photoURL &&
        set(ref(getDatabase(), `users/${firebaseUser.uid}/avatar`), providerUserInfo.photoURL.replace('=s96-c', '=s256-c')) // Modifies Google profile pic URL to serve a higher resolution.
      );
    }

    /* If user does not contain both a first and last name, override current
     * name values with whichever name parts we can extrapolate from provider data. */
    if (!user.legalFirstName || !user.lastName) {
      let firstName, lastName;

      firebaseUser.providerData.some(providerUserInfo => {
        if (providerUserInfo.displayName) {
          const nameParts = providerUserInfo.displayName.split(' ');
          if (nameParts.at(0))                          { firstName = nameParts.at(0);  }
          if (nameParts.length > 1 && nameParts.at(-1)) { lastName  = nameParts.at(-1); }
          return firstName && lastName;
        } else { return false; }
      });

      /* Update name if able to extract a first or last name. Note no null
       * fallback for name as must extract some name info to trigger name
       * update. Relevant use case is Apple login, where name is only provided
       * on first login. */
      if (firstName || lastName) {
        update(ref(getDatabase(), `users/${firebaseUser.uid}`), {
          legalFirstName: firstName || null,
          lastName: lastName        || null,
          name: (firstName && lastName && `${firstName} ${lastName}`) || firstName || lastName
        });
      }

    /* User contains both a first and last name, still need to audit denormalized name field. */
   } else {
     const denormalizedName = `${user.preferredName || user.legalFirstName} ${user.lastName}`;
     if (user.name !== denormalizedName) { set(ref(getDatabase(), `users/${firebaseUser.uid}/name`), denormalizedName); }
   }

  } else {
    let firstName, lastName, profilePic;

    /* Aggregate provider data for a first name, last name, and profile pic. */
    firebaseUser.providerData.some(providerUserInfo => {
      if (providerUserInfo.displayName) {
        const nameParts = providerUserInfo.displayName.split(' ');
        if (nameParts.at(0))                          { firstName = nameParts.at(0); }
        if (nameParts.length > 1 && nameParts.at(-1)) { lastName = nameParts.at(-1); }
      }
      if (providerUserInfo.photoURL) {
        /* Modifies Google profile pic URL to serve a higher resolution. */
        profilePic = providerUserInfo.photoURL.replace('=s96-c', '=s256-c');
      }
      /* Stop aggregation if all three found. */
      return firstName && lastName && profilePic;
    });

    /* Note null fallback for name as it is possible that no name info was
     * extractable from login. */
    update(ref(getDatabase(), `users/${firebaseUser.uid}`), {
      email: firebaseUser.email,
      avatar: profilePic        || null,
      legalFirstName: firstName || null,
      lastName: lastName        || null,
      name: (firstName && lastName && `${firstName} ${lastName}`) || firstName || lastName || null
    });
  }
}

export function dbUpdateUserName(legalFirstName, preferredName, lastName) {
  if (!legalFirstName) { throw new Error('Update user name failed: missing legal first name!'); }
  if (!lastName)       { throw new Error('Update user name failed: missing last name!');        }

  return update(ref(getDatabase(), `users/${getAuth().currentUser.uid}`), {
    legalFirstName,
    preferredName: preferredName || null,
    lastName,
    name: `${preferredName || legalFirstName} ${lastName}`,
  });
}

export function dbSetRegisteredAsManagement() {
  return update(ref(getDatabase(), `users/${getAuth().currentUser.uid}`), {
    portal: 'management',
    registeredAs: 'management'
  }).then(() => {
    logEvent(getAnalytics(), 'registration_management', { uid: getAuth().currentUser.uid });
  });
}

export function dbSetRegisteredAsTenant() {
  return update(ref(getDatabase(), `users/${getAuth().currentUser.uid}`), {
    portal: 'tenant',
    registeredAs: 'tenant'
  }).then(() => {
    logEvent(getAnalytics(), 'registration_tenant', { uid: getAuth().currentUser.uid });
  });
}

export function dbSetLastPortalToManagement() {
  return set(ref(getDatabase(), `users/${getAuth().currentUser.uid}/portal`), 'management');
}

export function dbSetLastPortalToTenant() {
  return set(ref(getDatabase(), `users/${getAuth().currentUser.uid}/portal`), 'tenant');
}

export function dbDeletePhone() {
  return remove(ref(getDatabase(), `phone/${getAuth().currentUser.uid}`));
}

/******************************************************************************
 * React hooks
 ******************************************************************************/

export function useDbUser(uid) {
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    if (uid) {
      return onValue(ref(getDatabase(), `users/${uid}`), snapshot => {
        const user = snapshot.val();

        if (user) {
          setUser({
            name: '<missing name>',
            avatar: '/default-avatar.jpg',
            isComplete: user.email && user.legalFirstName && user.lastName,
            ...user
          });
        } else {
          setUser({
            name: '<deleted user>',
            email: '<no email>',
            avatar: '/default-avatar.jpg',
            isComplete: false,
            isNotFound: true
          });
        }
      });

    } else {
      setUser();
    }
  }, [uid]);

  return user;
}

export function useDbUserName(uid) {
  const [name, setName] = React.useState();

  React.useEffect(() => {
    if (uid) {
      return onValue(ref(getDatabase(), `users/${uid}/name`), snapshot => {
        setName(snapshot.val());
      });
    } else {
      setName();
    }
  }, [uid]);

  return name;
}

export function useDbPhone(uid) {
  const [phoneNumber, setPhoneNumber] = React.useState();

  React.useEffect(() => {
    if (uid) {
      return onValue(ref(getDatabase(), `phone/${uid}`), snapshot => {
        setPhoneNumber(snapshot.val());
      });
    } else {
      setPhoneNumber();
    }
  }, [uid]);

  return phoneNumber;
}

/******************************************************************************
 * React components
 ******************************************************************************/

 export const DbUserName = React.memo(({ uid }) => {
   const name = useDbUserName(uid);

   switch (name) {
     case undefined: return null;
     case null     : return <span>&lt;missing name&gt;</span>;
     default       : return <span>{name}</span>;
   }
 });
