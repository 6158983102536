import React from 'react';

import 'widgets/text-field/text-field.scss';

export function PgTextField({ className,
                              minWidth,
                              maxWidth,
                              readonly,
                              readOnly,
                              onReadOnly,
                              onEditable,
                              autoFocus,
                              autoComplete,
                              multiline,
                              type,
                              label,
                              name,
                              required,
                              min,
                              max,
                              step,
                              minLength,
                              maxLength,
                              inputMode,
                              showControls,
                              pattern,
                              disabled,
                              validateSilently,
                              placeholder,
                              value,
                              onChange,
                              icon,
                              onClickIcon,
                              isIconSubmit }) {

  const inputRef = React.createRef();

  React.useEffect(() => {
    if (readOnly === true) {
      onReadOnly && onReadOnly(inputRef.current);
    } else {
      onEditable && onEditable(inputRef.current);
    }
  }, [readOnly]); // eslint-disable-line

  /* Special validator for input type month as Safari for desktop does not support type month. */
  React.useEffect(() => {
    if (type === 'month') {
      if (value && !value.match(/^\d{4}-\d{2}$/)) {
        inputRef.current.setCustomValidity('Please format month as yyyy-mm, unfortunately Safari for desktop does not support the month picker.');
      } else {
        inputRef.current.setCustomValidity('');
      }
    }
  }, [type, value]); // eslint-disable-line

  /* Makes textarea autogrow.
   * https://developer.mozilla.org/en-US/docs/Web/API/HTMLTextAreaElement#autogrowing_textarea_example */
  React.useEffect(() => {
    if (multiline && inputRef.current.scrollHeight > inputRef.current.clientHeight) {
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  }, [multiline, value]); // eslint-disable-line

  return (
    <div className={`text-field${(readOnly || readonly) ? ' read-only' : ''}${validateSilently ? ' validate-silently' : ''}${className ? ` ${className}` : ''}`}
         style={{ minWidth, maxWidth }} >
      {label &&
        <div className="label">
          {label}
          {required && !validateSilently && !(readOnly || readonly) && <span className="asterisk">✱</span>}
        </div>
      }
      {multiline ?
        <textarea ref={inputRef}
                  autoFocus={autoFocus}
                  name={name}
                  required={required}
                  autoComplete="off"
                  placeholder={placeholder}
                  minLength={minLength}
                  maxLength={maxLength}
                  readOnly={readOnly || readonly}
                  value={value}
                  onChange={onChange}></textarea>
      :
        <>
          {icon && !(readOnly || readonly) &&
            <button className="btn btn-unstyled"
                    type={isIconSubmit ? 'submit' : 'button'}
                    aria-label={icon}
                    onClick={onClickIcon}>
              <i className="material-icons" aria-hidden="true">{icon}</i>
            </button>
          }
          <input className={showControls && 'show-controls'}
                 ref={inputRef}
                 autoFocus={autoFocus}
                 type={type || 'text'}
                 name={name}
                 aria-label={label}
                 tabIndex={readOnly ? -1 : undefined}
                 required={required}
                 min={min}
                 max={max}
                 step={step}
                 minLength={minLength}
                 maxLength={maxLength}
                 inputMode={inputMode}
                 pattern={pattern}
                 autoComplete={autoComplete || 'off'}
                 readOnly={readOnly || readonly}
                 disabled={disabled || ((readOnly || readonly) && type === 'date')} // Work around as iOS Safari does not respect readonly on date inputs.
                 placeholder={type === 'month' ? 'yyyy-mm' : placeholder}
                 value={value}
                 onChange={onChange} />
        </>
      }
    </div>
  );
}
