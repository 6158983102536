import   React     from 'react';
import { NavLink } from 'react-router-dom';

import 'widgets/button/button-v2.scss';

export function ButtonIconOverText({ className,
                                     style,
                                     backgroundImage,
                                     icon,
                                     label,
                                     pathname,
                                     onClick }) {

  const computedClassName = React.useMemo(() => (
    `${className ? `${className} ` : ''}`   +
    'btn-icon-over-text btn-filled-primary' +
    `${backgroundImage ? ' btn-background-img' : ''}`
  ), [className, backgroundImage]);

  const computedStyle = React.useMemo(() => (
    (backgroundImage && {
      '--btn-background-image': `url(${backgroundImage})`,
      ...style
    }) || style || null
  ), [style, backgroundImage]);

  return pathname ? (
    <NavLink className={computedClassName} style={computedStyle} onClick={onClick} to={pathname}>
      <i className="material-icons" aria-hidden="true">{icon}</i>
      <span>{label}</span>
    </NavLink>
  ) : (
    <button className={computedClassName} style={computedStyle} onClick={onClick}>
      <i className="material-icons" aria-hidden="true">{icon}</i>
      <span>{label}</span>
    </button>
  );
}
