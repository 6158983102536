import React from 'react';

import                           'widgets/issue/toolbar.scss';
import { IssueFormDelete,
         IssueFormClose,
         IssueFormReopen,
         IssueFormTitle,
         IssueFormAppointment,
         IssueFormAssignee,
         IssueFormComment } from 'widgets/issue/forms';

export const IssueToolbar = React.memo(({ buildingId, issueId, issue, isManagement }) => {
  const [form, setForm] = React.useState();

  return (
    <>
      {(    form === 'delete' && isManagement &&
        <IssueFormDelete buildingId={buildingId}
                         issueId={issueId}
                         onExit={setForm} />

      ) || (form === 'close' && !issue.closed && isManagement &&
        <IssueFormClose buildingId={buildingId}
                        issueId={issueId}
                        onExit={setForm} />

      ) || (form === 'reopen' && issue.closed && isManagement &&
        <IssueFormReopen buildingId={buildingId}
                         issueId={issueId}
                         onExit={setForm} />

      ) || (form === 'retitle' && !issue.closed && isManagement &&
        <IssueFormTitle buildingId={buildingId}
                        issueId={issueId}
                        currentTitle={issue.title}
                        onExit={setForm} />

      ) || (form === 'schedule' && !issue.closed && isManagement &&
        <IssueFormAppointment buildingId={buildingId}
                              issueId={issueId}
                              currentAppointment={issue.appointment}
                              onExit={setForm} />

      ) || (form === 'assign' && !issue.closed && isManagement &&
        <IssueFormAssignee buildingId={buildingId}
                           issueId={issueId}
                           currentAssignee={issue.assignee}
                           onExit={setForm} />

      ) || (form === 'comment' && !issue.closed &&
        <IssueFormComment buildingId={buildingId}
                          issueId={issueId}
                          onExit={setForm} />

      ) || (
        <div className="widget-issue-toolbar">
          <div className="flex-1" />
          {                 isManagement && issue.role === 'manager' && <button className="btn-unstyled material-icons" aria-label="Delete"   onClick={() => setForm('delete')}  >delete<        /button>}
          {!issue.closed && isManagement                             && <button className="btn-unstyled material-icons" aria-label="Close"    onClick={() => setForm('close')}   >task_alt<      /button>}
          { issue.closed && isManagement                             && <button className="btn-unstyled material-icons" aria-label="Reopen"   onClick={() => setForm('reopen')}  >replay<        /button>}
          {!issue.closed && isManagement                             && <button className="btn-unstyled material-icons" aria-label="Retitle"  onClick={() => setForm('retitle')} >title<         /button>}
          {!issue.closed && isManagement                             && <button className="btn-unstyled material-icons" aria-label="Schedule" onClick={() => setForm('schedule')}>event<         /button>}
          {!issue.closed && isManagement && issue.role === 'manager' && <button className="btn-unstyled material-icons" aria-label="Assign"   onClick={() => setForm('assign')}  >assignment_ind</button>}
          {!issue.closed &&                                             <button className="btn-unstyled material-icons" aria-label="Comment"  onClick={() => setForm('comment')} >comment<       /button>}
        </div>
      )}
    </>
  );
});
