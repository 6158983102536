import { Loader } from '@googlemaps/js-api-loader';

var googlePlaces;

export async function getGooglePlaces() {
  if (!googlePlaces) {
    googlePlaces = await new Loader({
      apiKey: 'AIzaSyDp1bLCIUpqu1K3JBojQ1omXqqX6JfGa8M',
      version: 'weekly',
      libraries: ['places']
    }).load();
  }

  return googlePlaces;
}
