import React from 'react';

import                                  'widgets/button/button-v2.scss';
import { PgTextField }             from 'widgets/text-field/text-field';
import { Select }                  from 'widgets/select/select';
import { LoginContext }            from 'walls/login-wall/login-wall';
import { UrlContext }              from 'walls/url-parsing-wall/url-parsing-wall';
import { dbUpdateTransaction,
         dbDeleteTransaction,
         dbPayMethods }            from 'backend-layer/db/db-transactions';
import { useDbBuildingMemberRole } from 'backend-layer/db/db-memberships.js';

export function StatementItemDetails({ buildingId,
                                       unitId,
                                       tenancyId,
                                       transactionId,
                                       transaction }) {
  const url   = React.useContext(UrlContext);
  const login = React.useContext(LoginContext);
  const role  = useDbBuildingMemberRole(buildingId, login.uid);

  const [readOnly, setReadOnly] = React.useState(true);

  const [payer,       setPayer]       = React.useState(transaction?.payer       || '');
  const [payee,       setPayee]       = React.useState(transaction?.payee       || '');
  const [payMethod,   setPayMethod]   = React.useState(transaction?.payMethod   || '');
  const [checkNumber, setCheckNumber] = React.useState(transaction?.checkNumber || '');
  const [memo,        setMemo]        = React.useState(transaction?.memo        || '');

  const unpackData = React.useCallback(() => {
    setPayer      (transaction?.payer       || '');
    setPayee      (transaction?.payee       || '');
    setPayMethod  (transaction?.payMethod   || '');
    setCheckNumber(transaction?.checkNumber || '');
    setMemo       (transaction?.memo        || '');
  }, [transaction]);

  const submit = React.useCallback(e => {
    e.preventDefault();
    setReadOnly(true);
    dbUpdateTransaction(buildingId,
                        unitId,
                        tenancyId,
                        transactionId,
                        { payer, payee, payMethod, checkNumber, memo });
  }, [
    buildingId,
    unitId,
    tenancyId,
    transactionId,
    payer,
    payee,
    payMethod,
    checkNumber,
    memo
  ]);

  const reset = React.useCallback(e => {
    e.preventDefault();
    setReadOnly(true);
    unpackData();
  }, [unpackData]);

  const handleDelete = React.useCallback(() => {
    dbDeleteTransaction(buildingId, unitId, tenancyId, transactionId);
  }, [buildingId, unitId, tenancyId, transactionId]);

  React.useEffect(() => {
    unpackData();
  }, [unpackData]);

  return (
    <form onSubmit={submit} onReset={reset}>
      {(transaction.type === 'Payment' || transaction.type === 'Reimbursement') &&
        <>
          <div className="flex-row">
            <Select className="flex-2"
                    label="Method of Payment"
                    name="payMethod"
                    disabled={readOnly}
                    includeEmpty
                    selections={dbPayMethods}
                    value={payMethod}
                    onChange={e => setPayMethod(e.target.value)} />

            <PgTextField className="flex-3 left-spacer-sm"
                         label="Check / Confirmation #"
                         name="checkNumber"
                         readOnly={readOnly}
                         value={checkNumber}
                         onChange={e => setCheckNumber(e.target.value)} />
          </div>

          {(transaction.type === 'Payment' &&
            <PgTextField className="top-spacer-sm bottom-spacer-sm"
                         label="Paid by"
                         name="payer"
                         readOnly={readOnly}
                         value={payer}
                         onChange={e => setPayer(e.target.value)} />
          ) || (transaction.type === 'Reimbursement' &&
            <PgTextField className="top-spacer-sm bottom-spacer-sm"
                         label="Paid out to"
                         name="payee"
                         readOnly={readOnly}
                         value={payee}
                         onChange={e => setPayee(e.target.value)} />
          )}
        </>
      }

      <PgTextField label="Memo"
                   name="memo"
                   multiline
                   readOnly={readOnly}
                   value={memo}
                   onChange={e => setMemo(e.target.value)} />

      {url.portal === 'management' && role === 'manager' &&
        <div className="flex-row top-spacer-sm">
          {readOnly ?
            <>
              <div className="flex-1" />
              <button className="btn-primary" type="button" onClick={e => setReadOnly(false)}>Edit</button>
            </>
            :
            <>
              <button className="btn-remove" type="button" onClick={handleDelete}>Delete</button>
              <div className="flex-1" />
              <button className="btn-change"                 type="submit">Save</button>
              <button className="btn-primary left-spacer-xs" type="reset" >Cancel</button>
            </>
          }
        </div>
      }
    </form>
  );
}
