import React from 'react';

export function WidgetRemoveTenant({ onExit }) {
  return (
    <div className="widget">
      <p className="top-spacer-none">
        Click "Remove" under any tenants's name to disconnect them. Dismiss this
        box to hide the "Remove" option.
      </p>
      <div className="flex-row">
        <div className="flex-1" />
        <button className="btn-primary" onClick={onExit}>Dismiss</button>
      </div>
    </div>
  );
}
