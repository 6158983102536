import React from 'react';

import                          'widgets/button/button-v2.scss';
import { PgTextField }     from 'widgets/text-field/text-field';
import { dbSetTenancyEnd } from 'backend-layer/db/db-tenancies';

export function WidgetEndTenancy({ buildingId,
                                   unitId,
                                   tenancyId,
                                   currentEnd,
                                   onExit }) {
  const [end, setEnd] = React.useState(currentEnd || '');

  const submit = React.useCallback(e => {
    e.preventDefault();
    dbSetTenancyEnd(buildingId, unitId, tenancyId, end);
    onExit();
  }, [buildingId, unitId, tenancyId, end, onExit]);

  const reset = React.useCallback(e => {
    e.preventDefault();
    onExit();
  }, [onExit]);

  const resumeTenancy = React.useCallback(() => {
    dbSetTenancyEnd(buildingId, unitId, tenancyId, null);
    onExit();
  }, [buildingId, unitId, tenancyId, onExit]);

  return (
    <form className="widget" onSubmit={submit} onReset={reset}>
      <PgTextField type="date"
                   label="Moving out on"
                   required
                   value={end}
                   onChange={e => setEnd(e.target.value)} />

      <div className="flex-row top-spacer-md">
        {currentEnd &&
          <button className="btn-alt" type="button" onClick={resumeTenancy}>Resume Tenancy</button>
        }
        <div className="flex-1" />
        <button className="btn-change"                 type="submit">Set</button>
        <button className="btn-primary left-spacer-xs" type="reset" >Cancel</button>
      </div>
    </form>
  );
}
