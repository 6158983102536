import   React        from 'react';
import { useHistory } from "react-router-dom";

import                           'widgets/button/button-v2.scss';
import { dbDeleteBuilding } from 'backend-layer/db/db-buildings';

export function WidgetDeleteBuilding({ buildingId, onExit }) {
  const history = useHistory();

  return (
    <div className="widget">
      <p className="top-spacer-none">
        Are you sure you want to delete this building? This will permanently
        remove the building, along with all associated units, tenancies, and
        issues. This action cannot be undone.
      </p>
      <div className="flex-row">
        <div className="flex-1" />
        <button className="btn-remove"
                type="button"
                onClick={e => { dbDeleteBuilding(buildingId); history.replace('/management/file-room'); }}>
          Confirm Delete
        </button>
        <button className="btn-primary left-spacer-xs"
                type="button"
                onClick={onExit}>
          Cancel
        </button>
      </div>
    </div>
  );
}
