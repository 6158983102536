import   React  from 'react';
import { Link } from 'react-router-dom';

import                     'widgets/button/button-v2.scss';
import { DbUserName } from 'backend-layer/db/db-users';

export function FileRoomTenancy({ role, buildingId, unitId, tenancyId, tenancy }) {
  return (
    <Link className="tenancy flex-col btn-unstyled"
          to={`/management/tenancy?buildingId=${buildingId}&unitId=${unitId}&tenancyId=${tenancyId}`}>

      {(tenancy.members &&
        <div className="tenancy-tenants">
          {Object.keys(tenancy.members).map(uid => <DbUserName key={uid} uid={uid} />)}
        </div>
      ) || (Array.isArray(tenancy.namesOnLease) &&
        <div className="disabled">{tenancy.namesOnLease.join(', ')}</div>
      ) || (
        <div className="disabled">No tenants</div>
      )}

      {(role === 'manager' &&
        <div className="tenancy-financials flex-row">
          <div className="flex-1">
            {typeof tenancy.rent === 'number' ? tenancy.rent.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '-'}
          </div>
          {(typeof tenancy.balance === 'number' &&
            <div className={`tenancy-balance${tenancy.balance >= 0.01 ? ' owes-money' : ''}`}>
              {tenancy.balance.toLocaleString('en-US', { minimumFractionDigits: 2 })}
            </div>
          ) || (!tenancy.balance &&
            <div className="tenancy-balance">
              0.00
            </div>
          )}
        </div>
      ) || (
        <div className="tenancy-commencement-date text-secondary">
          {tenancy.commencementDate ? new Date(tenancy.commencementDate).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC'}) : '-'}
        </div>
      )}
    </Link>
  );
}
